import { useRouter } from "hooks/useRouter";
import { useEffect, useState } from "react";
import UserService from "services/user.service";
import { displayNames } from "./BlackSparrow";
import { Loader } from "common/Loader";

const service = new UserService();

export const SparrowReportLocation = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<string[]>([]);

  const { push, params } = useRouter();

  useEffect(() => {
    setLoading(true);
    service
      .getSparrowReportLocation(params.location)
      .then((resp) => {
        console.log(resp);
        setData(resp.data.total_docs);
        setRows(
          Object.keys(resp.data.total_docs[0])
            .filter((key) => key !== "label")
            .filter((key) => key !== "signedReps")
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.location]);

  if (loading) return <Loader />;

  return (
    <div className="overflow-x-auto">
      <div className="flex gap-3 mb-3 items-center">
      <button
        type="button"
        onClick={() => push("/black-sparrow?tab=Location")}
        className="bg-white rounded-md p-2 border"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
          />
        </svg>
      </button>
     <div>
     <p className="text-zinc-800">Location</p>
     <p className="text-xl font-medium">{params.location}</p>
     </div>
      </div>
      <table className="table-auto w-full text-left">
        <thead>
          <tr>
            <th className="px-4 py-2 bg-white border-b sticky left-0 border-gray-300">
              Week
            </th>
            {data.map((item, index) => (
              <th
                key={index}
                className="border bg-white whitespace-nowrap border-gray-300 px-4 py-2 text-center"
              >
                {item.label || "N/A"}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((metric, rowIndex) => (
            <tr key={rowIndex}>
              <td className=" whitespace-nowrap px-4 py-2 font-medium bg-white sticky left-0 border-b border-gray-300">
                {displayNames[metric]}
              </td>
              {data.map((item, colIndex) => (
                <td
                  key={colIndex}
                  className="border whitespace-nowrap border-gray-300 px-4 py-2 text-center"
                >
                  {item[metric] || "0 (0%)"} {/* Provide a default value */}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
