import { Table } from "common/Table/Table";
import { Fragment, useCallback, useEffect, useState } from "react";
import UserService from "services/user.service";
import { useDebounce } from "hooks/useDebounce";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportToCSV, formatDate, formatDateY } from "utils/funcs";

const service = new UserService();

export const Opportunities = () => {
  const columns = [
    // { key: "service_hash", dbColName: "service_hash", title: "service_hash" },
    // {
    //   key: "opportunity_hash",
    //   dbColName: "opportunity_hash",
    //   title: "opportunity_hash",
    // },
    { key: "recruiter_id", dbColName: "recruiter_id", title: "recruiter id",
      freeze: true,
      freezeIndex: 'left-0'
     },
    {
      key: "recruiter_name",
      dbColName: "recruiter_name",
      title: "recruiter name",
      freeze: true,
      freezeIndex: 'left-28'
    },
    { key: "team_name", dbColName: "team_name", title: "team name" },
    {
      key: "team_leader_id",
      dbColName: "team_leader id",
      title: "team leader id",
    },
    {
      key: "team_leader_name",
      dbColName: "team_leader_name",
      title: "team leader name",
    },
    {
      key: "management_group_name",
      dbColName: "management_group_name",
      title: "management group name",
    },
    {
      key: "management_group_leader_id",
      dbColName: "management_group_leader_id",
      title: "management group leader id",
    },
    {
      key: "management_group_leader_name",
      dbColName: "management_group_leader_name",
      title: "management group leader name",
    },
    {
      key: "senior_management_group_name",
      dbColName: "senior_management_group_name",
      title: "senior management group name",
    },
    {
      key: "senior_management_group_leader_id",
      dbColName: "senior_management_group_leader_id",
      title: "senior management group leader id",
    },
    {
      key: "senior_management_group_leader_name",
      dbColName: "senior_management_group_leader_name",
      title: "senior management group leader name",
    },
    { key: "region_name", dbColName: "region_name", title: "region name" },
    {
      key: "region_leader_id",
      dbColName: "region_leader_id",
      title: "region leader id",
    },
    {
      key: "region_leader_name",
      dbColName: "region_leader_name",
      title: "region leader name",
    },
    {
      key: "senior_region_name",
      dbColName: "senior_region_name",
      title: "senior region name",
    },
    {
      key: "senior_region_leader_id",
      dbColName: "senior_region_leader_id",
      title: "senior region leader id",
    },
    {
      key: "senior_region_leader_name",
      dbColName: "senior_region_leader_name",
      title: "senior region leader name",
    },
    {
      key: "partnership_name",
      dbColName: "partnership_name",
      title: "partnership name",
    },
    {
      key: "partnership_leader_id",
      dbColName: "partnership_leader_id",
      title: "partnership leader id",
    },
    {
      key: "partnership_leader_name",
      dbColName: "partnership_leader_name",
      title: "partnership leader name",
    },
    {
      key: "division_name",
      dbColName: "division_name",
      title: "division_name",
    },
    { key: "tenure", dbColName: "tenure", title: "tenure" },
    {
      key: "first_install_date",
      dbColName: "first_install_date",
      title: "first install date",
      render: date => formatDate(date)
    },
    {
      key: "employee_id_created",
      dbColName: "employee_id_created",
      title: "employee id created",
    },
    {
      key: "tenure_numeric",
      dbColName: "tenure_numeric",
      title: "tenure numeric",
    },
    { key: "payable_rmr", dbColName: "payable_rmr", title: "payable rmr" },
    { key: "is_upgrade", dbColName: "is_upgrade", title: "is upgrade" },
    { key: "is_funded", dbColName: "is_funded", title: "is funded" },
    {
      key: "install_date_and_time",
      dbColName: "install_date_and_time",
      title: "install date and time",
    },
    { key: "install_date", dbColName: "install_date", title: "install date", render: date => formatDate(date) },
    {
      key: "credit_run_date_and_time",
      dbColName: "credit_run_date_and_time",
      title: "credit run date and time",
      render: date => new Date(date).toLocaleString()
    },
    {
      key: "preinstall_first_survey_date_and_time",
      dbColName: "preinstall_first_survey_date_and_time",
      title: "preinstall first survey date and time",
    },
    {
      key: "preinstall_latest_survey_date_and_time",
      dbColName: "preinstall_latest_survey_date_and_time",
      title: "preinstall latest survey date and time",
    },
    {
      key: "cancellation_date_time",
      dbColName: "cancellation_date_time",
      title: "cancellation date time",
    },
    { key: "finance_type", dbColName: "finance_type", title: "finance type" },
    {
      key: "install_fees_financed",
      dbColName: "install_fees_financed",
      title: "install fees financed",
    },
    {
      key: "install_fees_collected",
      dbColName: "install_fees_collected",
      title: "install fees collected",
    },

    {
      key: "sales_office_name",
      dbColName: "sales_office_name",
      title: "sales office name",
    },
    {
      key: "rep_quality_score",
      dbColName: "rep_quality_score",
      title: "rep quality score",
    },

    {
      key: "first_rookie_install_date",
      dbColName: "first_rookie_install_date",
      title: "first rookie install date",
      render: date => formatDate(date)
    },
  ];

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 100,
    year: 2023,
    recruiterName: "",
    installDate: "",
    tenure: "",
    teamName: "",
    regionName: "",
  });

  const debounced = useDebounce(pagination, 500);

  const fetchData = useCallback(() => {
    setLoading(true);
    service
      .getOpportunities(debounced)
      .then((resp) => {
        setData(
          resp.data.data.map((item) => ({
            ...item,
            first_install_date: new Date(
              item.first_install_date
            ).toLocaleDateString(),
          }))
        );
        setTotalItems(resp.data.totalDocument);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [debounced]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleExport = () => {
    setExporting(true);
    const pagin: any = { ...pagination };
    delete pagin.pageNumber;
    delete pagin.pageSize;
    service
      .downloadOpportunities(pagin)
      .then((resp) => {
        exportToCSV(
          resp.data.data.map((item) => ({ ...item._source })),
          "opportunities"
        );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setExporting(false);
      });
  };

  const clearFilter = () => {
    setPagination({
      pageNumber: 1,
      pageSize: 100,
      year: 2023,
      recruiterName: "",
      installDate: "",
      tenure: "",
      teamName: "",
      regionName: "",
    })
  }
  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
          Board Opportunities
        </h1>
        <p className="text-gray-500 text-sm">View all </p>
      </div>
      <Table
        data={data}
        pagination={pagination}
        handleExport={handleExport}
        setPagination={setPagination}
        loadingData={loading}
        exporting={exporting}
        filters={
          <Filters clearFilter={clearFilter} pagination={pagination} setPagination={setPagination} />
        }
        columnFilters={
          <ColumnFilters
            pagination={pagination}
            setPagination={setPagination}
          />
        }
        totalItems={totalItems}
        columns={columns}
      />
    </Fragment>
  );
};

const ColumnFilters = ({ pagination, setPagination }) => {
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const onChangeCreated = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    setPagination({
      ...pagination,
      instalStartDate: start ? formatDateY(start) : "",
      instalEndDate: end ? formatDateY(end) : "",
    });
  }

  return (
    <div className="flex gap-4 mt-3">
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        value={pagination.recruiterName}
        placeholder="Search by Recruiter Name"
        onChange={(e) => {
          setPagination({
            ...pagination,
            recruiterName: e.target.value,
          });
        }}
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        value={pagination.teamName}
        placeholder="Search by Team Name"
        onChange={(e) => {
          setPagination({
            ...pagination,
            teamName: e.target.value,
          });
        }}
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        value={pagination.regionName}
        placeholder="Search by region"
        onChange={(e) => {
          setPagination({
            ...pagination,
            regionName: e.target.value,
          });
        }}
      />
      <select
        placeholder="Tenure"
        value={pagination.tenure}
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        onChange={(e) => {
          setPagination({
            ...pagination,
            tenure: e.target.value,
          });
        }}
      >
        <option value="" disabled selected>
          Tenure
        </option>
        <option value="VETERAN">VETERAN</option>
        <option value="ROOKIE">ROOKIE</option>
        <option value="SOPHOMORE">SOPHOMORE</option>
      </select>
      <DatePicker
        selected={startDate}
        placeholderText="Install Start/End Date"
        onChange={onChangeCreated}
        // showIcon
        startDate={startDate}
        endDate={endDate}
        isClearable
        className="z-100 py-2"
        customInput={
          <input
            placeholder="Select date"
            className=" bg-white  rounded-md border w-80 border-gray-outline outline-none focus-visible:shadow-none placeholder:text-sm focus:ring-2 ring-brown-10 ring-opacity-50 py-2 text-sm text-zinc-600 pl-4 pr-4 disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;"
          />
        }
        selectsRange
        selectsDisabledDaysInRange
      />
    </div>
  );
};

export const Filters = ({ clearFilter, pagination, setPagination }: any) => {
  const filters = { ...pagination };
  delete filters.pageNumber;
  delete filters.pageSize;
  delete filters.year
  const hasFilters = Object.values(filters).some((item) => item);
  
  return (
    <div className="flex items-center gap-2">
     
     <div>
     <label className="text-sm text-gray-800">Filter by: </label>
      <select
        onChange={(eve) =>
          setPagination({
            ...pagination,
            year: eve.target.value,
          })
        }
        defaultValue={pagination.year}
        className="rounded-md border-gray-200 focus:border-gray-300 focus:ring-0 focus:outline-none text-sm"
      >
        <option>Year</option>
        <option value="1999">1999</option>
        <option value="2000">2000</option>
        <option value="2001">2001</option>
        <option value="2002">2002</option>
        <option value="2003">2003</option>
        <option value="2004">2004</option>
        <option value="2005">2005</option>
        <option value="2006">2006</option>
        <option value="2007">2007</option>
        <option value="2008">2008</option>
        <option value="2009">2009</option>
        <option value="2010">2010</option>
        <option value="2011">2011</option>
        <option value="2012">2012</option>
        <option value="2013">2013</option>
        <option value="2015">2015</option>
        <option value="2016">2016</option>
        <option value="2017">2017</option>
        <option value="2018">2018</option>
        <option value="2019">2019</option>
        <option value="2020">2020</option>
        <option value="2021">2021</option>
        <option value="2022">2022</option>
        <option value="2023">2023</option>
      </select>
     </div>
     {hasFilters && (
        <button
          onClick={() => {
            clearFilter();
          }}
          className="bg-black whitespace-nowrap rounded-lg text-white px-3 py-2 text-sm "
        >
          Clear Filters
        </button>
      )}
    </div>
  );
};
