import classNames from "classnames";
import { Loader } from "common/Loader";
import { useRouter } from "hooks/useRouter";
import { formatPhone } from "pages/Contacts";
import { tabStyles } from "pages/GeneralData";
import { Fragment, useCallback, useEffect, useState } from "react";
import UserService from "services/user.service";
import { formatDate } from "utils/funcs";
import swal from "sweetalert";

const service = new UserService();

export const SpecificTraining = () => {
  const { params, push } = useRouter();

  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState("Attendees");
  const [attendees, setAttendees] = useState([]);
  const [rsvp, setRsvp] = useState([]);

  const [data, setData] = useState<any>(null);

  const fetchData = useCallback(() => {
    setLoading(true);
    service
      .getSpecificTraining(params?.id)
      .then((resp) => {
        setData(resp.data.data);
        setAttendees(resp.data.data.users);
        setRsvp(resp.data.data.rsvp);
        // setData(resp.data.data.users.map(item => ({ ...item._source})))
        // setTotalItems(resp.data.data.raffle_entries)
      })
      .catch((err) => {
        console.error(err);
        setData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params]);

  const handleUpdate = (id, status) => {
    swal({
      title: "Are you sure, you want to update the status?",
      icon: "warning",
      className: "w-[500px]",
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
        },
        confirm: {
          text: "Update",
          className: "bg-green-600 text-white",
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        service
          .updateAttendees({ id, status })
          .then((resp) => {
            fetchData()
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClick = (item) => {
    const apiData = {
      training_id: item.training_id,
      first_name: item.first_name,
      last_name: item.last_name,
      phone: item.phone,
      email: item.email,
      id: item._id,
      badgeId: data?.badge_id,
    };

    service
      .updateRSVP(apiData)
      .then((resp) => {
        fetchData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <div className="flex items-center gap-3 ">
        <button
          onClick={() => push("/weekly-trainings")}
          className="rounded-md bg-white border border-gray-200 px-4 py-2.5"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
            />
          </svg>
        </button>
        <div>
          <h1 className="font-medium text-gray-700 tracking-wide text-xl">
            Registered Training
          </h1>
          <p className="text-gray-500 text-sm">
            View all registered training list
          </p>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-1/4">
          <p className="mt-4 text-lg font-semibold">Training Details</p>

          <div className="flex flex-col gap-2">
            <div className="bg-white mt-1.5 shadow-sm border border-zinc-200 flex flex-col  px-4 py-2  rounded-lg divide-y divide-zinc-200">
              <div className="flex justify-between gap-4 py-3">
                <span>Creator</span>
                <span>{data?.creator_name}</span>
              </div>
              <div className="flex justify-between gap-4 py-3">
                <span>Type</span>
                <span>{data?.type}</span>
              </div>
              <div className="flex justify-between gap-4 py-3">
                <span>Trainer</span>
                <span>{data?.trainer_name}</span>
              </div>
              <div className="flex justify-between gap-4 py-3">
                <span>Training Title</span>
                <span>{data?.title}</span>
              </div>
            </div>
          </div>
          <div className="bg-white mt-1.5 shadow-sm border border-zinc-200 flex flex-col  px-4 py-2  rounded-lg divide-y divide-zinc-200">
            <div className="flex justify-between gap-4 py-3">
              <span>Meeting Date</span>
              <span>{formatDate(data?.meeting_date)}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>Start Time</span>
              <span>{data?.meeting_start_time}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>End Time</span>
              <span>{data?.meeting_end_time}</span>
            </div>
          </div>
          <div className="bg-white mt-1.5 shadow-sm border border-zinc-200 flex flex-col  px-4 py-2 rounded-lg divide-y divide-zinc-200">
            <div className="flex justify-between gap-4 py-3">
              <span>Location</span>
              <span>{data?.location}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>Address</span>
              <span className="break-all">{data?.address}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>City</span>
              <span>{data?.city}</span>
            </div>
            <div className="flex justify-between gap-4 py-3">
              <span>State</span>
              <span>{data?.state}</span>
            </div>
          </div>
        </div>

        <div className="flex-1 mt-14">
          <div className="text-sm mb-4 p-2 text-center text-gray-500 rounded-md bg-gray-200">
            <div className="flex flex-wrap -mb-px gap-3">
              <button
                type="button"
                onClick={() => setTab("Attendees")}
                className={classNames(
                  `${tabStyles.default} flex items-center justify-center gap-2`,
                  {
                    [tabStyles.active]: tab === "Attendees",
                  }
                )}
              >
                <span>Attendees</span>
                <span className="bg-gray-200 px-2 py-1 rounded-full text-xs flex-shrink-0">
                  {attendees?.length}
                </span>
              </button>
              <button
                type="button"
                onClick={() => setTab("RSVP")}
                className={classNames(
                  `${tabStyles.default} flex items-center justify-center gap-2`,
                  {
                    [tabStyles.active]: tab === "RSVP",
                  }
                )}
              >
                <span>RSVP</span>
                <span className="bg-gray-200 px-2 py-1 rounded-full text-xs flex-shrink-0">
                  {rsvp?.length}
                </span>
              </button>
            </div>
          </div>

          {tab === "Attendees" ? (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Name
                    </th>

                    <th scope="col" className="px-6 py-3">
                      Phone
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Tenure
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Status
                    </th>
                    <th scope="col" className=" py-3">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {attendees.map((item: any, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {item?.full_name}
                      </th>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {formatPhone(item?.phone)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item?.tenure}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {item?.status}
                      </td>
                      <td>
                        <div className="flex gap-2 items-center">
                          <button
                            type="button"
                            onClick={() => handleUpdate(item._id, "Deny")}
                            className={classNames("rounded-md text-xs px-3 py-2 border border-zinc-300", {
                              "bg-primary text-white": item.status === "Deny"
                            })}
                          >
                            Deny
                          </button>
                          <button
                            type="button"
                            onClick={() => handleUpdate(item._id, "Follow-up")}
                            className={classNames("rounded-md text-xs px-3 py-2 border border-zinc-300", {
                              "bg-orange-400 text-white": item.status === "Follow-up"
                            })}
                          >
                            Follow-up
                          </button>
                          <button
                            type="button"
                            onClick={() => handleUpdate(item._id, "Hire")}
                            className={classNames("rounded-md text-xs px-3 py-2 border border-zinc-300", {
                              "bg-green-500 text-white": item.status === "Hire"
                            })}
                          >
                            Hire
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Name
                    </th>

                    <th scope="col" className="px-6 py-3 ">
                      Phone
                    </th>
                    <th scope="col" className="px-6 py-3 ">
                      Email
                    </th>
                    <th scope="col" className="py-3 ">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rsvp.map((item: any, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {item?.first_name + " " + item.last_name}
                      </th>
                      <td className="px-6 py-4 ">{formatPhone(item?.phone)}</td>
                      <td className="px-6 py-4">{item?.email}</td>
                      <td>
                        <div className="flex gap-2 items-center">
                          <button
                            type="button"
                            onClick={() => handleClick(item)}
                            className="px-3 py-2 text-xs text-white bg-green-600 rounded-md"
                          >
                            Attended
                          </button>
                          <button
                            type="button"
                            onClick={() => handleClick(item)}
                            className="px-3 py-2 text-xs text-white bg-red-600 rounded-md"
                          >
                            Not Attended
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
