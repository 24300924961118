import { SideModal } from "common/SideModal";
import { Table } from "common/Table/Table";
import { useNotifications } from "hooks/useNotification";
import { useRouter } from "hooks/useRouter";
import { Fragment, useCallback, useEffect, useState } from "react";
import UserService from "services/user.service";
import swal from "sweetalert";

const service = new UserService();

export const SpecificCategory = () => {
  const columns = [
    {
      key: "category",
      title: "Category",
      dbColName: "category",
      render: (category: { title: string }) => <p>{category.title}</p>,
    },
    {
      key: "title",
      title: "Video Title",
      dbColName: "title",
      render: (title: string) => {
        return <p className="break-all">{title}</p>;
      },
    },
    {
      key: "video_url",
      title: "Video url",
      render: (values: { video_url: string }) => (
        <div className=" text-blue-500">
          <a href={values.video_url} target="_blank" rel="noreferrer">
            {values?.video_url?.slice(0, 50)}...
          </a>
        </div>
      ),
    },
    {
      key: "video_tag",
      title: "Video tags",
      render: (values: { video_tag: string }) => {
        const array = values.video_tag?.split(",");
        const current = array.length > 2 ? array.slice(0, 3) : array;
        const rest = array.length - current.length;

        return (
          <div className="flex items-center gap-1">
            {current.map((item, key) => (
              <p
                key={key}
                className="px-4 py-1 text-xs flex items-center justify-center text-center rounded-full bg-green-100 text-[#027a48]"
              >
                {item}
              </p>
            ))}
            {rest > 1 && <p className="text-xs text-gray-700">+ {rest} more</p>}
          </div>
        );
      },
    },
    {
      key: "description",
      title: "description",
      render: (values: { description: string }) => (
        <p>{values.description?.slice(0, 20)}...</p>
      ),
    },
    {
      key: "actions",
      title: "Actions",
      render: ({ _id }) => {
        return (
          <button type="button" onClick={() => handleDelete(_id)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 hover:scale-125 transition duration-100 text-primary"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
              />
            </svg>
          </button>
        );
      },
    },
  ];

  const [loading, setLoading] = useState(false);
  const [sideModalOpen, setSideModalOpen] = useState(false);
  const [data, setData] = useState<any>([]);

  const { params, push } = useRouter();

  const [state, setState] = useState({
    title: "",
    description: "",
    video_url: "",
    video_type: "Youtube",
    video_tag: "",
    badgeid: 2719,
    category: params.category,
    category_name: "",
  });

  const { successMessage } = useNotifications();

  const fetchData = useCallback(() => {
    setLoading(true);

    service
      .getCatVideos(params.category)
      .then((resp) => {
        setData(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDelete = (id: string) => {
    swal({
      title: "Are you sure, you want to delete this video?",
      icon: "warning",
      className: "w-[500px]",
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
        },
        confirm: {
          text: "Delete",
          className: "bg-primary text-white",
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        service
          .deleteVideo(id)
          .then((resp) => {
            if (resp.data.success) {
              successMessage("Video deleted successfully");
              const shallowCopy = [...data];
              const newData = shallowCopy.filter((d) => d._id !== id);
              setData(newData);

              
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    service
      .addVideo({ ...state, category_name: data[0].category.title })
      .then(() => {
        fetchData();
        successMessage("Successfully added");
        setSideModalOpen(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Fragment>
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center gap-3">
          <button
            onClick={() => push("/categories")}
            className="rounded-md bg-white border border-gray-200 px-4 py-2.5"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </button>
          <div>
            <h1 className="font-medium text-gray-700 tracking-wide text-xl">
              Videos List
            </h1>
            <p className="text-gray-500 text-sm">View category videos</p>
          </div>
        </div>
        <button
          type="button"
          onClick={() => setSideModalOpen(true)}
          className="bg-green-400 drop-shadow-md text-white rounded-md p-2.5 text-sm flex items-center"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
          </span>
          Add Video
        </button>
      </div>
      <Table
        data={data}
        loadingData={loading}
        totalItems={data.length}
        columns={columns}
      />

      <SideModal
        sidebarOpen={sideModalOpen}
        setSidebarOpen={setSideModalOpen}
        title="Add Video"
      >
        <form onSubmit={handleSubmit} className="flex flex-col gap-2">
          <input
            type="text"
            onChange={(e) =>
              setState({
                ...state,
                title: e.target.value,
              })
            }
            value={state.title}
            placeholder="Video title"
            className="rounded-md focus:border-gray-400 focus:outline-none w-full focus:ring-0 border-gray-300 px-4 py-2.5 text-sm text-gray-800"
          />
          <input
            type="text"
            onChange={(e) =>
              setState({
                ...state,
                video_url: e.target.value,
              })
            }
            value={state.video_url}
            placeholder="Video link"
            className="rounded-md focus:border-gray-400 focus:outline-none w-full focus:ring-0 border-gray-300 px-4 py-2.5 text-sm text-gray-800"
          />
          <input
            type="text"
            onChange={(e) =>
              setState({
                ...state,
                video_tag: e.target.value,
              })
            }
            placeholder="Tags (Add comma separated)"
            className="rounded-md focus:border-gray-400 focus:outline-none w-full focus:ring-0 border-gray-300 px-4 py-2.5 text-sm text-gray-800"
          />
          <select
            onChange={(e) =>
              setState({
                ...state,
                video_type: e.target.value,
              })
            }
            className="rounded-md focus:border-gray-400 focus:outline-none w-full focus:ring-0 border-gray-300 px-4 py-2.5 text-sm text-gray-800"
          >
            <option value="Youtube">Youtube</option>
            <option value="Vimeo">Vimeo</option>
          </select>

          <textarea
            placeholder="Description"
            onChange={(e) =>
              setState({
                ...state,
                description: e.target.value,
              })
            }
            className="rounded-md focus:border-gray-400 focus:outline-none w-full focus:ring-0 border-gray-300 px-4 py-2.5 text-sm text-gray-800"
          ></textarea>
          <button
            type="submit"
            className="bg-green-400 disabled:cursor-not-allowed rounded-md font-medium text-sm disabled:bg-green-300 text-white p-2.5"
          >
            Save
          </button>
        </form>
      </SideModal>
    </Fragment>
  );
};
