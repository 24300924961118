import { Loader } from "common/Loader";
import { Table } from "common/Table/Table";
import { useRouter } from "hooks/useRouter";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import DatePicker from "react-datepicker";
import UserService from "services/user.service";
import { exportToCSV, formatDate, formatDateY } from "utils/funcs";

const service = new UserService();

const TAGS = [
  "YESTERDAY",
  "TODAY",
  "WEEKTODATE",
  "LASTWEEK",
  "LASTMONTH",
  "MONTHTODATE",
  "YEARTODATE",
  "CUSTOM",
];

export const BreezyStats = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [date, setDate] = useState("MONTHTODATE");
  const [showCustom, setShowCustom] = useState(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const columns = [
    { key: "first_name", dbColName: "first_name", title: "first name" },
    { key: "last_name", dbColName: "last_name", title: "last name" },
    {
      key: "start_date",
      dbColName: "start_date",
      title: "start date",
      render: (date) => formatDate(date),
    },
    {
      key: "end_date",
      dbColName: "end_date",
      title: "end date",
      render: (date) => formatDate(date),
    },
    {
      key: "registered_count",
      dbColName: "registered_count",
      title: "registered ",
      render: (d) => <p className="font-semibold">{d}</p>,
    },
    {
      key: "not_registered_count",
      dbColName: "not_registered_count",
      title: "un registered",
      render: (d) => <p className="font-semibold">{d}</p>,
    },
    {
      key: "sales_rep",
      dbColName: "sales_rep",
      title: "sales rep",
      render: (d) => <p className="font-semibold">{d}</p>,
    },
    {
      key: "tech",
      dbColName: "tech",
      title: "tech",
      render: (d) => <p className="font-semibold">{d}</p>,
    },
    {
      key: "solar_closer",
      dbColName: "solar_closer",
      title: "solar closer",
      render: (d) => <p className="font-semibold">{d}</p>,
    },
  ];

  const [series, setSeries] = useState<any>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [exporting, setExporting] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    service
      .getBreezyState(date)
      .then((resp) => {
        setSeries([
          {
            name: "Count",
            data: resp.data.data.map((item: any) => item.count),
          },
         
        ]);
        setCategories(
          resp.data.data.map(
            (item: any) => item._id
          )
        );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [date]);

  const handleChangeDates = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    if ((!start && !end) || end) {
      setDate(`${moment(start).format("YYYY-MM-DD")},${moment(end).format("YYYY-MM-DD")}`);
    }
  };

  if (loading) return <Loader />;

  const handleExport = () => {
    setExporting(true);
    exportToCSV(data, "blitzstats");
    setExporting(false);
  };

  return (
    <Fragment>
      <div className="flex justify-between items-center mb-4">
        <div>
          <h1 className="font-medium text-gray-700 tracking-wide text-xl">
            Breezy Stats
          </h1>
          <p className="text-gray-500 text-sm">View stats</p>
        </div>

        <button
          onClick={handleExport}
          disabled={exporting}
          className="bg-black disabled:bg-opacity-40 text-white flex items-center gap-2 p-2.5 rounded-md"
        >
          <span className="text-xs">
            {exporting ? "Exporting..." : "Export CSV"}
          </span>
          {!exporting ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-loader-3 animate-spin"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 12a9 9 0 0 0 9 9a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9" />
              <path d="M17 12a5 5 0 1 0 -5 5" />
            </svg>
          )}
        </button>
       
      </div>
      <div>
        <select
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-60 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          value={date}
          onChange={(e) => {
            if (e.target.value !== "CUSTOM") {
              setDate(e.target.value);
            } else {
              setShowCustom(true);
            }
          }}
        >
          {TAGS.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
        {showCustom && (
          <DatePicker
            selected={startDate}
            placeholderText="Select range"
            onChange={handleChangeDates}
            startDate={startDate}
            endDate={endDate}
            isClearable
            className="z-100 ml-2 py-2 w-60"
            customInput={
              <input
                placeholder="Select date"
                className=" rounded-md border w-60 border-gray-outline outline-none focus-visible:shadow-none placeholder:text-sm focus:ring-0 focus:border-gray-200 py-2 text-sm text-zinc-600 pl-4 pr-4 disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;"
              />
            }
            selectsRange
            selectsDisabledDaysInRange
          />
        )}
      </div>

      <div className="h-full">
        <ReactApexChart
          options={{
            chart: {
              type: "bar",
              height: 600,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                borderRadiusApplication: "end",
                horizontal: true,
              },
            },
            dataLabels: {
              enabled: true,

              style: {
                fontSize: "12px",
                colors: ["#fff"],
              },
            },
            xaxis: {
              categories,
            },
            legend: {
              position: "top",
              offsetX: -10,
            },
          }}
          series={series}
          type="bar"
          height={500}
        />
      </div>
      {/* <Table
        data={data}
        loadingData={loading}
        columns={columns}
        totalItems={data.length}
        filters={
          <div>
           

               
          </div>
        }
        exportButton={false}
      /> */}
    </Fragment>
  );
};
