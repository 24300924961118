import * as FileSaver from "file-saver";
import moment from "moment";
import * as XLSX from "xlsx";

export function objectToQueryString(obj: any) {
  let queryString = "";
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (queryString.length > 0) {
        queryString += "&";
      }

      // if(key === 'pageNumber') {
      //   queryString += "page=" + encodeURIComponent(obj[key] -1 );
      // }

      // if(key === 'pageSize') {
      //   queryString += "limit=" + encodeURIComponent(obj[key] -1 );
      // }

      if(key === 'page') {
        queryString += key + "=" + encodeURIComponent(obj[key] -1 );
      } else {

        queryString += key + "=" + encodeURIComponent(obj[key]);
      }
    }
  }
  return queryString;
}

export const convertTo12HourFormat = inputValue => {
  const [hours, minutes] = inputValue.split(':')

  let twelveHourFormat: string
  if (hours >= 12) {
      if (hours > 12) {
          twelveHourFormat = `${hours - 12}:${minutes} PM`
      } else {
          twelveHourFormat = `${hours}:${minutes} PM`
      }
  } else {
      if (hours === '00') {
          twelveHourFormat = `12:${minutes} AM`
      } else {
          twelveHourFormat = `${hours}:${minutes} AM`
      }
  }

  return twelveHourFormat
}

export const formatDate = (arg: string) => {
  if(arg) {
    const formattedDate = moment(arg).format('MM/DD/YYYY');
    return formattedDate
    // console.log({ date:  date.getDate() })
    // /* Date format you have */
    // let dateMDY = `${
    //   date.getDate()
    // }/${date.getMonth() + 1}/${date.getFullYear()}`;
    // /* Date converted to MM-DD-YYYY format */
    // return dateMDY;
  } else {
    return ''
  }
}

export const formatDateY = (date) => {
  if(date) {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getUTCDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }
  
};

export const getLastWeekDateRange = () => {
  const currentDate = new Date();

  // Calculate the end date, which is today (28th)
  const endDate = new Date(currentDate);

  // Calculate the start date, which is 6 days before the end date
  const startDate = new Date(endDate);
  startDate.setDate(endDate.getDate() - 6);

  // Format the dates to YYYY-MM-DD
  const formatDate = (date) => date.toISOString().split('T')[0];

  return {
    start: formatDate(startDate),
    end: formatDate(endDate),
  };
};


// Function to flatten an object
const flattenObject = (obj, parent = "", res = {}) => {
  for (let key in obj) {
    const propName = parent ? `${parent}.${key}` : key;
    if (typeof obj[key] === "object" && obj[key] !== null && !Array.isArray(obj[key])) {
      flattenObject(obj[key], propName, res); // Recursively flatten
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
};


// export const exportToCSV = (apiData, fileName) => {
//   const fileType =
//     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
//   const fileExtension = ".xlsx";
//   const ws = XLSX.utils.json_to_sheet(apiData);
//   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
//   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
//   const data = new Blob([excelBuffer], { type: fileType });
//   FileSaver.saveAs(data, fileName + fileExtension);
// };


// Export to CSV
export const exportToCSV = (apiData, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  // Flatten each object in the array
  const flattenedData = apiData.map(item => flattenObject(item));

  const ws = XLSX.utils.json_to_sheet(flattenedData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};












