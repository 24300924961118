import classNames from "classnames";
import { Modal } from "common/Modal";
import { useEffect, useState } from "react";
import UserService from "services/user.service";
import { formatPhone } from "./Contacts";
import DatePicker from "react-datepicker";
import { formatDateY } from "utils/funcs";
import moment from "moment";

const service = new UserService();

const GROUP = [
  "Rep",
  "Recruiter",
  "Team Lead",
  "Manager",
  "Senior Manager",
  "Office",
  "Regional",
  "Senior Regional",
  "Partner",
  "Division",
  "Downline",
];

const RANGE = [
  "YESTERDAY",
  "TODAY",
  "LAST WEEK",
  "WEEK TO DATE",
  "LAST MONTH",
  "MONTH TO DATE",
  "LAST SATURDAY",
  "QUARTER TO DATE",
  "LAST QUARTER",
  "YEAR TO DATE",
  "CUSTOM",
];

const StageCircle = ({ stage, onClick, count, lastYear }) => {
  const getColor = (s) => {
    if (s === "Contact" || s === "Contact+") {
      return "border-[#EE8332]";
    } else if (s === "SH Pro" || s === "SH Pro+") {
      return "border-[#7715F5]";
    } else if (s === "Recruiter" || s === "Recruiter+") {
      return "border-[#57C1F9]";
    } else if (s === "Signed" || s === "Signed+") {
      return "border-[#02F900]";
    } else {
      return "border-[#EE8332]";
    }
  };

  return (
    <div
      onClick={onClick}
      className="flex hover:bg-gray-100 p-3 rounded-md cursor-pointer items-center flex-col gap-2"
    >
      <div
        className={`border-[7px] ${getColor(
          stage
        )} rounded-full w-32 h-32 text-sm flex  items-center justify-center flex-shrink-0`}
      >
        <div className="flex items-center flex-col">
          <p className="font-semibold leading-none text-2xl text-zinc-800">
            {count}
          </p>
          <p className="text-zinc-600 text-sm">{lastYear}</p>
        </div>
      </div>
      <span className="text-gray-800 font-medium">{stage}</span>
    </div>
  );
};

export const ContactReports = () => {
  const [state, setState] = useState({
    date: "YEAR TO DATE",
    tag: "Division",
    group_by: "Division",
    userid: "2719",
    users: "All",
  });
  const [users, setUsers] = useState([]);
  const [tag, setTag] = useState("Division");
  const [loadingData, setLoadingData] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showCustom, setShowCustom] = useState(false)
  const [selected, setSelected] = useState<string[]>([]);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [selectedData, setSelectedData] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setLoadingData(true);
    service
      .getSummary({...state, date: state.date.replace(/\s/g, ""),})
      .then((resp) => {
        setData(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingData(false);
      });
  }, [state]);

  useEffect(() => {
    setLoadingUser(true);
    service
      .getContactUsers(tag)
      .then((resp) => {
        setUsers(resp.data.data);
        setSelected(resp.data.data.map((item: any) => item.badgeid));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingUser(false);
      });
  }, [tag]);

  const filteredUsers = users.filter((item: any) =>
    item?.name?.toLowerCase().includes(search.toLowerCase())
  );

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected(filteredUsers.map((item: any) => item.badgeid));
    } else {
      setSelected([]);
    }
  };

  const handleClick = () => {
    const ids = selected.map((i) => i);
    setState({
      ...state,
      tag,
      users: ids.length === users.length ? "All" : ids.join(","),
    });

    setShowModal(false);
  }

  const handleChangeDates = (dates) => {
      const [start, end] = dates;
  
      setStartDate(start);
      setEndDate(end);
  
        if((!start && !end) || end) {
          setState({
            ...state,
            date: `${moment(start).format("YYYY-MM-DD")},${moment(end).format("YYYY-MM-DD")}`,
          })
        }
    }

  return (
    <>
      <div className="flex flex-col md:flex-row items-start gap-4">
        <div className="flex w-full md:w-1/2 flex-col flex-1">
          <div className=" grid grid-cols-2 gap-4 bg-white rounded-lg border p-5">
            <button
              type="button"
              className="bg-primary text-white px-3 py-2 rounded-md"
              onClick={() => setShowModal(true)}
            >
              {tag ? tag : "Hierarchy"}
            </button>
            <select
            value={state.group_by}
              onChange={(e) =>
                setState({
                  ...state,
                  group_by: e.target.value,
                })
              }
              className="rounded-md flex-1 focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
            >
              <option>Group By</option>
              {GROUP.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
            <select
            value={state.date}
              onChange={(e) => {
                if (e.target.value !== "CUSTOM") {
                  setState({
                    ...state,
                    date: e.target.value.replace(/\s/g, ""),
                  });
                } else {
                  setShowCustom(true)
                }
              }}
              className="rounded-md flex-1 focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
            >
              <option>Range</option>
              {RANGE.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
            {showCustom && 
              
                    <DatePicker
                      selected={startDate}
                      placeholderText="Select range"
                      onChange={handleChangeDates}
                      startDate={startDate}
                      endDate={endDate}
                      isClearable
                      className="z-100 py-2"
                      customInput={
                        <input
                          placeholder="Select date"
                          className=" bg-white rounded-md border w-full border-gray-outline outline-none focus-visible:shadow-none placeholder:text-sm focus:ring-2 ring-brown-10 ring-opacity-50 py-2 text-sm text-zinc-600 pl-4 pr-4 disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;"
                        />
                      }
                      selectsRange
                      selectsDisabledDaysInRange
                    />
          }
          </div>
          <div className="relative mt-4 overflow-x-auto shadow-sm border bg-white sm:rounded-lg">
            <table className="min-w-full text-sm text-left rtl:text-right text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                <th scope="col" className="px-6 py-3">
                    #
                  </th>
                  <th scope="col" className="px-2 py-3">
                    Name
                  </th>

                  <th scope="col" className="px-2 py-3">
                    Phone
                  </th>
                  <th scope="col" className="px-2 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-2 py-3">
                    Leader
                  </th>
                </tr>
              </thead>
              <tbody>
                {selectedData?.length > 0 ? (
                  selectedData.map((item: any, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b "
                    >
                       <td className="pl-6 py-4 whitespace-nowrap">
                        {index+1}
                      </td>
                      <th
                        scope="row"
                        className="px-2 py-4 font-medium whitespace-nowrap text-gray-900"
                      >
                        {item?.name}
                      </th>
                      <td className="px-2 py-4 whitespace-nowrap">
                        {formatPhone(item?.phone)}
                      </td>
                      <td className="px-2 py-4 whitespace-nowrap">
                        {item?.email}
                      </td>
                      <td className="px-2 py-4 whitespace-nowrap">
                        {item?.contact_leader}
                      </td>
                    </tr>
                  ))
                ) : (
                 <tr> <td colSpan={13} className="text-center p-5">
                    <svg
                      className="w-16 h-16 mx-auto text-gray-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.2}
                        d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                      />
                    </svg>
                    <p className="text-gray-900 font-medium mt-1">
                      No data found
                    </p>
                  </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {!loadingData ? (
          <div className="flex-1 w-full place-items-center grid grid-cols-2 md:grid-cols-4 gap-4 bg-white shadow-sm rounded-lg border p-5">
            {data.map((item: any, index: number) => (
              <StageCircle
                key={index}
                stage={item.stage}
                count={item.count}
                onClick={() => setSelectedData(item.data)}
                lastYear={item.lastyear}
              />
            ))}
          </div>
        ) : (
          <div className="flex-1 bg-white rounded-md shadow-sm text-center p-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler mx-auto text-primary icon-tabler-loader-3 animate-spin"
              width="40"
              height="40"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 12a9 9 0 0 0 9 9a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9" />
              <path d="M17 12a5 5 0 1 0 -5 5" />
            </svg>
          </div>
        )}
      </div>
      <Modal
        showModal={showModal}
        onClick={() => {
          setTag("");
          setSelected([]);
        }}
        setShowModal={setShowModal}
        title={"Heirarchy"}
      >
        <div className="overflow-auto h-[35rem] p-2">
          <div className="grid grid-cols-5 gap-3">
            {GROUP.map((item) => (
              <button
                key={item}
                type="button"
                onClick={() => setTag(item)}
                className={classNames(
                  "border-black border hover:bg-black hover:text-white text-center rounded-md px-3 py-2",
                  { "bg-black text-white": tag === item }
                )}
              >
                {item}
              </button>
            ))}
          </div>
          <div className="mt-3">
            <input
              type="text"
              onChange={(e) => setSearch(e.target.value)}
              className="border w-full rounded-md border-zinc-300 p-2"
            />
          </div>

          <label className="flex items-center gap-2 mt-2 justify-end">
            <input
              type="checkbox"
              defaultChecked={
                selected.length === filteredUsers.length ||
                selected.length === 0
              }
              onChange={handleSelectAll}
            />
            Select All
          </label>

          {loadingUser ? (
            <p>Loading, please wait...</p>
          ) : (
            filteredUsers.map((item: any, index) => (
              <div key={item.badgeid} className="flex items-center gap-2 py-2 ">
                <div>
                  <input
                    checked={selected.includes(item.badgeid)}
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelected((prev) => [...prev, item.badgeid]);
                      } else {
                        setSelected((prev) =>
                          prev.filter((id) => id !== item.badgeid)
                        );
                      }
                    }}
                  />
                </div>
                <p>{item.name}</p>
              </div>
            ))
          )}
        </div>
        <div className="flex gap-2">
          <button
            type="button"
            onClick={() => {
              setTag("");
              setSelected([]);
              setShowModal(false);
            }}
            className="flex-1 rounded-md py-2 text-white bg-primary"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleClick}
            className="flex-1 rounded-md py-2 text-white bg-black"
          >
            Done
          </button>
        </div>
      </Modal>
    </>
  );
};
