import classNames from "classnames";
import { Table } from "common/Table/Table";
import { ChangeEvent, Fragment, useCallback, useEffect, useState } from "react";
import UserService from "services/user.service";
import swal from "sweetalert";
import {
  CampusReel,
  Breezy,
  Positions,
  DigitalHire,
  DigitalHireSocial,
  Acuity,
} from "./";
import { exportToCSV } from "utils/funcs";
import { CandidateStage } from "common/CandidateStage";
import { useDebounce } from "hooks/useDebounce";
import { formatPhone } from "pages/Contacts";
import { useNotifications } from "hooks/useNotification";
import CopyText from "common/CopyText";

const service = new UserService();

export const Candidates = () => {
  const columns = [
    { key: "first_name", dbColName: "first_name", title: "first name" },
    { key: "last_name", dbColName: "last_name", title: "last name" },
    {
      key: "full_name",
      dbColName: "full_name",
      title: "full name",
      freeze: true,
      freezeIndex: "left-0",
    },
    {
      key: "CANDIDATE_STAGE_ICON",
      title: "CANDIDATE STAGE",
      render: (value: {
        stage: string;
        first_name: string;
        last_name: string;
      }) => {
        return (
          <CandidateStage
            firstName={value.first_name}
            lastName={value.last_name}
            stage={value.stage}
          />
        );
      },
    },
    {
      key: "phone",
      dbColName: "phone",
      title: "phone",
      render: (phone) => <CopyText copyText={phone} text={formatPhone(phone)} />,
    },
    { key: "birthday", dbColName: "birthday", title: "birthday" },
    { key: "email", dbColName: "email", title: "email" },
    // { key: "source", dbColName: "source", title: "source" },
    // {
    //   key: "source_category",
    //   dbColName: "source_category",
    //   title: "source category",
    // },
    // { key: "source_note", dbColName: "source_note", title: "source note" },
    // { key: "source_id", dbColName: "source_id", title: "source id" },
    // {
    //   key: "origination_date",
    //   dbColName: "origination_date",
    //   title: "origination_date",
    // },
    { key: "stage", dbColName: "stage", title: "stage" },

    {
      key: "outreach",
      dbColName: "outreach",
      title: "outreach",
      render: (outreach) => <span>{outreach.outreach}</span>,
    },
    {
      key: "action",
      dbColName: "_id",
      title: "Actions",
      freeze: true,
      freezeIndex: "right-0",
      render: id =><button
      type="button"
      className="text-red-500 text-center"
      onClick={() => handleDelete(id)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="h-6 w-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
        />
      </svg>
    </button>
    }
    // { key: "home_address", dbColName: "home_address", title: "home address" },
    // { key: "home_city", dbColName: "home_city", title: "home city" },
    // { key: "home_state", dbColName: "home_state", title: "home state" },
    // {
    //   key: "home_zip_code",
    //   dbColName: "home_zip_code",
    //   title: "home zip code",
    // },
    // {
    //   key: "home_timezone",
    //   dbColName: "home_timezone",
    //   title: "home timezone",
    // },
    // {
    //   key: "candidate_type",
    //   dbColName: "candidate_type",
    //   title: "candidate type",
    // },
    // {
    //   key: "college_degree_type",
    //   dbColName: "college_degree_type",
    //   title: "college degree type",
    // },
    // {
    //   key: "college_enroll_date",
    //   dbColName: "college_enroll_date",
    //   title: "college enroll date",
    // },
    // {
    //   key: "college_expected_graduation_year",
    //   dbColName: "college_expected_graduation_year",
    //   title: "college expected graduation year",
    // },
    // {
    //   key: "college_major",
    //   dbColName: "college_major",
    //   title: "college_major",
    // },
    // { key: "college_name", dbColName: "college_name", title: "college_name" },
    // {
    //   key: "college_address",
    //   dbColName: "college_address",
    //   title: "college_address",
    // },
    // { key: "college_city", dbColName: "college_city", title: "college_city" },
    // {
    //   key: "college_state",
    //   dbColName: "college_state",
    //   title: "college_state",
    // },
    // {
    //   key: "college_zip_code",
    //   dbColName: "college_zip_code",
    //   title: "college_zip_code",
    // },
    // { key: "birthday", dbColName: "birthday", title: "birthday" },
    // { key: "campaign_id", dbColName: "campaign_id", title: "campaign_id" },
    // {
    //   key: "recruiting_location",
    //   dbColName: "recruiting_location",
    //   title: "recruiting_location",
    // },
    // {
    //   key: "interviewer_id",
    //   dbColName: "interviewer_id",
    //   title: "interviewer_id",
    // },
    // {
    //   key: "interviewer_name",
    //   dbColName: "interviewer_name",
    //   title: "interviewer_name",
    // },
    // {
    //   key: "interviewer_phone",
    //   dbColName: "interviewer_phone",
    //   title: "interviewer_phone",
    // },
    // {
    //   key: "interviewer_email",
    //   dbColName: "interviewer_email",
    //   title: "interviewer_email",
    // },
    // {
    //   key: "interviewer_timezone",
    //   dbColName: "interviewer_timezone",
    //   title: "interviewer_timezone",
    // },
    // {
    //   key: "sales_leader_id",
    //   dbColName: "sales_leader_id",
    //   title: "sales_leader_id",
    // },
    // {
    //   key: "sales_leader_name",
    //   dbColName: "sales_leader_name",
    //   title: "sales_leader_name",
    // },
    // {
    //   key: "sales_leader_phone",
    //   dbColName: "sales_leader_phone",
    //   title: "sales_leader_phone",
    // },
    // {
    //   key: "sales_leader_email",
    //   dbColName: "sales_leader_email",
    //   title: "sales_leader_email",
    // },
    // {
    //   key: "sales_leader_timezone",
    //   dbColName: "sales_leader_timezone",
    //   title: "sales_leader_timezone",
    // },
    // { key: "outreach", dbColName: "outreach", title: "outreach" },
    // {
    //   key: "recruiter_notes",
    //   dbColName: "recruiter_notes",
    //   title: "recruiter_notes",
    // },
    // { key: "intro_video", dbColName: "intro_video", title: "intro_video" },
    // { key: "follow_up", dbColName: "follow_up", title: "follow_up" },
    // {
    //   key: "follow_up_call_date",
    //   dbColName: "follow_up_call_date",
    //   title: "follow_up_call_date",
    // },
    // {
    //   key: "follow_up_call_time",
    //   dbColName: "follow_up_call_time",
    //   title: "follow_up_call_time",
    // },
    // {
    //   key: "follow_up_candidate_time_zone",
    //   dbColName: "follow_up_candidate_time_zone",
    //   title: "follow_up_candidate_time_zone",
    // },
    // {
    //   key: "follow_up_recruiter_timezone",
    //   dbColName: "follow_up_recruiter_timezone",
    //   title: "follow_up_recruiter_timezone",
    // },
    // {
    //   key: "follow_up_notes",
    //   dbColName: "follow_up_notes",
    //   title: "follow_up_notes",
    // },
    // {
    //   key: "candidate_interest",
    //   dbColName: "candidate_interest",
    //   title: "candidate_interest",
    // },
    // {
    //   key: "recruiter_interest",
    //   dbColName: "recruiter_interest",
    //   title: "recruiter_interest",
    // },
    // {
    //   key: "screening_result",
    //   dbColName: "screening_result",
    //   title: "screening_result",
    // },
    // {
    //   key: "screening_date",
    //   dbColName: "screening_date",
    //   title: "screening_date",
    // },
    // {
    //   key: "screening_notes",
    //   dbColName: "screening_notes",
    //   title: "screening_notes",
    // },
    // { key: "interview", dbColName: "interview", title: "interview" },
    // {
    //   key: "interview_scheduled_date",
    //   dbColName: "interview_scheduled_date",
    //   title: "interview_scheduled_date",
    // },
    // {
    //   key: "interview_scheduled_time",
    //   dbColName: "interview_scheduled_time",
    //   title: "interview_scheduled_time",
    // },
    // {
    //   key: "interview_scheduled_c_timezone",
    //   dbColName: "interview_scheduled_c_timezone",
    //   title: "interview_scheduled_c_timezone",
    // },
    // {
    //   key: "interview_scheduled_notes",
    //   dbColName: "interview_scheduled_notes",
    //   title: "interview_scheduled_notes",
    // },
    // {
    //   key: "interview_notes",
    //   dbColName: "interview_notes",
    //   title: "interview_notes",
    // },
    // {
    //   key: "interview_result",
    //   dbColName: "interview_result",
    //   title: "interview_result",
    // },
    // {
    //   key: "agreement_sent_date",
    //   dbColName: "agreement_sent_date",
    //   title: "agreement_sent_date",
    // },
    // { key: "signed", dbColName: "signed", title: "signed" },
    // { key: "onboarded", dbColName: "onboarded", title: "onboarded" },
    // {
    //   key: "street_genie_login",
    //   dbColName: "street_genie_login",
    //   title: "street_genie_login",
    // },
    // {
    //   key: "sales_training",
    //   dbColName: "sales_training",
    //   title: "sales_training",
    // },
    // {
    //   key: "assign_candidate_bid",
    //   dbColName: "assign_candidate_bid",
    //   title: "assign_candidate_bid",
    // },
    // { key: "bootcamp", dbColName: "bootcamp", title: "bootcamp" },
    // {
    //   key: "bootcamp_type",
    //   dbColName: "bootcamp_type",
    //   title: "bootcamp_type",
    // },
    // {
    //   key: "bootcamp_scheduled_date",
    //   dbColName: "bootcamp_scheduled_date",
    //   title: "bootcamp_scheduled_date",
    // },
    // {
    //   key: "bootcamp_scheduled_time",
    //   dbColName: "bootcamp_scheduled_time",
    //   title: "bootcamp_scheduled_time",
    // },
    // {
    //   key: "bootcamp_timezone",
    //   dbColName: "bootcamp_timezone",
    //   title: "bootcamp_timezone",
    // },
    // {
    //   key: "bootcamp_address",
    //   dbColName: "bootcamp_address",
    //   title: "bootcamp_address",
    // },
    // {
    //   key: "bootcamp_notes",
    //   dbColName: "bootcamp_notes",
    //   title: "bootcamp_notes",
    // },
    // {
    //   key: "most_recent_action",
    //   dbColName: "most_recent_action",
    //   title: "most_recent_action",
    // },
    // {
    //   key: "most_recent_ts",
    //   dbColName: "most_recent_ts",
    //   title: "most_recent_ts",
    // },
    // { key: "outreach_ts", dbColName: "outreach_ts", title: "outreach_ts" },
    // {
    //   key: "intro_video_ts",
    //   dbColName: "intro_video_ts",
    //   title: "intro_video_ts",
    // },
    // {
    //   key: "follow_up_call_ts",
    //   dbColName: "follow_up_call_ts",
    //   title: "follow_up_call_ts",
    // },
    // {
    //   key: "candidate_interest_ts",
    //   dbColName: "candidate_interest_ts",
    //   title: "candidate_interest_ts",
    // },
    // {
    //   key: "recruiter_interest_ts",
    //   dbColName: "recruiter_interest_ts",
    //   title: "recruiter_interest_ts",
    // },
    // {
    //   key: "screening_result_ts",
    //   dbColName: "screening_result_ts",
    //   title: "screening_result_ts",
    // },
    // {
    //   key: "recruiter_notes_ts",
    //   dbColName: "recruiter_notes_ts",
    //   title: "recruiter_notes_ts",
    // },
    // { key: "interview_ts", dbColName: "interview_ts", title: "interview_ts" },
    // {
    //   key: "interview_notes_ts",
    //   dbColName: "interview_notes_ts",
    //   title: "interview_notes_ts",
    // },
    // {
    //   key: "interview_results_ts",
    //   dbColName: "interview_results_ts",
    //   title: "interview_results_ts",
    // },
    // {
    //   key: "agreement_sent_ts",
    //   dbColName: "agreement_sent_ts",
    //   title: "agreement_sent_ts",
    // },
    // {
    //   key: "agreement_signed_ts",
    //   dbColName: "agreement_signed_ts",
    //   title: "agreement_signed_ts",
    // },
    // { key: "onboarded_ts", dbColName: "onboarded_ts", title: "onboarded_ts" },
    // {
    //   key: "street_geinie_login_ts",
    //   dbColName: "street_geinie_login_ts",
    //   title: "street_geinie_login_ts",
    // },
    // {
    //   key: "sales_training_ts",
    //   dbColName: "sales_training_ts",
    //   title: "sales_training_ts",
    // },
    // {
    //   key: "sales_leader_assigned_ts",
    //   dbColName: "sales_leader_assigned_ts",
    //   title: "sales_leader_assigned_ts",
    // },
    // { key: "bootcamp_ts", dbColName: "bootcamp_ts", title: "bootcamp_ts" },
    // {
    //   key: "bootcamp_type_ts",
    //   dbColName: "bootcamp_type_ts",
    //   title: "bootcamp_type_ts",
    // },
    // {
    //   key: "bootcamp_scheduled_ts",
    //   dbColName: "bootcamp_scheduled_ts",
    //   title: "bootcamp_scheduled_ts",
    // },
    // {
    //   key: "bootcamp_notes_ts",
    //   dbColName: "bootcamp_notes_ts",
    //   title: "bootcamp_notes_ts",
    // },
    // { key: "lead_set_ts", dbColName: "lead_set_ts", title: "lead_set_ts" },
    // {
    //   key: "lead_closed_ts",
    //   dbColName: "lead_closed_ts",
    //   title: "lead_closed_ts",
    // },
    // { key: "employee_id", dbColName: "employee_id", title: "employee_id" },
    // {
    //   key: "recruiter_name",
    //   dbColName: "recruiter_name",
    //   title: "recruiter_name",
    // },
    // { key: "team_name", dbColName: "team_name", title: "team_name" },
    // {
    //   key: "mgmt_group_name",
    //   dbColName: "mgmt_group_name",
    //   title: "mgmt_group_name",
    // },
    // {
    //   key: "sr_mgmt_group_name",
    //   dbColName: "sr_mgmt_group_name",
    //   title: "sr_mgmt_group_name",
    // },
    // { key: "region_name", dbColName: "region_name", title: "region_name" },
    // {
    //   key: "senior_region_name",
    //   dbColName: "senior_region_name",
    //   title: "senior_region_name",
    // },
    // {
    //   key: "partnership_name",
    //   dbColName: "partnership_name",
    //   title: "partnership_name",
    // },
    // {
    //   key: "division_name",
    //   dbColName: "division_name",
    //   title: "division_name",
    // },
    // {
    //   key: "contact_leader_id",
    //   dbColName: "contact_leader_id",
    //   title: "contact_leader_id",
    // },
    // {
    //   key: "contact_leader_name",
    //   dbColName: "contact_leader_name",
    //   title: "contact_leader_name",
    // },
    // {
    //   key: "contact_leader_phone_number",
    //   dbColName: "contact_leader_phone_number",
    //   title: "contact_leader_phone_number",
    // },
    // {
    //   key: "contact_leader_email",
    //   dbColName: "contact_leader_email",
    //   title: "contact_leader_email",
    // },
    // {
    //   key: "candidate_leader_id",
    //   dbColName: "candidate_leader_id",
    //   title: "candidate_leader_id",
    // },
    // {
    //   key: "candidate_leader_name",
    //   dbColName: "candidate_leader_name",
    //   title: "candidate_leader_name",
    // },
    // {
    //   key: "candidate_leader_phone_number",
    //   dbColName: "candidate_leader_phone_number",
    //   title: "candidate_leader_phone_number",
    // },
    // {
    //   key: "candidate_leader_email",
    //   dbColName: "candidate_leader_email",
    //   title: "candidate_leader_email",
    // },
    // {
    //   key: "summer_deployment",
    //   dbColName: "summer_deployment",
    //   title: "summer_deployment",
    // },
    // {
    //   key: "summer_deployment_recruiter",
    //   dbColName: "summer_deployment_recruiter",
    //   title: "summer_deployment_recruiter",
    // },
    // {
    //   key: "outreach_previous",
    //   dbColName: "outreach_previous",
    //   title: "outreach_previous",
    // },
    // {
    //   key: "video_started",
    //   dbColName: "video_started",
    //   title: "video_started",
    // },
    // { key: "video_viewed", dbColName: "video_viewed", title: "video_viewed" },
    // {
    //   key: "video_completed",
    //   dbColName: "video_completed",
    //   title: "video_completed",
    // },
    // {
    //   key: "interview_city",
    //   dbColName: "interview_city",
    //   title: "interview_city",
    // },
    // {
    //   key: "interview_state",
    //   dbColName: "interview_state",
    //   title: "interview_state",
    // },
    // {
    //   key: "interview_address",
    //   dbColName: "interview_address",
    //   title: "interview_address",
    // },
    // {
    //   key: "interview_zipcode",
    //   dbColName: "interview_zipcode",
    //   title: "interview_zipcode",
    // },
    // {
    //   key: "sales_interview_result",
    //   dbColName: "sales_interview_result",
    //   title: "sales_interview_result",
    // },
    // {
    //   key: "sales_interview_ts",
    //   dbColName: "sales_interview_ts",
    //   title: "sales_interview_ts",
    // },
    // {
    //   key: "sales_interview",
    //   dbColName: "sales_interview",
    //   title: "sales_interview",
    // },
    // {
    //   key: "sales_interview_date",
    //   dbColName: "sales_interview_date",
    //   title: "sales_interview_date",
    // },
    // {
    //   key: "sales_interview_time",
    //   dbColName: "sales_interview_time",
    //   title: "sales_interview_time",
    // },
    // {
    //   key: "sales_interview_timezone",
    //   dbColName: "sales_interview_timezone",
    //   title: "sales_interview_timezone",
    // },
    // {
    //   key: "sales_interview_notes",
    //   dbColName: "sales_interview_notes",
    //   title: "sales_interview_notes",
    // },
    // {
    //   key: "onboarding_notes",
    //   dbColName: "onboarding_notes",
    //   title: "onboarding_notes",
    // },
    // {
    //   key: "leader_region",
    //   dbColName: "leader_region",
    //   title: "leader_region",
    // },
    // {
    //   key: "screening_points",
    //   dbColName: "screening_points",
    //   title: "screening_points",
    // },
    // {
    //   key: "recruiter_interest_notes",
    //   dbColName: "recruiter_interest_notes",
    //   title: "recruiter_interest_notes",
    // },
    // {
    //   key: "user_timezone",
    //   dbColName: "user_timezone",
    //   title: "user_timezone",
    // },
    // { key: "user_date", dbColName: "user_date", title: "user_date" },
    // { key: "user_time", dbColName: "user_time", title: "user_time" },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [filterData, setFilterData] = useState<any>(null);
  const [exporting, setExporting] = useState(false);

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    fullName: "",
    pageSize: 100,
    homeCity: "",
    homeState: "",
    outreach: "",
    recruitingCategory: "",
    recruitingLocation: "",
    startDate: "",
    endDate: "",
    source: "",
    sourceCategory: "",
  });
  const [tab, setTab] = useState("Candidates");

  // -----------------------------
  const debounced = useDebounce(pagination, 500);

  const { successMessage } = useNotifications()

  const fetchData = useCallback(() => {
    setLoading(true);
    service
      .getCanidates(debounced)
      .then((resp) => {
        setData(resp.data.data);
        setTotalItems(resp.data.totalDocument);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [debounced]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  // ----------------------------

  const handleDelete = (id) => {
    swal({
      title: "Are you sure, you want to delete this contact?",
      icon: "warning",
      className: "w-[500px]",
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
        },
        confirm: {
          text: "Delete",
          className: "bg-primary text-white",
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        service
        .deleteCandidate(id)
        .then((resp) => {
          if (resp.data.success) {
            successMessage("Contact deleted successfully");
            const index = data.findIndex(
              (item: { _id: string }) => item._id === id
            );
            const shallowCopy = [...data];
            shallowCopy.splice(index, 1);
            setData(shallowCopy);
          }
        })
        .catch((error) => console.log(error));
      }
    })
  }

  useEffect(() => {
    service
      .getCandidateFilters()
      .then((res) => {
        setFilterData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleExport = () => {
    setExporting(true);
    const pagin: any = { ...pagination };
    delete pagin.pageNumber;
    delete pagin.pageSize;
    service
      .downloadCanidates(pagin)
      .then((resp) => {
        exportToCSV(
          resp.data.data.map((item) => ({ ...item._source })),
          "candidates"
        );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setExporting(false);
      });
  };

  const clearFilter = () => {
    setPagination({
      pageNumber: 1,
      fullName: "",
      pageSize: 100,
      // origination_date: "",
      homeCity: "",
      homeState: "",
      outreach: "",
      recruitingCategory: "",
      recruitingLocation: "",
      startDate: "",
      endDate: "",
      source: "",
      sourceCategory: "",
    })
  }

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-800 tracking-wide text-xl">
          {tab}
        </h1>
        <p className="text-gray-500 text-sm">View all {tab}</p>
      </div>

      {/* Tables */}
      {tab === "Candidates" ? (
        <Table
          data={data}
          loadingData={loading}
          handleExport={handleExport}
          setPagination={setPagination}
          exporting={exporting}
          showSearch={false}
          pagination={pagination}
          totalItems={totalItems}
          setData={setData}
          filters={
            <HeaderFilter
            clearFilter={clearFilter}
              pagination={pagination}
              setPagination={setPagination}
            />
          }
          columnFilters={
            <ColumnFilters
              filterData={filterData}
              pagination={pagination}
              setPagination={setPagination}
            />
          }
          columns={columns}
        />
      ) : tab === "Reels" ? (
        <CampusReel />
      ) : tab === "Breezy" ? (
        <Breezy />
      ) : tab === "Positions" ? (
        <Positions />
      ) : tab === "Digital Hire" ? (
        <DigitalHire />
      ) : tab === "Acuity" ? (
        <Acuity />
      ) : (
        <DigitalHireSocial />
      )}

      {/* Tabs */}
      <div className="text-sm mt-4 p-2 text-center text-gray-500 rounded-md bg-gray-200">
        <div className="flex flex-wrap -mb-px gap-3">
          <button
            type="button"
            onClick={() => setTab("Candidates")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Candidates",
            })}
          >
            Candidates
          </button>
          <button
            type="button"
            onClick={() => setTab("Positions")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Positions",
            })}
          >
            Positions
          </button>
          <button
            type="button"
            onClick={() => setTab("Reels")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Reels",
            })}
          >
            Campus Reels
          </button>
          <button
            type="button"
            onClick={() => setTab("Breezy")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Breezy",
            })}
          >
            Breezy
          </button>
          <button
            type="button"
            onClick={() => setTab("Digital Hire")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Digital Hire",
            })}
          >
            Digital Hire
          </button>
          <button
            type="button"
            onClick={() => setTab("Digital Hire Social")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Digital Hire Social",
            })}
          >
            Digital Hire Social
          </button>
          <button
            type="button"
            onClick={() => setTab("Acuity")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: tab === "Acuity",
            })}
          >
            Acuity
          </button>
        </div>
      </div>
    </Fragment>
  );
};

const ColumnFilters = ({ pagination, setPagination, filterData }) => {
  const handleChange = (
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target;

    setPagination({
      ...pagination,
      [name]: value,
    });
  };

  return (
    <div className="flex z-50 flex-wrap gap-3 mt-4">
      <div className="flex flex-1 basis-2 flex-col gap-1">
        <label className="text-xs whitespace-nowrap text-gray-800">From</label>
        <input
          className={tabStyles.select}
          type="date"
          onChange={handleChange}
          name="startDate"
          value={pagination.startDate}
        />
      </div>
      <div className=" flex flex-1 basis-2 flex-col gap-1">
        <label className="text-xs whitespace-nowrap text-gray-800">To</label>
        <input
          className={tabStyles.select}
          type="date"
          onChange={handleChange}
          name="endDate"
          value={pagination.endDate}
        />
      </div>

      <div className="flex flex-1 basis-2 flex-col gap-1">
        <label className="text-xs whitespace-nowrap text-gray-800">
          Home State
        </label>
        <select
          onChange={handleChange}
          value={pagination.homeState}
          name="homeState"
          className={tabStyles.select}
        >
          <option value="">Select</option>
          {filterData?.home_state
            .filter((item) => item.HOME_STATE)
            .map((item) => (
              <option key={item.HOME_STATE} value={item.HOME_STATE}>
                {item.HOME_STATE}
              </option>
            ))}
        </select>
      </div>
      <div className="flex flex-1 basis-2 flex-col gap-1">
        <label className="text-xs whitespace-nowrap text-gray-800">
          Outreach
        </label>
        <select
          onChange={handleChange}
          name="outreach"
          value={pagination.outreach}
          className={tabStyles.select}
        >
          <option value="">Select</option>
          {filterData?.outreach
            .filter((item) => item.OUTREACH)
            .map((item) => (
              <option key={item.OUTREACH} value={item.OUTREACH}>
                {item.OUTREACH}
              </option>
            ))}
        </select>
      </div>
      <div className="flex flex-1 basis-2 flex-col gap-1">
        <label className="text-xs whitespace-nowrap text-gray-800">
          Recuriting Category
        </label>
        <select
          onChange={handleChange}
          name="recruitingCategory"
          value={pagination.recruitingCategory}
          className={tabStyles.select}
        >
          <option value="">Select</option>
          {filterData?.recruiting_category
            .filter((item) => item.RECRUITING_CATEGORY)
            .map((item) => (
              <option
                key={item.RECRUITING_CATEGORY}
                value={item.RECRUITING_CATEGORY}
              >
                {item.RECRUITING_CATEGORY}
              </option>
            ))}
        </select>
      </div>

      <div className="flex flex-1 flex-col gap-1">
        <label className="text-xs whitespace-nowrap text-gray-800">
          Source
        </label>
        <select
          onChange={handleChange}
          name="source"
          value={pagination.source}
          className={tabStyles.select}
        >
          <option value="">Select</option>
          {filterData?.source
            .filter((item) => item.SOURCE)
            .map((item) => (
              <option key={item.SOURCE} value={item.SOURCE}>
                {item.SOURCE}
              </option>
            ))}
        </select>
      </div>
      <div className="flex flex-1 flex-col gap-1">
        <label className="text-xs whitespace-nowrap text-gray-800">
          Source Category
        </label>
        <select
          onChange={handleChange}
          name="sourceCategory"
          value={pagination.sourceCategory}
          className={tabStyles.select}
        >
          <option value="">Select</option>
          {filterData?.source_category
            .filter((item) => item.SOURCE_CATEGORY)
            .map((item) => (
              <option key={item.SOURCE_CATEGORY} value={item.SOURCE_CATEGORY}>
                {item.SOURCE_CATEGORY}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
};

const HeaderFilter = ({ clearFilter, pagination, setPagination }) => {
  const filters = { ...pagination };
  delete filters.pageNumber;
  delete filters.pageSize;
  const hasFilters = Object.values(filters).some((item) => item);
  return (
    <div className="flex flex-1 items-center gap-4">
     
        <input
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          placeholder="Full Name"
          type="text"
          value={pagination.fullName}
          onChange={(e) =>
            setPagination({ ...pagination, fullName: e.target.value })
          }
        />
      
        <input
        placeholder="Home City"
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"          type="text"
          onChange={(e) =>
            setPagination({ ...pagination, homeCity: e.target.value })
          }
          name="homeCity"
          value={pagination.homeCity}
        />
     
        <input
        placeholder="Recruiting Location"
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"          type="text"
          onChange={(e) =>
            setPagination({ ...pagination, recruitingLocation: e.target.value })
          }
          name="recruitingLocation"
          value={pagination.recruitingLocation}
        />
         {hasFilters && (
        <button
          onClick={() => {
            clearFilter()
          }}
          className="bg-black whitespace-nowrap rounded-lg text-white px-3 py-2 text-sm "
        >
          Clear Filters
        </button>
      )}
    </div>
  );
};

export const tabStyles = {
  default:
    "px-5 py-3 flex-1 hover:text-gray-700 hover:drop-shadow-md transition ease-in-out hover:bg-gray-50 hover:rounded-md",
  active: "font-medium text-gray-700 drop-shadow-md bg-gray-50 rounded-md",
  select:
    "focus:border-gray-300 w-full focus:ring-0 focus:outline-none rounded-md border border-gray-200 text-gray-700 text-xs",
};
