import React, { FC } from "react";
import { ITable } from "./Table";
import classNames from "classnames";

export const TableHeader: FC<ITable> = ({
  columns,
  rowSelection,
  data,
  setData,
}) => {
  const [key, setKey] = React.useState("");
  const [type, setType] = React.useState("asc");
  const handleCheck = () => {
    if (data?.length > 0)
      return data?.every((current: { select: boolean }) => current.select);
    else return false;
  };

  const handleAllCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const newData = [...data];
    newData.forEach((current) => (current.select = checked));
    // setData(newData)
  };

  //   const renderSelectTags = (columnData, column) => {
  //     console.log({columnData, column})
  //     if(!column?.render) {

  //         return columnData.map((item, index) => (
  //           <select key={index}>
  //             <option value={item}>{item}</option>
  //           </select>
  //         ));
  //     }
  //   };

  const onSort = (column) => {
    const compareFunction = (a, b) => a[column]?.toLowerCase().localeCompare(b[column]?.toLowerCase());
    if (type === "asc") {
      data.sort(compareFunction);
    } else if (type === "desc") {
      data.sort((a, b) => compareFunction(b, a)); // Reverse order for descending
    } else {
      console.error("Invalid sortOrder. Use 'asc' or 'desc'.");
    }

    setData(data);
  }

  return (
    <thead className="bg-gray-100 sticky top-0 border z-20 h-full">
      <tr>
        {rowSelection && (
          <th className="text-left pl-3 w-14">
            <input
              type="checkbox"
              className="checked:border-transparent text-sm w-4 h-4"
              onChange={handleAllCheck}
              checked={handleCheck()}
            />
          </th>
        )}
        <th className="p-4 text-left text-xs font-medium text-gray-800 uppercase tracking-wider">
          #
        </th>
        {columns?.map(
          (col, i) =>
            (col?.show === undefined || col?.show) && (
              <th
                key={i}
                scope="col"
                className={`p-4 text-left
                        ${
                          col?.key === "key" ? "flex" : ""
                        }  text-xs font-medium text-gray-700 whitespace-nowrap uppercase  ${
                  col?.key === "FULL_NAME"
                    ? "bg-[#BDBDBD]"
                    : col?.key === "UID"
                    ? "bg-[#4DD0E1]"
                    : col?.color
                    ? col?.color
                    : "bg-gray-100"
                } ${col?.freezeIndex} ${col.freeze && "sticky"}`}
              >
                <span
                  className={classNames("flex items-center gap-1 font-bold", {
                    "text-white": col.hasOwnProperty("color"),
                  })}
                >
                  {col.title}
                  {col.hasOwnProperty("header") ? col.header(col) : null}
                  {col.isSort && (
                    <button
                      onClick={() => {
                        setType((prev) => (prev === "asc" ? "desc" : "asc"));
                        onSort(col.key);
                        setKey(col.key);
                      }}
                    >
                      {key === col.key && type === "desc" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m19.5 8.25-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m4.5 15.75 7.5-7.5 7.5 7.5"
                          />
                        </svg>
                      )}
                    </button>
                  )}
                </span>
              </th>
            )
        )}
      </tr>
    </thead>
  );
};
