import { AreaChart } from "components/charts/AreaChart";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import DatePicker from "react-datepicker";
import UserService from "services/user.service";
import { formatDateY } from "utils/funcs";

const service = new UserService();

export const DATE = [
  "YESTERDAY",
  "TODAY",
  "WEEKTODATE",
  "LASTWEEK",
  "LASTMONTH",
  "MONTHTODATE",
  "YEARTODATE",
  "CUSTOM",
];

export const WeeklySttats = () => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("YEARTODATE");
  const [showCustom, setShowCustom] = useState(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [type, setType] = useState('All')
  const [stats, setStats] = useState({
    location_wise_training_count: [],
    training_created_in_date_range: [],
    attendess_count_with_in_training: [],
    attendess_count_date_wise: [],
    rsvp_count_date_wise: [],
  });

  useEffect(() => {
    setLoading(true);
    service
      .getWeeklyStats({ type, date })
      .then((resp) => {
        setStats(resp.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [date, type]);

  const handleChangeDates = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    if ((!start && !end) || end) {
      setDate(`${formatDateY(start)},${formatDateY(end)}`);
    }
  };

  return (
    <div>
      <div className="mb-4 flex items-center gap-4">
      <select
        value={date}
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-60 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        onChange={(e) => {
          if (e.target.value !== "CUSTOM") {
            setDate(e.target.value);
          } else {
            setShowCustom(true);
          }
        }}
      >
        <option>Select</option>
        {DATE.map((item) => (
          <option value={item} key={item}>
            {item}
          </option>
        ))}
      </select>

      <select
        placeholder="Type"
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-60 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        value={type}
        onChange={e => setType(e.target.value)}
      >
        <option value="">All</option>
        <option value="Weekly Training">Weekly Training</option>
        <option value="Group Interview">Group Interview</option>
      </select>

      {showCustom && (
        <DatePicker
          selected={startDate}
          placeholderText="Select range"
          onChange={handleChangeDates}
          startDate={startDate}
          endDate={endDate}
          isClearable
          className="z-100 ml-2 py-2 w-60"
          customInput={
            <input
              placeholder="Select date"
              className=" rounded-md border w-60 border-gray-outline outline-none focus-visible:shadow-none placeholder:text-sm focus:ring-0 focus:border-gray-200 py-2 text-sm text-zinc-600 pl-4 pr-4 disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;"
            />
          }
          selectsRange
          selectsDisabledDaysInRange
        />
      )}
      </div>
      {loading ? (
        <div className="flex flex-col h-full mt-8 items-center justify-center mx-auto w-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler mx-auto text-primary icon-tabler-loader-3 animate-spin"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 12a9 9 0 0 0 9 9a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9" />
            <path d="M17 12a5 5 0 1 0 -5 5" />
          </svg>
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-4">
           <div className="border border-gray-200 bg-white p-3 rounded-md">
            <ReactApexChart
              series={[
                {
                  name: "RSVP",
                  data: stats.attendess_count_with_in_training.map(
                    (item: { rsvp_count: number }) => item.rsvp_count
                  ),
                },
                {
                  name: "Attendees",
                  data: stats.attendess_count_with_in_training.map(
                    (item: { attendees: number }) => item.attendees
                  ),
                },
              ]}
              options={{
                title: {
                  text: "Training's Attendees & RSVP",
                  align: "left",
              
                },
                chart: {
                  height: 350,
                  type: "area",
                },
                dataLabels: {
                  enabled: true,
                },
                stroke: {
                  curve: "smooth",
                },
                xaxis: {
                  categories: stats.attendess_count_with_in_training.filter((item: { location: string }) => item.location !== "").map(
                    (item: { location: string }) => item.location
                  )
                }
              }}
            />
          </div>
          <AreaChart
            name="Group Interview Date"
            labelName="Training Count"
            series={stats.training_created_in_date_range.map(
              (item: { date: string }) => item.date
            )}
            data={stats.training_created_in_date_range.map(
              (item: { total: number }) => item.total
            )}
          />
          <AreaChart
            name="Training's Location"
            series={stats.location_wise_training_count.filter((item: { location: string }) =>  item.location !== "").map(
              (item: { location: string }) => item.location
            )}
            data={stats.location_wise_training_count.map(
              (item: { total: number }) => item.total
            )}
          />
         
         
          {/* <AreaChart
            name="Attendees Date Wise"
            series={stats.attendess_count_date_wise.map(
              (item: { date: string }) => item.date
            )}
            data={stats.attendess_count_date_wise.map(
              (item: { total: number }) => item.total
            )}
          />
          <AreaChart
            name="RSVP Date Wise"
            series={stats.rsvp_count_date_wise.map(
              (item: { date: string }) => item.date
            )}
            data={stats.rsvp_count_date_wise.map(
              (item: { total: number }) => item.total
            )}
          /> */}
        </div>
      )}
    </div>
  );
};
