import classNames from "classnames";
import { Table } from "common/Table/Table";
import { useRouter } from "hooks/useRouter";
import { useEffect, useState } from "react";
import UserService from "services/user.service";
import { tabStyles } from "./GeneralData";
import { formatDate } from "utils/funcs";
import { formatPhone } from "./Contacts";

const service = new UserService();

export const BlackSparrowUsers = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const [tab, setTab] = useState("attende_hire");

  const { push, query } = useRouter();

  const columns = [
    { key: "first_name", dbColName: "first_name", title: "first name" },
    { key: "last_name", dbColName: "last_name", title: "last name" },
    {
      key: "phone",
      dbColName: "phone",
      title: "phone",
      render: (phone) => formatPhone(phone),
    },
    {
      key: "date_submission",
      dbColName: "date_submission",
      title: "date submission",
      render: (date) => formatDate(date),
    },
    {
      key: "rep_tenure",
      dbColName: "rep_tenure",
      title: "rep tenure",
    },
    {
      key: "createdAt",
      dbColName: "createdAt",
      title: "createdAt",
      render: (date) => formatDate(date),
    },
  ]

  const repColumns = [
     {key: "rep_id", dbColName: "rep_id", title: "rep id"}, 
     {key: "rep_name", dbColName: "rep_name", title: "rep name"}, 
     {key: "tenure", dbColName: "tenure", title: "tenure"}, 
     {key: "max_position", dbColName: "max_position", title: "max position"}, 
     {key: "email", dbColName: "email", title: "email"}, 
     {key: "phone", dbColName: "phone", title: "phone"}, 
     {key: "agreement_sent_date", dbColName: tab  === "onboarded_date" ? "onboarded_date" :  "agreement_sent_date", title: tab  === "onboarded_date" ? "onboarded date" :  "agreement sent date", render: (date) => formatDate(date),}, 
  ]

  useEffect(() => {
    setLoading(true);
    service
      .getSparrowUsers(query)
      .then((resp) => {
        setData(resp.data);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  }, [query]);

  return (
    <div>
      <div className="flex gap-4 items-start">
        <button
          type="button"
          onClick={() => push("/black-sparrow?tab=Reports")}
          className="bg-white rounded-md p-2 border"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
            />
          </svg>
        </button>
        <div className="flex flex-col mb-4">
          <h1 className="font-medium text-gray-700 tracking-wide text-xl">
            Black Sparrow Users
          </h1>
          <p className="text-gray-500 text-sm">View all users</p>
        </div>
      </div>

      <div className="text-sm mb-4 p-2 text-center text-gray-500 rounded-md bg-gray-200">
        <div className="flex flex-wrap -mb-px gap-3">
          <button
            type="button"
            onClick={() => setTab("attende_hire")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "attende_hire",
            })}
          >
            Attendee Hire
          </button>
          <button
            type="button"
            onClick={() => setTab("attende_offer")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "attende_offer",
            })}
          >
            Attendee Offer
          </button>
          <button
            type="button"
            onClick={() => setTab("goalsSet")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "goalsSet",
            })}
          >
            Goals Set
          </button>
          <button
            type="button"
            onClick={() => setTab("group_infosession")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "group_infosession",
            })}
          >
            Group Infosession Scheduled
          </button>
          <button
            type="button"
            onClick={() => setTab("group_infosession_raffle")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "group_infosession_raffle",
            })}
          >
            Group Infosession Attended
          </button>
          <button
            type="button"
            onClick={() => setTab("register_for_blitz")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "register_for_blitz",
            })}
          >
            Register for Blitz
          </button>
          <button
            type="button"
            onClick={() => setTab("contract_sent")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "contract_sent",
            })}
          >
            Contract Sent
          </button>
          <button
            type="button"
            onClick={() => setTab("contract_signed")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "contract_signed",
            })}
          >
            Agreement Signed
          </button>
          <button
            type="button"
            onClick={() => setTab("last_credit_run_date")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "last_credit_run_date",
            })}
          >
            Ran
          </button>
          <button
            type="button"
            onClick={() => setTab("onboarded_date")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "onboarded_date",
            })}
          >
            Onboard
          </button>
          <button
            type="button"
            onClick={() => setTab("last_street_genie_login")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "last_street_genie_login",
            })}
          >
            Street Genie Login
          </button>
          <button
            type="button"
            onClick={() => setTab("completed_training")}
            className={classNames(tabStyles.default, "text-xs", {
              [tabStyles.active]: tab === "completed_training",
            })}
          >
            Completed Training
          </button>
        </div>
      </div>
      <Table
        loadingData={loading}
        columns={tab ===  "completed_training"  || tab ===  "contract_sent" || tab ===  "contract_signed" || tab ===  "onboarded_date"    ? repColumns :  columns}
        data={Object.keys(data).length > 0 ? data[tab] : []}
        totalItems={0}
      />
    </div>
  );
};
