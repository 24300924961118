import { configureStore, Action } from "@reduxjs/toolkit"
import storage from "redux-persist/lib/storage"
import thunk, { ThunkAction as TAction } from "redux-thunk"
import { persistCombineReducers, persistStore } from "redux-persist"
import { userReducer, filterReducer } from "./reducers"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"

const reducers = {
    user: userReducer,
    filters: filterReducer
}

const persistConfig = {
    key: "root",
    storage: storage,
    version: 1,
    stateReconciler: autoMergeLevel2,
    whitelist: ["user"],
}

const persistedReducer = persistCombineReducers(persistConfig, reducers)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = TAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>

export const persistor = persistStore(store)
