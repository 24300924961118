import { FC, Fragment, useState } from "react";
import { TableHeader } from "./TableHeader";

import classNames from "classnames";

export interface IColumn {
  title: string;
  render?: any;
  key?: string;
  dbColName?: string;
  show?: boolean;
  freezeIndex?: any;
  color?: any;
  freeze?: boolean;
  select?: boolean;
}

export interface ITable {
  rowSelection?: boolean;
  data: any;
  totalItems: number;
  exporting?: boolean;
  setPagination?: any;
  isSort?: boolean;
  setLoading?: any;
  columnFilters?: any;
  onSort?: (type: string) => void;
  pagination?: any;
  filters?: any;
  exportData?: any;
  columns: any;
  exportLabel?: string
  showSearch?: boolean;
  setData?: any;
  exportButton?: boolean;
  showPagination?: boolean;
  handleExport?: any;
  loadingData?: boolean;
}

export interface ITableBody extends ITable {
  showingData: any;
  itemsPerPage: number;
  pagination?: any;
  setPagination?: any;
  currentPage: number;
}

function generateNumberArray(N: number) {
  const numberArray: number[] = [];
  for (let i = 10; i <= N; i += 10) {
    numberArray.push(i);
  }
  return numberArray;
}

export const Table: FC<ITable> = ({
  columns,
  loadingData,
  pagination,
  setPagination,
  data,
  totalItems,
  showPagination = true,
  onSort,
  exportLabel = "Export CSV",
  columnFilters,
  showSearch = true,
  filters,
  rowSelection,
  handleExport,
  setData,
  exportButton = true,
  exporting,
}) => {
  const [perPage, setPerPage] = useState(
    +pagination?.pageSize ? pagination?.pageSize : 100
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");

  // const last = currentPage * perPage;
  // const first = last - perPage;
  // const current = data?.slice(first, last);

  const filteredData = data?.filter(
    (item: { [x: string]: { toString: () => string } }) => {
      return Object?.keys(item).some((key) =>
        item[key]?.toString()?.toLowerCase()?.includes(searchText.toLowerCase())
      );
    }
  );

  const pageNumber: number[] = [];
  for (let i = 1; i <= Math.ceil(totalItems / perPage); i++) {
    pageNumber.push(i);
  }
  const renderTableColumnData = (col: IColumn, data: any, index: number) => {
    let column =
      col.dbColName !== undefined && data[col.dbColName as keyof typeof data];

    if (col.hasOwnProperty("render") && !col.hasOwnProperty("dbColName")) {
      return (
        <td
          key={col.key}
          className={classNames(
            `px-4 py-5 text-sm whitespace-nowrap  text-gray-900  font-light ${
              col?.freeze ? "" : "bg-opacity-20"
            }`,
            {
              sticky: col.freeze,
              [col.freezeIndex]: col.freeze,
              "bg-white": index % 2 === 0,
              "bg-gray-50": index % 2 !== 0,
              // "": index % 2 === 0
            }
          )}
        >
          {col.render(data)}
        </td>
      );
    } else if (
      col.hasOwnProperty("render") &&
      col.hasOwnProperty("dbColName")
    ) {
      return (
        <td
          key={col.key}
          onClick={() => col.render(data)}
          className={classNames(
            `px-4 py-5 text-sm  whitespace-nowrap text-gray-700 font-light  ${
              col?.freeze ? "" : "bg-opacity-20"
            }`,
            {
              "bg-white": index % 2 === 0,
              "bg-gray-50": index % 2 !== 0,
              sticky: col.freeze,
              [col.freezeIndex]: col.freeze,
            }
          )}
        >
          {col.render(column, data?.ID as string)}
        </td>
      );
    } else {
      return (
        <td
          key={col.key}
          className={classNames(
            `whitespace-nowrap px-4 py-5 text-sm text-gray-700  font-light ${
              col?.freeze ? "" : "bg-opacity-20"
            }`,
            {
              sticky: col.freeze,
              [col.freezeIndex]: col.freeze,
              "bg-white": index % 2 === 0,
              "bg-gray-50": index % 2 !== 0,
            }
          )}
        >
          {column}
        </td>
      );
    }
  };

  const startIndex = (currentPage - 1) * perPage;

  return (
    <Fragment>
      <div className="relative bg-white p-4 rounded-t-md">
        {loadingData && (
          <p className="text-center pb-3">
            Please wait while data is loading...
          </p>
        )}
        <div className="pt-4 flex gap-2 items-center">
          <div className="flex items-center gap-2">
            <label className="text-sm text-gray-800">Per Page: </label>
            <select
              onChange={(e) => {
                setPagination &&
                  setPagination({
                    ...pagination,
                    pageSize: e.target.value,
                  });
                setPerPage(+e.target.value);
              }}
              value={perPage}
              className="rounded-md border-gray-200 focus:border-gray-300 focus:ring-0 focus:outline-none text-sm"
            >
              {generateNumberArray(1000).map((item) => (
                <option key={item} defaultValue={perPage} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          {showSearch && (
            <div className="relative flex-1">
              <input
                type="search"
                placeholder="Search..."
                onChange={(e) => setSearchText(e.target.value)}
                className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 absolute text-gray-500 right-2 top-2.5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </div>
          )}

          {filters}
          {/* <CSVLink
            className="hidden"
            data={exportData}
            asyncOnClick={true}
            headers={columns}
          /> */}
          {exportButton && (
            <button
              onClick={handleExport}
              disabled={exporting}
              className="bg-black disabled:bg-opacity-40 text-white flex items-center gap-2 p-2.5 rounded-md"
            >
              <span className="text-xs">
                {exporting ? "Exporting..." : exportLabel}
              </span>
              {!exporting ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-loader-3 animate-spin"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 12a9 9 0 0 0 9 9a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9" />
                  <path d="M17 12a5 5 0 1 0 -5 5" />
                </svg>
              )}
            </button>
          )}
        </div>
        {columnFilters}
      </div>
      <div className="bg-white drop-shadow-md overflow-x-auto max-h-screen">
        <table className="overflow-auto border min-w-full border-gray-200">
          <TableHeader
            rowSelection={rowSelection}
            columns={columns}
            setData={setData}
            totalItems={totalItems}
            onSort={onSort}
            data={filteredData}
          />
          <tbody
            className={classNames("bg-white", {
              "h-60": !data.length,
            })}
          >
            <tr>
              {(filteredData?.length === 0 || !filteredData) &&
                !loadingData && (
                  <td colSpan={13} className="text-center p-5">
                    <svg
                      className="w-16 h-16 mx-auto text-gray-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.2}
                        d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                      />
                    </svg>
                    <p className="text-gray-900 font-medium mt-1">
                      No data found
                    </p>
                  </td>
                )}
              {loadingData && (
                <td className="flex z-10 bg-white left bg-opacity-70 flex-col items-center justify-center absolute w-full h-full pb-16">
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      className="w-full h-9 mr-2 text-center text-gray-200 animate-spin-slow dark:text-gray-600 fill-primary"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                  <p className="ml-3 mt-1 font-medium text-gray-700">
                    Loading...
                  </p>
                </td>
              )}
            </tr>
            {filteredData?.length !== 0 &&
              filteredData?.map((d: any, index: number) => (
                <tr
                  key={index}
                  className={classNames({
                    "bg-gray-50": index % 2 !== 0,
                  })}
                >
                  <td className="p-5 text-gray-700 text-sm font-light">
                    {pagination && pagination?.page > 1
                      ? pagination?.limit * pagination?.page + index + 1 - 10
                      : startIndex + index + 1}
                  </td>
                  {columns.map((col) => {
                    if (col?.show === undefined || col?.show) {
                      return renderTableColumnData(col, d, index);
                    }
                    return null;
                  })}
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {showPagination && (
        <div className="bg-white drop-shadow-md rounded-b-md">
          <div className="flex p-3 items-center justify-between">
            <div className="flex-1 flex justify-between sm:hidden">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                className="disabled:opacity-50 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
                disabled={currentPage === pageNumber[0]}
              >
                Previous
              </button>
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === pageNumber.length}
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between text-right">
              <div className="flex items-center gap-2">
                <p className="text-sm text-gray-700">
                  Page{" "}
                  <span className="font-medium">
                    {pagination ? pagination.pageNumber : currentPage} of{" "}
                    {pageNumber.length}
                  </span>{" "}
                </p>
                <select
                  onChange={(eve) => {
                    setCurrentPage(+eve.target.value);
                    setPagination &&
                      setPagination({
                        ...pagination,
                        pageNumber: +eve.target.value,
                        pageSize: pagination?.pageSize,
                      });
                  }}
                  className="p-1.5 rounded-md border border-gray-200 focus:border-gray-200 focus:ring-0 focus:outline-none text-xs text-gray-700"
                >
                  <option disabled>Go to page</option>
                  {pageNumber.map((number) => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  ))}
                </select>
              </div>
                  {loadingData && <p className="text-zinc-800">Loading...</p>}
              <nav
                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-1"
                aria-label="Pagination"
              >
                <button
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                    setPagination &&
                      setPagination({
                        ...pagination,
                        pageNumber: currentPage - 1,
                        pageSize: pagination?.pageSize,
                      });
                  }}
                  className={`disabled:cursor-not-allowed disabled:bg-gray-100 relative focus:outline-none dis inline-flex items-center justify-center py-2 w-24 text-center rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
                  disabled={currentPage === pageNumber[0]}
                >
                  <span>Previous</span>
                </button>
                <button
                  onClick={() => {
                    setCurrentPage(currentPage + 1);
                    setPagination &&
                      setPagination({
                        ...pagination,
                        pageNumber: pagination.pageNumber + 1,
                        pageSize: pagination?.pageSize,
                      });
                  }}
                  className={`disabled:cursor-not-allowed disabled:bg-gray-100 relative focus:outline-none inline-flex items-center justify-center py-2 w-24 text-center rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
                  disabled={
                    currentPage === pageNumber.length ||
                    !data.length ||
                    !filteredData.length
                  }
                >
                  <span>Next</span>
                </button>
              </nav>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};
