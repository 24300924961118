import { createSlice } from "@reduxjs/toolkit"

const initialState = {
        pageNumber: 1,
        pageSize: 100,
        type: "",
        leaderName: "",
        location: "",
        startDate: "",
        hierarcyFilter: "",
        endDate: "",
}

const filterReducer = createSlice({
    name: "filters",
    initialState,
    reducers: {
        addFilters: (state, action) =>  {
            state = { ...state }
        },
      
    },
})

export const {
    addFilters
} = filterReducer.actions

export default filterReducer.reducer
