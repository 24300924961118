import { Table } from "common/Table/Table";
import { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import UserService from "services/user.service";
import { exportToCSV, formatDate, formatDateY } from "utils/funcs";
import "react-datepicker/dist/react-datepicker.css";
import { useDebounce } from "hooks/useDebounce";

const service = new UserService();

export const Licenses = () => {
  const columns = [
    {
      key: "FullName",
      dbColName: "FullName",
      title: "Full Name",
      freeze: true,
      freezeIndex: "left-0",
    },
    {
      key: "LicenseStatus",
      dbColName: "LicenseStatus",
      title: "License Status",
      freeze: true,
      freezeIndex: "left-40",
    },
    { key: "CreatedBy", dbColName: "CreatedBy", title: "Created By" },
    { key: "license_type", dbColName: "license_type", title: "License Type" },

    { key: "Division", dbColName: "Division", title: "Division" },
    { key: "Partnership", dbColName: "Partnership", title: "Partnership" },
    {
      key: "PartnershipLeadID",
      dbColName: "PartnershipLeadID",
      title: "Partnership Lead ID",
    },
    {
      key: "PARTNERSHIP_LEADER_NAME",
      dbColName: "PARTNERSHIP_LEADER_NAME",
      title: "PARTNERSHIP LEADER NAME",
    },
    { key: "SeniorRegion", dbColName: "SeniorRegion", title: "Senior Region" },
    {
      key: "SRRegionLeadID",
      dbColName: "SRRegionLeadID",
      title: "SR Region Lead ID",
    },
    {
      key: "SENIOR_REGION_LEADER_NAME",
      dbColName: "SENIOR_REGION_LEADER_NAME",
      title: "SENIOR REGION LEADER NAME",
    },
    { key: "Region", dbColName: "Region", title: "Region" },
    { key: "RegionLeadID", dbColName: "RegionLeadID", title: "Region Lead ID" },
    {
      key: "REGION_LEADER_NAME",
      dbColName: "REGION_LEADER_NAME",
      title: "REGION LEADER NAME",
    },
    {
      key: "SeniorManagementGroup",
      dbColName: "SeniorManagementGroup",
      title: "Senior Management Group",
    },
    { key: "SRMGMTLeadID", dbColName: "SRMGMTLeadID", title: "SRMGMT Lead ID" },
    {
      key: "SENIOR_MANAGEMENT_GROUP_LEADER_NAME",
      dbColName: "SENIOR_MANAGEMENT_GROUP_LEADER_NAME",
      title: "SENIOR MANAGEMENT GROUP LEADER NAME",
    },
    {
      key: "ManagementGroup",
      dbColName: "ManagementGroup",
      title: "Management Group",
    },
    { key: "MGMTLeadID", dbColName: "MGMTLeadID", title: "MGMT Lead ID" },
    {
      key: "MANAGEMENT_GROUP_LEADER_NAME",
      dbColName: "MANAGEMENT_GROUP_LEADER_NAME",
      title: "MANAGEMENT GROUP LEADER NAME",
    },
    { key: "Team", dbColName: "Team", title: "Team" },
    { key: "TeamLeadID", dbColName: "TeamLeadID", title: "Team Lead ID" },
    {
      key: "TEAM_LEADER_NAME",
      dbColName: "TEAM_LEADER_NAME",
      title: "TEAM LEADER NAME",
    },
    { key: "SeasonID", dbColName: "SeasonID", title: "Season ID" },
    { key: "EmployeeID", dbColName: "EmployeeID", title: "Employee ID" },
    { key: "Office", dbColName: "Office", title: "Office" },
    {
      key: "OFFICE_ASSIGNMENT_CREATED",
      dbColName: "OFFICE_ASSIGNMENT_CREATED",
      title: "OFFICE ASSIGNMENT CREATED",
      render: (date) => formatDate(date),
    },
    {
      key: "OfficeSalesManagerID",
      dbColName: "OfficeSalesManagerID",
      title: "Office Sales Manager ID",
    },
    {
      key: "OfficeHeadTechID",
      dbColName: "OfficeHeadTechID",
      title: "Office Head Tech ID",
    },
    {
      key: "StateAbbreviation",
      dbColName: "StateAbbreviation",
      title: "State Abbreviation",
    },
    { key: "Sales Entit", dbColName: "Sales Entit", title: "Sales Entit" },
    { key: "Email", dbColName: "Email", title: "Email" },
    { key: "PhoneNumber", dbColName: "PhoneNumber", title: "Phone Number" },
    {
      key: "PoliticalUnitName",
      dbColName: "PoliticalUnitName",
      title: "PoliticalUnitName",
    },
    {
      key: "RequirementName",
      dbColName: "RequirementName",
      title: "Requirement Name",
    },
    {
      key: "EmployeeRequirement",
      dbColName: "EmployeeRequirement",
      title: "Employee Requirement",
    },
    { key: "LicenseName", dbColName: "LicenseName", title: "License Name" },
    {
      key: "LicenseNumber",
      dbColName: "LicenseNumber",
      title: "License Number",
    },

    {
      key: "StartDate",
      dbColName: "StartDate",
      title: "StartDate",
      render: (date) => formatDate(date),
    },
    {
      key: "SentToState",
      dbColName: "SentToState",
      title: "Sent To State",
      render: (date) => formatDate(date),
    },
    {
      key: "ExpirationDate",
      dbColName: "ExpirationDate",
      title: "Expiration Date",
      render: (date) => formatDate(date),
    },
    {
      key: "PublicAppObservations",
      dbColName: "PublicAppObservations",
      title: "Public App Observations",
    },
    {
      key: "LastUpdatedBy",
      dbColName: "LastUpdatedBy",
      title: "Last Updated By",
    },
    {
      key: "HasAttachment",
      dbColName: "HasAttachment",
      title: "Has Attachment",
    },
    { key: "Country", dbColName: "Country", title: "Country" },
    { key: "Id", dbColName: "Id", title: "Id" },
    {
      key: "ProcessingTime",
      dbColName: "ProcessingTime",
      title: "Processing Time",
    },
    { key: "GracePeriod", dbColName: "GracePeriod", title: "Grace Period" },
    {
      key: "EligibleToWork",
      dbColName: "EligibleToWork",
      title: "Eligible To Work",
    },
    {
      key: "LicenseActiveDate",
      dbColName: "LicenseActiveDate",
      title: "License Active Date",
    },
    {
      key: "LastDayToSubmit",
      dbColName: "LastDayToSubmit",
      title: "Last Day To Submit",
      render: (date) => formatDate(date),
    },
    {
      key: "regional_coordinator",
      dbColName: "regional_coordinator",
      title: "regional coordinator",
    },
    {
      key: "SALES_MANAGER_NAME",
      dbColName: "SALES_MANAGER_NAME",
      title: "SALES MANAGER NAME",
    },
    {
      key: "OPERATIONS_MANAGER_NAME",
      dbColName: "OPERATIONS_MANAGER_NAME",
      title: "OPERATIONS MANAGER NAME",
    },
    {
      key: "SUMMER_READINESS_DATE",
      dbColName: "SUMMER_READINESS_DATE",
      title: "SUMMER READINESS DATE",
      render: (date) => formatDate(date),
    },
    {
      key: "SEASON_START_DATE__C",
      dbColName: "SEASON_START_DATE__C",
      title: "SEASON START DATE C",
      render: (date) => formatDate(date),
    },
    { key: "HAS_CONFIRM", dbColName: "HAS_CONFIRM", title: "HAS CONFIRM" },
    {
      key: "CONDENSED_SUMMER_STATUS",
      dbColName: "CONDENSED_SUMMER_STATUS",
      title: "CONDENSED SUMMER STATUS",
    },
    {
      key: "SUMMERSTATUS",
      dbColName: "SUMMER_STATUS",
      title: "SUMMER STATUS",
    },
    {
      key: "EMPLOYEE_USER_NAME",
      dbColName: "EMPLOYEE_USER_NAME",
      title: "EMPLOYEE USER NAME",
    },
    {
      key: "OFFICE_SALES_MANAGER_USER_NAME",
      dbColName: "OFFICE_SALES_MANAGER_USER_NAME",
      title: "OFFICE SALES MANAGER USER NAME",
    },
    {
      key: "OFFICE_HEAD_TECH_USER_NAME",
      dbColName: "OFFICE_HEAD_TECH_USER_NAME",
      title: "OFFICE HEAD TECH USER NAME",
    },
    {
      key: "TEAM_LEADER_USER_NAME",
      dbColName: "TEAM_LEADER_USER_NAME",
      title: "TEAM LEADER USER NAME",
    },
    {
      key: "MANAGEMENT_GROUP_LEADER_USER_NAME",
      dbColName: "MANAGEMENT_GROUP_LEADER_USER_NAME",
      title: "MANAGEMENT GROUP LEADER USER NAME",
    },
    {
      key: "SENIOR_MANAGEMENT_GROUP_LEADER_USER_NAME",
      dbColName: "SENIOR_MANAGEMENT_GROUP_LEADER_USER_NAME",
      title: "SENIOR MANAGEMENT GROUP LEADER USER NAME",
    },
    {
      key: "REGION_LEADER_USER_NAME",
      dbColName: "REGION_LEADER_USER_NAME",
      title: "REGION LEADER USER NAME",
    },
    {
      key: "SENIOR_REGION_LEADER_USER_NAME",
      dbColName: "SENIOR_REGION_LEADER_USER_NAME",
      title: "SENIOR REGION LEADER USER NAME",
    },
    {
      key: "PARTNERSHIP_LEADER_USER_NAME",
      dbColName: "PARTNERSHIP_LEADER_USER_NAME",
      title: "PARTNERSHIP LEADER USER NAME",
    },
  ]

  const [data, setData] = useState([]);
  const [exporting, setExporting] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 100,
    licenseStatus: "",
    officeAssignmentCreatedDate: "",
    email: "",
    phone: "",
    teamLeaderName: "",
    repName: "",
    state: "",
    createdBy: "",
    startDate: "",
    licenseExpiryStartDate: "",
    licenseExpiryEndDate: "",
    officeAssignmentCreatedStartDate: "",
    officeAssignmentCreatedEndDate: "",
    sentToStateStartDate: "",
    sentToStateEndDate: "",
  })

  const debounced = useDebounce(pagination, 1000)

  useEffect(() => {
    setLoading(true);
    service
      .getLicenses(debounced)
      .then((resp) => {
        setData(resp.data.data)
        setTotalItems(resp.data.totalDocument)
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [debounced])

  const handleExport = () => {
    setExporting(true);
    exportToCSV(data, "data");
    setExporting(false);
  }

  const clearFilter = () => {
    setPagination({
      pageNumber: 1,
      pageSize: 100,
      licenseStatus: "",
      officeAssignmentCreatedDate: "",
      email: "",
      phone: "",
      teamLeaderName: "",
      repName: "",
      state: "",
      createdBy: "",
      startDate: "",
      licenseExpiryStartDate: "",
      licenseExpiryEndDate: "",
      officeAssignmentCreatedStartDate: "",
      officeAssignmentCreatedEndDate: "",
      sentToStateStartDate: "",
      sentToStateEndDate: "",
    })
  }

  return (
    <Fragment>
      <div className="flex flex-col mb-4 ">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
          Licenses
        </h1>
        <p className="text-gray-500 text-sm">View all licenses</p>
      </div>
      <Table
        data={data}
        loadingData={loading}
        setPagination={setPagination}
        exporting={exporting}
        handleExport={handleExport}
        showSearch={false}
        columnFilters={
          <ColumnFilters
            pagination={pagination}
            setPagination={setPagination}
          />
        }
        filters={
          <Filters
            clearFilter={clearFilter}
            pagination={pagination}
            setPagination={setPagination}
          />
        }
        pagination={pagination}
        totalItems={totalItems}
        columns={columns}
      />
    </Fragment>
  );
};

const ColumnFilters = ({ pagination, setPagination }) => {
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const [assignmentStart, setAssignmentStart] = useState<any>(null);
  const [assignmentEnd, setAssignmentEnd] = useState<any>(null);

  const onChangeExpiry = (dates) => {
    const [start, end] = dates

    setStartDate(start)
    setEndDate(end)

    if (end) {
      setPagination({
        ...pagination,
        licenseExpiryStartDate: start ? formatDateY(start) : "",
        licenseExpiryEndDate: end ? formatDateY(end) : "",
      })
    }

    if (!end && !start) {
      setPagination({
        ...pagination,
        licenseExpiryStartDate: "",
        licenseExpiryEndDate: "",
      })
    }
  }

  const onChangeAssignment = (dates) => {
    const [start, end] = dates;

    setAssignmentStart(start);
    setAssignmentEnd(end);

    if (end) {
      setPagination({
        ...pagination,
        officeAssignmentCreatedStartDate: start ? formatDateY(start) : "",
        officeAssignmentCreatedEndDate: end ? formatDateY(end) : "",
      })
    }

    if (!end && !start) {
      setPagination({
        ...pagination,
        officeAssignmentCreatedStartDate: "",
        officeAssignmentCreatedEndDate: "",
      });
    }
  };

  return (
    <div className="flex gap-2 mt-3">
      <div className="flex flex-1 basis-2 flex-col gap-1">
        <label className="text-xs whitespace-nowrap text-gray-800">
          Created By
        </label>
        <input
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="text"
          placeholder="Created By"
          onChange={(e) => {
            setPagination({
              ...pagination,
              createdBy: e.target.value,
            });
          }}
        />
      </div>
      <div className="flex flex-1  flex-col gap-1">
        <label className="text-xs whitespace-nowrap text-gray-800">
          Assignment Date
        </label>
        <DatePicker
          selected={startDate}
          placeholderText="Assignment Date"
          onChange={onChangeAssignment}
          // showIcon
          startDate={assignmentStart}
          endDate={assignmentEnd}
          isClearable
          className="z-100 py-2"
          customInput={
            <input
              placeholder="Select date"
              className=" bg-white  rounded-md border w-full border-gray-outline outline-none  placeholder:text-sm focus:border-gray-300 py-2 text-sm text-zinc-600 pl-4 pr-4 disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;"
            />
          }
          selectsRange
          selectsDisabledDaysInRange
        />
      </div>
      <div className="flex flex-1  flex-col gap-1">
        <label className="text-xs whitespace-nowrap text-gray-800">
          Start Date
        </label>
        <input
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          value={pagination.startDate}
          onChange={(e) => {
            setPagination({
              ...pagination,
              startDate: e.target.value,
            });
          }}
        />
      </div>

      <div className="flex flex-1  flex-col gap-1">
        <label className="text-xs whitespace-nowrap text-gray-800">
          Expiry Date
        </label>
        <DatePicker
          selected={startDate}
          placeholderText="Expiry Date"
          onChange={onChangeExpiry}
          // showIcon
          startDate={startDate}
          endDate={endDate}
          isClearable
          className="z-100 py-2"
          customInput={
            <input
              placeholder="Select date"
              className=" bg-white  rounded-md border w-full border-gray-outline outline-none focus-visible:shadow-none placeholder:text-sm focus:ring-2 ring-brown-10 ring-opacity-50 py-2 text-sm text-zinc-600 pl-4 pr-4 disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;"
            />
          }
          selectsRange
          selectsDisabledDaysInRange
        />
      </div>
      <div className="flex flex-1  flex-col gap-1">
        <label className="text-xs whitespace-nowrap text-gray-800">
          Sent To State
        </label>
        <input
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          value={pagination.sentToStateEndDate}
          placeholder="Expiry Date"
          onChange={(e) => {
            setPagination({
              ...pagination,
              sentToStateEndDate: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};

const Filters = ({ clearFilter, pagination, setPagination }) => {
  const filters = { ...pagination };
  delete filters.pageNumber;
  delete filters.pageSize;
  const hasFilters = Object.values(filters).some((item) => item);

  return (
    <div className="flex flex-1 items-center gap-2">
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        value={pagination.repName}
        placeholder="Rep Name"
        onChange={(e) => {
          setPagination({
            ...pagination,
            repName: e.target.value,
          });
        }}
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        placeholder="Email"
        value={pagination.email}
        onChange={(e) => {
          setPagination({
            ...pagination,
            email: e.target.value,
          });
        }}
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        placeholder="Team Leader"
        value={pagination.teamLeaderName}
        onChange={(e) => {
          setPagination({
            ...pagination,
            teamLeaderName: e.target.value,
          });
        }}
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        placeholder="State"
        value={pagination.state}
        onChange={(e) => {
          setPagination({
            ...pagination,
            state: e.target.value,
          });
        }}
      />
      <select
        value={pagination.licenseStatus}
        onChange={(e) =>
          setPagination({ ...pagination, licenseStatus: e.target.value })
        }
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
      >
        <option value="Status">Status</option>
        <option value="Active">Active</option>
        <option value="Denied">Denied</option>
        <option value="Started">Started</option>
        <option value="None">None</option>
        <option value="Expired">Expired</option>
      </select>
      {hasFilters && (
        <button
          onClick={() => {
            clearFilter();
          }}
          className="bg-black whitespace-nowrap rounded-lg text-white px-3 py-2 text-sm "
        >
          Clear Filters
        </button>
      )}
    </div>
  );
};
