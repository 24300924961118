import BaseService from "./base.service";
import { getItem, removeItem } from "./localStorage.service";

class ProtectedService extends BaseService {
  getAuthHeader = () => {
    const accessToken = getItem("x-auth-token");
    if (accessToken) return accessToken;
    return null;
  };

  makeProtectedRequest = async (fn: any) => {
    try {
      const token = this.getAuthHeader();
      if (!token) throw new Error("No Authorization");
      const authHeaders = { token };
      return await fn(authHeaders);
    } catch (e: any) {
      if (
        e.response?.data?.session_expired === 1 ||
        e.response?.data?.exc_type === "PermissionError"
      ) {
        this.clearAccessToken();
      }
      throw e;
    }
  };

  clearAccessToken = () => {
    removeItem("x-auth-token");
    window.dispatchEvent(new Event("storage"));
  };

  getWithParams = async (url: string, config?: any) =>
    this.makeProtectedRequest(async (authHeaders: any) => 
      super.get(url, {
        headers: {
          ...authHeaders,
          ...config,
        },
      })
    );

    postWithParams = async (url: string, data: any, config?: any) =>
      this.makeProtectedRequest(async (authHeaders: any) => {
        const token = this.getAuthHeader();
        return super.post(url, data, {
          headers: {
            ...authHeaders,
            ...config,
          },
        })
      }
      );
  

  get = async (url: string, config?: any) =>
    this.makeProtectedRequest(async (authHeaders: any) =>
      super.get(url, {
        headers: {
          ...authHeaders,
        },
        ...config,
      })
    );

  post = async (url: string, data?: any, config?: any) =>
    this.makeProtectedRequest(async (authHeaders: any) =>
      super.post(url, data, {
        headers: {
          ...authHeaders,
          
        },
        ...config,
      })
    );

  patch = async (url: string, data?: any, config?: any) =>
    this.makeProtectedRequest(async (authHeaders: any) =>
      super.patch(url, data, {
        headers: {
          ...authHeaders,
        },
        ...config,
      })
    );

  delete = async (url: string, config?: any) =>
    this.makeProtectedRequest(async (authHeaders: any) =>
      super.delete(url, {
        headers: {
          ...authHeaders,
        },
        ...config,
      })
    );
}

export default ProtectedService;
