import ProtectedService from "services/protected.service";
import { objectToQueryString } from "utils/funcs";
import { getItem } from "services/localStorage.service";

import axios from "axios";

const token = getItem("token");

const SNOW_FLAKE_URL = "https://ecards.evolutionsmarthome.com/api/v1";

class UserService extends ProtectedService {
  async getReimbursements({ limit, page }) {
    axios.defaults.headers["x-auth-token"] = token;
    return await this.get(
      `${SNOW_FLAKE_URL}/helper/reimbursements?limit=${limit}&skip=${page - 1}`
    );
  }
  async getRepAndReimbursements() {
    return await this.get(`/rep_and_regional_reimbursement/receipt`);
  }

  async updateRSVP (data) {
    return await this.post(`/dashboard_weekly_training/move_rsvp_to_attendees`, data);
  }
  
  async updateAttendees (data) {
    return await this.post(`/dashboard_weekly_training/update_training_attendees_status`, data);
  }

  async getRepReimbursements({ startDate, endDate }) {
    return await this.get(
      `/rep_and_regional_reimbursement/rep_receipt?startDate=${startDate}&endDate=${endDate}`
    );
  }

  async getWeeklyStats({ type, date }) {
    return await this.get(
      `/dashboard_weekly_training/stats?date=${date}&meetingType=${type}`
    );
  }
  async getBlitzStats(date) {
    return await this.get(
      `/blitz_dash_board/blitz_stats?date=${date}`
    );
  }
  async getBreezyState(date) {
    return await this.get(
      `/breezy_dash_board/breezy_stats?date=${date}`
    );
  }

  async getRegionalReimbursements({ startDate, endDate }) {
    return await this.get(
      `/rep_and_regional_reimbursement/regional_receipt?startDate=${startDate}&endDate=${endDate}`
    );
  }
  async locationRaffles(ids) {
    return await this.get(
      `/dashboard_weekly_training/weekly_location_raffle_entries?training_id=${ids}`
    );
  }

  async getEcards({ pageSize, name, pageNumber, endDate, startDate }) {
    return await this.get(
      `/e_card_dash_board/all_e_card?pageNumber=${pageNumber}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}&name=${name}`
    );
  }

  async deleteEcard (id) {
    return await this.delete(`/e_card_dash_board/delete_e_card/${id}`);
  }

  async getBreezyData({ limit, pageNumber, endDate, startDate }) {
    return await this.get(
      `/breezy_dash_board/all_candidates?page=${pageNumber}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
    );
  }

  async getTrainingReports() {
    return await this.get(`/dashboard_weekly_training/all_training_stats`);
  }

  async changeRepStatus(id: string, status: string) {
    return await this.post(
      `/rep_and_regional_reimbursement/update_rep_receipet/${id}`,
      { status }
    );
  }

  async getCanidates(state: any) {
    const queryString = objectToQueryString(state);
    return await this.get(
      `/candidates_dash_board/getCandidates?${queryString}`
    );
  }

  async downloadCanidates(state: any) {
    const queryString = objectToQueryString(state);
    return await this.get(`/download_candidates?${queryString}`);
  }

  async deleteContact(id) {
    return await this.delete(`${SNOW_FLAKE_URL}/contact/delete/${id}`);
  }
  async getBlackSparrow(tag) {
    return await this.get(`${SNOW_FLAKE_URL}/board/candidate_states?date=${tag}`);
  }

  async getBlackSparrowStats({ type, date, flag }) {
    return await this.get(`${SNOW_FLAKE_URL}/board/black_sparrow_states?type=${type}&date=${date}&flag=${flag}`);
  }
  async getBlackSparrowStatsLocations(locationDate) {
    return await this.get(`${SNOW_FLAKE_URL}/board/candidate_location_states?date=${locationDate}`);
  }


  async getSparrowReport(id) {
    return await this.get(`${SNOW_FLAKE_URL}/board/candidate_states/${id}`);
  }


  async getSparrowReportLocation(location) {
    return await this.get(`${SNOW_FLAKE_URL}/board/candidate_location_details?location=${location}`);
  }

  async deleteReimbursement(id) {
    return await this.delete(
      `/rep_and_regional_reimbursement/delete_receipt/${id}`
    );
  }

  async getLicenses(state: any) {
    return await this.post(`/licensing/all_licenses`, state);
  }

  async getSummary (state) {
    axios.defaults.headers["x-auth-token"] = token;

    return await this.post(`${SNOW_FLAKE_URL}/contact/summary`, state);
  }

  async addLocation(state: any) {
    return await this.post(`/recruitment_center_location/add_location`, state);
  }

  async updateLocation(state: any) {
    return await this.post(
      `/recruitment_center_location/update_location`,
      state
    );
  }
  async addTraining(state: any) {
    return await this.post(`${SNOW_FLAKE_URL}/trainings/add`, state);
  }

  async updateStatus(id: string) {
    return await this.get("/helper/changestatus/" + id);
  }

  async getCategories() {
    return await this.get(`${SNOW_FLAKE_URL}/videos/all`);
  }

  async getUsers() {
    return await this.get(`${SNOW_FLAKE_URL}/dashboard/list_users`);
  }

  async getOffices() {
    return await this.get(
      `${SNOW_FLAKE_URL}/partner/offices?partner=Jason Card`
    );
  }

  async deleteCategory(id: string) {
    return await this.delete(`${SNOW_FLAKE_URL}/videos/delete/` + id);
  }
  async deleteFeedback(id: string) {
    return await this.delete(`${SNOW_FLAKE_URL}/board/feedbacks/` + id);
  }

  async updateFeedback(id: string) {
    return await this.get(`${SNOW_FLAKE_URL}/helper/feedback/${id}`);
  }

  async getCatVideos(category: string) {
    return await this.get(`${SNOW_FLAKE_URL}/videos/allvideos/` + category);
  }

  async addCategory(data: any) {
    return await this.post("/videos/add", data, {
      "Content-Type": "multipart/form-data",
    });
  }

  async getOpportunities(pagination: any) {
    const queryString = objectToQueryString(pagination);

    return await this.get(`/get_opportunitiess?${queryString}`);
  }
  async downloadOpportunities(pagination: any) {
    const queryString = objectToQueryString(pagination);

    return await this.get(`/download_opportunities?${queryString}`);
  }

  async getLeads(pagination: any) {
    const queryString = objectToQueryString(pagination);
    return await this.get(`/get_solar_leads?${queryString}`);
  }
  async downloadLeads(pagination: any) {
    const queryString = objectToQueryString(pagination);
    return await this.get(`/get_solar_leads_download?${queryString}`);
  }
  async getVivintLeads(pagination: any) {
    const queryString = objectToQueryString(pagination);
    return await this.get(
      `${SNOW_FLAKE_URL}/board/vivint_leads?${queryString}`
    );
  }

  async getSignedReps(pagination: any) {
    const queryString = objectToQueryString(pagination);

    return await this.get(
      `/signed_reps_dash_board/get_signed_reps?${queryString}`
    );
  }

  async getSolarLead() {
    return await this.get(
      `/solar_leads_management/fetch_acuity_calendar_office`
    );
  }
  async solarDetails(id: string) {
    return await this.get(
      `/solar_leads_management/calendar_office_detail/${id}`
    );
  }

  async getList() {
    return await this.get(`${SNOW_FLAKE_URL}/helper/downline/2719`);
  }

  async getSolarStats(id: string) {
    return await this.get(
      `/solar_leads_management/get_solar_closure_stats?officeId=${id}`
    );
  }

  async downloadSignedReps(pagination: any) {
    delete pagination.pageNumber
    delete pagination.pageSize
    const queryString = objectToQueryString(pagination);

    return await this.get(
      `/signed_reps_dash_board/download_signed_reps?${queryString}`
    );
  }

  async getTechOffices({ limit, page, year }) {
    axios.defaults.headers["x-auth-token"] = token;

    return await axios.get(
      `https://ecards.evolutionsmarthome.com/api/v1/board/tech_offices?limit=${limit}&skip=${
        page - 1
      }&year=${year}`
    );
  }
  async getProduction(filters: any) {
    axios.defaults.headers["x-auth-token"] = token;

    return await axios.post(
      `https://mobile.evolutionsmarthome.com/filter_efp_up_pmr`,
      filters
    );
  }

  async getMediaAds(pagination: any) {
    axios.defaults.headers["x-auth-token"] = token;

    const queryString = objectToQueryString(pagination);

    return await this.get(`${SNOW_FLAKE_URL}/board/media_ads?${queryString}`);
  }

  async getSaleOffices({ limit, page, year }) {
    axios.defaults.headers["x-auth-token"] = token;
    return await axios.get(
      `https://ecards.evolutionsmarthome.com/api/v1/board/sale_offices?limit=${limit}&skip=${
        page - 1
      }&year=${year}`
    );
  }

  async getCampusReels({ limit, page }) {
    return await this.get(
      `${SNOW_FLAKE_URL}/board/campus_reel?limit=${limit}&skip=${page - 1}`
    );
  }

  async getRecruiterLocations() {
    return await this.get(`/recruitment_center_location/locations`);
  }

  async deleteLocation(id: string) {
    return await this.delete(
      `/recruitment_center_location/delete_location/${id}`
    );
  }

  async getBreezy({ limit, page }) {
    return await this.get(
      `${SNOW_FLAKE_URL}/board/breezy?limit=${limit}&skip=${page - 1}`
    );
  }

  async getPositions({ limit, page }) {
    return await this.get(
      `${SNOW_FLAKE_URL}/board/positions?limit=${limit}&skip=${page - 1}`
    );
  }

  async getDigitalHire({ limit, page }) {
    return await this.get(
      `/board/digital_hire?limit=${limit}&skip=${page - 1}`
    );
  }

  async getDigitalHireSocial({ limit, page }) {
    return await this.get(
      `/board/digital_hire_social?limit=${limit}&skip=${page - 1}`
    );
  }

  async deleteVideo(id: string) {
    return await this.delete(`${SNOW_FLAKE_URL}/videos/deletevideo/` + id);
  }

  async getCandidateFilters() {
    return await this.get(`${SNOW_FLAKE_URL}/board/get_candidate_filters`);
  }

  async deleteCandidate(id) {
    return await this.delete(`/candidates_dash_board/delete_candidate/${id}`,)
  }

  async getWeeklyTrainings(pagination) {
    const queryString = objectToQueryString(pagination);

    return await this.get(
      `/dashboard_weekly_training/all_training?${queryString}`
    );
  }

  async deleteTraining(id) {
    return await this.delete(`/dashboard_weekly_training/delete/${id}`);
  }

  async getBlitz(pagination: any) {
    const queryString = objectToQueryString(pagination);
    return await this.get(`/blitz_dash_board/all_blitz?${queryString}`);
  }

  // async getBlitzUsers({ limit, page, key }) {
  //   return await this.get(
  //     `${SNOW_FLAKE_URL}/board/blitz_users?limit=${limit}&skip=${
  //       page - 1
  //     }&blitz_key=${key}`
  //   );
  // }

  async getBlitzDetails(id) {
    return await this.get(`${SNOW_FLAKE_URL}/blitz/${id}`);
  }
  async getRegisteredBlitz(params) {
    delete params.pageNumber
    delete params.pageSize
    return await this.get(`/blitz_dash_board/registered_blitz_user?${objectToQueryString(params)}`);
  }

  async getSparrowUsers(params) {
    return await this.get(`${SNOW_FLAKE_URL}/board/black_sparrow_states_users?${objectToQueryString(params)}`);
  }

  async getAquity({ limit, page }) {
    return await this.get(
      `${SNOW_FLAKE_URL}/board/aquity?limit=${limit}&skip=${page - 1}`
    );
  }

  // async getRaffles(id) {
  //   return await this.get(`/trainings/weekly_training_details/${id}`);
  // }

  async getSpecificTraining(id) {
    return await this.get(`${SNOW_FLAKE_URL}/trainings/get/${id}`);
  }

  async getAttendees({ pageNumber, pageSize, fullName, endDate, startDate }) {
    return await this.get(
      `/dashboard_weekly_training/all_raffle_entries?fullName=${fullName}&pageNumber=${pageNumber}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`
    );
  }
  async getWeeklyMeetings({ pageNumber, limit, endDate, startDate }) {
    return await this.get(
      `/dashboard_weekly_training/weekly_board_meeting_training_stats?page=${pageNumber}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
    );
  }

  async updateColumn(data: any) {
    return await this.post(`${SNOW_FLAKE_URL}/board/update_candidates`, data);
  }

  async getZipCodes({ limit, page }) {
    return await this.get(
      `${SNOW_FLAKE_URL}/board/zip_codes?limit=${limit}&skip=${page - 1}`
    );
  }

  async getCollegeCampuses({ limit, page }) {
    return await this.get(
      `${SNOW_FLAKE_URL}/board/college_campuses?limit=${limit}&skip=${page - 1}`
    )
  }

  async getContacts(pagination) {
    return await this.post(`/get_contacts`, pagination);
  }
  async getContactStats(date) {
    return await this.get(`/contacts/total_count_stats?date=${date}` );
  }

  async getFilters(tag) {
    return await this.post(`/contact_dash_board/users`, {
      tag,
    })
  }

  async getContactUsers(tag) {
    return await this.post(`${SNOW_FLAKE_URL}/contact/users`, {
      tag,
    })
  }

  async downloadContacts(pagination) {
    return await this.post(`/get_contacts_download`, pagination)
  }

  async downloadBlitz() {
    return await this.get(`${SNOW_FLAKE_URL}/board/export_blitz`)
  }

  async downloadBlitzUsers(pagination) {
    const queryString = objectToQueryString(pagination);
    return await this.get(`${SNOW_FLAKE_URL}/board/export_blitz_users?${queryString}`)
  }

  async getInfoSessions({ limit, pageNumber }) {
    return await this.get(
      `/online_info_session_dash_board/all_info_session?page=${pageNumber}&limit=${limit}`
    );
  }

  async getInfoSessionsReg({ limit, pageNumber, startDate, endDate }) {
    return await this.get(
      `/online_info_session_dash_board/all_registered_reps?page=${pageNumber}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
    );
  }

  async getInfoSessionsAttended({ limit, pageNumber, endDate, startDate }) {
    return await this.get(
      `/online_info_session_dash_board/all_attended_reps?page=${pageNumber}&limit=${limit}&endDate=${endDate}&startDate=${startDate}`
    );
  }

  async getScholarships({ limit, page }) {
    return await this.get(
      `${SNOW_FLAKE_URL}/board/scholarships?limit=${limit}&skip=${page - 1}`
    );
  }

  async addImages(data: any) {
    return await this.postWithParams(`/give_away_images/add`, data, {
      "Content-Type": "multipart/form-data",
    });
  }

  async deleteImage(id: string) {
    return await this.delete(`/give_away_images/delete/` + id);
  }

  async getGiveaway() {
    return await this.get(`/give_away_images/images`);
  }
  async getFeedbacks({ limit, page }) {
    axios.defaults.headers["x-auth-token"] = token;

    return await this.get(
      `${SNOW_FLAKE_URL}/board/feedbacks?limit=${limit}&skip=${page - 1}`
    );
  }

  async changeImageStatus(id: string, active: boolean) {
    return await this.post(`/give_away_images/make_active`, {id, is_active: active});
  }

  async changeStatus(id: string) {
    return await this.get(`${SNOW_FLAKE_URL}/helper/changestatus/` + id);
  }

  async getDashData(filters: any) {
    axios.defaults.headers["x-auth-token"] = token;

    return await this.get(
      `${SNOW_FLAKE_URL}/partner/dashboard?from_date=${filters.from_date}&to_date=${filters.to_date}&partner=EVO - Card&name=Jason Card`
    );
  }

  async getSpecificOffice(filters: any) {
    axios.defaults.headers["x-auth-token"] = token;

    return await this.get(
      `${SNOW_FLAKE_URL}/partner/dashboard_by_office?from_date=${filters.from_date}&to_date=${filters.to_date}&office=${filters.office}`
    );
  }

  async getOfficeList(filters: any) {
    axios.defaults.headers["x-auth-token"] = token;

    return await this.get(
      `${SNOW_FLAKE_URL}/partner/get_list_office?type=${filters.type}&from_date=${filters.from_date}&to_date=${filters.to_date}&office=${filters.office}`
    );
  }

  // partner/get_list_office?type=leads&from_date=Jan 1, 2023&to_date=May 1, 2024&office=GM Scott Windell

  async getLeadsData(filters: any) {
    return await this.get(
      `${SNOW_FLAKE_URL}/partner/get_list?type=${filters.type}&from_date=${filters.from_date}&to_date=${filters.to_date}&partner=EVO - Card&name=Jason Card`
    );
  }
  // partner/get_list?type=leads&from_date=Jan 1, 2023&to_date=May 1, 2024&partner=EVO - Card&name=Jason Card

  async addVideo(data: any) {
    return await this.post(`${SNOW_FLAKE_URL}/videos/addvideo`, data);
  }

  async addUser(data: any) {
    return await this.post(`${SNOW_FLAKE_URL}/dashboard/signup`, data);
  }

  async updatePermissions(data: any) {
    return await this.post(
      `${SNOW_FLAKE_URL}/dashboard/update_permissions`,
      data
    );
  }

  async changePassword(data: any) {
    return await this.post(`${SNOW_FLAKE_URL}/dashboard/resetpassword`, data);
  }

  async deleteUser(id: any) {
    return await this.delete(`${SNOW_FLAKE_URL}/dashboard/delete_user/` + id);
  }
}

export default UserService;
