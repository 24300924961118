import { Table } from "common/Table/Table";
import { useDebounce } from "hooks/useDebounce";
import { Fragment, useCallback, useEffect, useState } from "react";
import UserService from "services/user.service";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

export const MediaAds = () => {
  const columns = [
    {
      key: "firstName",
      title: "First name",
      label: "First name",
      isSort: true,
      dbColName: "firstName",
    },
    {
      key: "userid.last_name",
      title: "Last name",

      label: "Last name",
      dbColName: "userid",
      render: (value: any) => <p className="w-32">{value?.last_name}</p>,
    },
    {
      key: "city",
      title: "city",
      isSort: true,
      label: "city",
      dbColName: "city",
    },
    {
      key: "state",
      title: "state",
      label: "state",
      isSort: true,
      dbColName: "state",
    },
    {
      key: "season",
      title: "season",
      label: "season",
      dbColName: "season",
    },
    {
      key: "start_date",
      title: "start date",
      label: "start_date",
      dbColName: "start_date",
    },
    {
      key: "end_date",
      title: "end date",
      label: "end_date",
      dbColName: "end_date",
    },
    {
      key: "position_for_list",
      title: "position for list",
      label: "position for list",
      dbColName: "position_for_list",
    },
    {
      key: "recruiting_support",
      title: "recruiting support",
      label: "recruiting support",
      dbColName: "recruiting_support",
    },
    {
      key: "amount",
      title: "amount",
      label: "amount",
      dbColName: "amount",
    },
  ];

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 100,
    state: "",
    season: "",
    recruiting_support: "",
  });

  const debounced = useDebounce(pagination, 500);

  const fetchData = useCallback(() => {
    setLoading(true);
    service
      .getMediaAds(debounced)
      .then((resp) => {
        setData(
          resp.data.data.map((item) => ({
            ...item,
            firstName: item?.userid?.first_name,
          }))
        );
        setTotalItems(resp.data.total_docs);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [debounced]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleExport = () => {
    exportToCSV(data, "mediaads");
  };

  const clearFilter = () => {
    setPagination(
      {
        page: 1,
        limit: 100,
        state: "",
        season: "",
        recruiting_support: "",
      }
    )
  }

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
          Media Ads
        </h1>
        <p className="text-gray-500 text-sm">View all Media Ads</p>
      </div>
      <Table
        data={data}
        pagination={pagination}
        setPagination={setPagination}
        loadingData={loading}
        setData={setData}
        handleExport={handleExport}
        filters={<Filters pagination={pagination} clearFilter={clearFilter} />}
        totalItems={totalItems}
        columns={columns}
        columnFilters={
          <ColumnFilters
            pagination={pagination}
            setPagination={setPagination}
          />
        }
      />
    </Fragment>
  );
};

const Filters = ({ clearFilter, pagination }) => {
  const filters = { ...pagination };
  delete filters.page;
  delete filters.limit;
  const hasFilters = Object.values(filters).some((item) => item);
  
  return hasFilters ? (
      <button
        onClick={() => {
          clearFilter();
        }}
        className="bg-black whitespace-nowrap rounded-lg text-white px-3 py-2 text-sm "
      >
        Clear Filters
      </button>
    ) : null
  
}

const ColumnFilters = ({ pagination, setPagination }) => {
  return (
    <div className="flex gap-4 mt-3">
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        value={pagination.state}
        placeholder="Search by State"
        onChange={(e) => {
          setPagination({
            ...pagination,
            state: e.target.value,
          });
        }}
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        value={pagination.season}
        placeholder="Season"
        onChange={(e) => {
          setPagination({
            ...pagination,
            season: e.target.value,
          });
        }}
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        value={pagination.recruiting_support}
        placeholder="Recuriting Support"
        onChange={(e) => {
          setPagination({
            ...pagination,
            recruiting_support: e.target.value,
          });
        }}
      />
    </div>
  );
};
