import { Table } from "common/Table/Table";
import { useRouter } from "hooks/useRouter";
import { exportToCSV, formatDate, objectToQueryString } from "utils/funcs";
import { Fragment, useCallback, useEffect, useState } from "react";
import UserService from "services/user.service";
import { useSelector } from "react-redux";
import { userSelector } from "redux/selectors/user";
import { formatPhone } from "pages/Contacts";
import { useDebounce } from "hooks/useDebounce";
import { MetricCard } from "pages/BlackSparrow";
import { Modal } from "common/Modal";
import DatePicker from "react-datepicker";
import moment from "moment";

const service = new UserService();

export const Blitz = () => {
  const { push } = useRouter();
  const user = useSelector(userSelector);

  const columns = [
    // {
    //   key: "actions",
    //   title: "View",
    //   dbColName: "blitz_key",
    //   render: (key: string) => (
    //     <button
    //       type="button"
    //       onClick={() => push(`/blitz/${key}`)}
    //       className="px-4 py-1.5 bg-green-500 text-xs text-white rounded-md"
    //     >
    //       View
    //     </button>
    //   ),
    // },
    // {
    //   key: "BLITZ_KEY",
    //   title: "BLITZ KEY",
    //   label: "BLITZ KEY",
    //   dbColName: "BLITZ_KEY",
    // },

    {
      key: "first_name",
      title: "blitz creator name",
      render: (values) => `${values.first_name} ${values?.last_name}`,
      freeze: true,
      freezeIndex: "left-0",
    },
    {
      key: "start_date",
      dbColName: "start_date",
      title: "start date of blitz",
      render: (date) => formatDate(date),
    },
    {
      key: "end_date",
      dbColName: "end_date",
      title: "end date of blitz",
      render: (date) => formatDate(date),
    },
    { key: "badge_id", dbColName: "badge_id", title: "Blitz badge id" },

    { key: "sale_office", dbColName: "sale_office", title: "sales office" },
    {
      key: "phone_number",
      dbColName: "phone_number",
      title: "phone number",
      render: (phone) => formatPhone(phone),
    },
    { key: "tech_office", dbColName: "tech_office", title: "tech office" },
    { key: "state", dbColName: "state", title: "state (Location)" },
    { key: "zip_code", dbColName: "zip_code", title: "zip code (Location)" },
    {
      key: "month_of_blitz",
      dbColName: "month_of_blitz",
      title: "month of blitz",
    },

    // {key: "vets", dbColName: "vets", title: "vets"},
    { key: "rookies", dbColName: "rookies", title: "estimated total rookies" },
    { key: "vets", dbColName: "vets", title: "estimated experience reps" },
    {
      key: "techs_needed",
      dbColName: "techs_needed",
      title: "number of techs needed",
    },
    // {key: "est_accounts", dbColName: "est_accounts", title: "est accounts"},
    {
      key: "request_solar_closer",
      dbColName: "request_solar_closer",
      title: "solar Requested",
    },
    {
      key: "date",
      dbColName: "createdAt",
      title: "Blitz Creation date",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      key: "action",
      dbColName: "_id",
      freeze: true,
      freezeIndex: "right-0",
      title: "action",
      render: (id) => {
        return (
          <div className="flex items-center  justify-center">
            <button
              type="button"
              onClick={() => push(`/blitz/${id}`)}
              className="text-blue-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
            </button>
            <div />
          </div>
        );
      },
    },
    // {key: "opt_in_for_bonus", dbColName: "opt_in_for_bonus", title: "opt in for bonus"},
    // {key: "senior_region", dbColName: "senior_region", title: "senior region"},
    // {key: "lead_tech_id", dbColName: "lead_tech_id", title: "lead tech id"},
    // {key: "lead_tech_name", dbColName: "lead_tech_name", title: "lead tech name"},
    // {key: "lead_tech_phone", dbColName: "lead_tech_phone", title: "lead tech phone"},
    // {key: "website_url", dbColName: "website_url", title: "website url", render: url => <a className="text-blue-500" href={url} target="_blank" rel="noreferrer">{url}</a>},
    // {key: "blitz_key", dbColName: "blitz_key", title: "blitz key"},
    // {key: "sales_year", dbColName: "sales_year", title: "sales year"},
    // {key: "userlist", dbColName: "userlist", title: "userlist"},
  ];

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [blitzStaste, setBlitzStaste] = useState({
    total_blitz_created: 99,
    total_registered_blitz_user_count: 972,
    total_un_registered_blitz_user_count: 6959,
  });
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 100,
    name: "",
    state: "",
    startDate: "",
    endDate: "",
    blitzMonth: "",
  });

  const [exporting, setExporting] = useState(false)
  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)

  const debounced = useDebounce(pagination, 500)

  useEffect(() => {
    setLoading(true);
    service
      .getBlitz(debounced)
      .then((resp) => {
        if (resp.data.success) {
          setData(resp.data.data);
          setBlitzStaste({
            ...blitzStaste,
            total_blitz_created: resp.data.total_blitz_created,
            total_registered_blitz_user_count:
              resp.data.total_registered_blitz_user_count,
            total_un_registered_blitz_user_count:
              resp.data.total_un_registered_blitz_user_count,
          });
          setTotalItems(resp.data.totalDocument);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [debounced]);

  const handleExport = () => {
    setExporting(true);

    service
      .getBlitz({ ...pagination, isDownload: "true" })
      .then((resp) => {
        exportToCSV(resp.data.data, "blitz");
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setExporting(false);
      });
  };

  const clearFilter = () => {
    setPagination({
      pageNumber: 1,
      pageSize: 100,
      name: "",
      state: "",
      startDate: "",
      endDate: "",
      blitzMonth: "",
    })
    setStartDate(null)
    setEndDate(null)

  };

  return (
    <Fragment>
      <div className="flex items-center justify-between">
        <div className="flex flex-col mb-4">
          <h1 className="font-medium text-gray-700 tracking-wide text-xl">
            Blitz
          </h1>
          <p className="text-gray-500 text-sm">View all Blitz</p>
        </div>

        <button
          type="button"
          onClick={() => push("/blitz-stats")}
          className="bg-black px-4 gap-2 flex items-center text-sm py-2.5 rounded-md text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z"
            />
          </svg>
          <span>Blitz Stats</span>
        </button>
      </div>
      {!loading && (
        <div className="grid grid-cols-3 gap-6 mb-5">
          <div>
            <MetricCard
              label="Total Blitz Created"
              displayName="Total Blitz Created"
              value={blitzStaste.total_blitz_created}
            />
          </div>
          <div
            className="cursor-pointer"
            onClick={() =>
              push(`/registered-users?${objectToQueryString(pagination)}`)
            }
          >
            <MetricCard
              label="Total Registered Blitz Users"
              displayName="Total Registered Blitz Users"
              className="underline underline-offset-2"
              value={blitzStaste.total_registered_blitz_user_count}
            />
          </div>
          <div>
            <MetricCard
              label="Total Un Registered Bliz Users"
              displayName="Total Un Registered Bliz Users"
              value={blitzStaste.total_un_registered_blitz_user_count}
            />
          </div>
        </div>
      )}
      <Table
        data={data}
        pagination={pagination}
        setPagination={setPagination}
        exportLabel="Export Blitz"
        loadingData={loading}
        setData={setData}
        exporting={exporting}
        handleExport={handleExport}
        totalItems={totalItems}
        columns={columns}
        filters={
          <BlitzUsers clearFilter={clearFilter} pagination={pagination} />
        }
        columnFilters={
          <ColumnFilters
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
            pagination={pagination}
            setPagination={setPagination}
          />
        }
      />
    </Fragment>
  );
};

const ColumnFilters = ({ startDate, setStartDate, endDate, setEndDate, pagination, setPagination }) => {

  const handleChangeDates = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);


    if (end) {
      setPagination({
        ...pagination,
        startDate: moment(start).format("YYYY-MM-DD"),
        endDate: moment(end).format("YYYY-MM-DD"),
      })
    } else {
      setPagination({
        ...pagination,
        startDate: '',
        endDate: '',
      })
    }
  }
  return (
    <div className="flex gap-4 mt-3">
      <div className="flex-1">
        <label className="text-xs text-gray-700">Blitz Month</label>
        <select
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          placeholder="Blitz Month"
          value={pagination.blitzMonth}
          onChange={(e) => {
            setPagination({
              ...pagination,
              blitzMonth: e.target.value,
            });
          }}
        >
          <option value="" disabled selected>
            Select Month
          </option>
          <option value="January">January</option>
          <option value="February">February</option>
          <option value="March">March</option>
          <option value="April">April</option>
          <option value="May">May</option>
          <option value="June">June</option>
          <option value="July">July</option>
          <option value="August">August</option>
          <option value="September">September</option>
          <option value="October">October</option>
          <option value="November">November</option>
          <option value="December">December</option>
        </select>
      </div>
      
      <div className="flex-1">
        <label className="text-xs text-gray-700">Creator</label>
        <input
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="text"
          value={pagination.name}
          placeholder="Search by creator"
          onChange={(e) => {
            setPagination({
              ...pagination,
              name: e.target.value,
            });
          }}
        />
      </div>
     
      {/* <div className="flex-1">
        <label className="text-xs text-gray-700">Blitz Start Date</label>
        <input
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          value={pagination.startDate}
          onChange={(e) =>
            setPagination({
              ...pagination,
              startDate: e.target.value,
            })
          }
        />
      </div>

      <div className="flex-1">
        <label className="text-xs text-gray-700">Blitz End Date</label>
        <input
          placeholder="End date"
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          value={pagination.endDate}
          onChange={(e) => {
            setPagination({
              ...pagination,
              endDate: e.target.value,
            });
          }}
        />
      </div> */}
      <div className="flex-1">
        <label className="text-xs text-gray-700">State</label>
        <input
          placeholder="State"
          value={pagination.state}
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="text"
          onChange={(e) => {
            setPagination({
              ...pagination,
              state: e.target.value,
            });
          }}
        />
      </div>
      <div className="flex-1 mt-1 gap-1 flex flex-col">
        <label className="text-xs text-gray-700">Date</label>
        <DatePicker
          selected={startDate}
          placeholderText="Select range"
          onChange={handleChangeDates}
          startDate={startDate}
          endDate={endDate}
          isClearable
          className="z-100  "
          customInput={
            <input
              placeholder="Select date"
              className=" rounded-md border w-full border-gray-outline outline-none placeholder:font-light focus-visible:shadow-none placeholder:text-sm focus:ring-0 focus:border-gray-200 py-2 text-sm text-zinc-600 pl-4 pr-4 disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;"
            />
          }
          selectsRange
          selectsDisabledDaysInRange
        />
      </div>
    </div>
  );
};

const BlitzUsers = ({ pagination, clearFilter }) => {
  const filters = { ...pagination };
  delete filters.pageNumber;
  delete filters.pageSize;
  const hasFilters = Object.values(filters).some((item) => item);

  const [exporting, setExporting] = useState(false);

  const handleExport = () => {
    setExporting(true);

    service
      .downloadBlitzUsers(pagination)
      .then((resp) => {
        exportToCSV(resp.data.data, "blitz_users");
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setExporting(false);
      });
  };
  return (
    <div className="flex items-center gap-2">
      {hasFilters && (
        <button
          onClick={() => {
            clearFilter();
          }}
          className="bg-black whitespace-nowrap rounded-md text-white px-3 py-2 text-sm "
        >
          Clear Filters
        </button>
      )}
      <button
        onClick={handleExport}
        disabled={exporting}
        className="bg-black disabled:bg-opacity-40 text-white flex items-center gap-2 p-2.5 rounded-md"
      >
        <span className="text-xs">
          {exporting ? "Exporting..." : "Export Blitz Users"}
        </span>
        {!exporting ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-loader-3 animate-spin"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 12a9 9 0 0 0 9 9a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9" />
            <path d="M17 12a5 5 0 1 0 -5 5" />
          </svg>
        )}
      </button>
    </div>
  );
};
