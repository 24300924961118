import { Table } from "common/Table/Table";
import { useRouter } from "hooks/useRouter";
import { TAGS } from "pages/BlackSparrow";
import { useEffect, useState } from "react";
import UserService from "services/user.service";

const service = new UserService()

export const BlackSparrowLocation = ()=> {
    const [locationDate, setLocationDate] = useState("YEARTODATE")
    const [locationWise, setLocationWise] = useState<any>([])
    const [loadingLocation, setLocationLocation] = useState(false)
    const { push } = useRouter()

    const locationColumns = [
        { key: "location", dbColName: "location", title: "location" },
        //  {key: "candidates", dbColName: "candidates", title: "candidates"},
        //  {key: "screening_completed", dbColName: "screening_completed", title: "screening_completed"},
        //  {key: "screening_approved", dbColName: "screening_approved", title: "screening_approved"},
        {
          key: "group_infosession",
          dbColName: "group_infosession",
          title: "Group Infosession Scheduled",
        },
        {
          key: "group_infosession_raffle",
          dbColName: "group_infosession_raffle",
          title: "Group Infosession Attended",
        },
        {
          key: "attende_offer",
          dbColName: "attende_offer",
          title: "attendee offer",
        },
        { key: "attende_hire", dbColName: "attende_hire", title: "attendee hire" },
        { key: "signedReps", dbColName: "signedReps", title: "signedReps" },
        {
          key: "contract_sent",
          dbColName: "contract_sent",
          title: "contract sent",
        },
        {
          key: "contract_signed",
          dbColName: "contract_signed",
          title: "contract signed",
        },
        {
          key: "last_street_genie_login",
          dbColName: "last_street_genie_login",
          title: "street Genie Login",
        },
        {
          key: "completed_training",
          dbColName: "completed_training",
          title: "completed training",
        },
        {
          key: "last_credit_run_date",
          dbColName: "last_credit_run_date",
          title: "ran credit",
        },
        {
          key: "last_install_date",
          dbColName: "last_install_date",
          title: "sales install",
        },
        { key: "onboarded_date", dbColName: "onboarded_date", title: "onboarded" },
        { key: "upgrades", dbColName: "upgrades", title: "upgrades" },
        {
          key: "register_for_blitz",
          dbColName: "register_for_blitz",
          title: "registered for blitz",
        },
        {
          key: "attended_blitz",
          dbColName: "attended_blitz",
          title: "attended blitz",
        },
        { key: "goals_set", dbColName: "goals_set", title: "goals set" },
        {
          key: "action",
          freeze: true,
          freezeIndex: "right-0",
          title: "action",
          dbColName: "location",
          render: (row) => {
            return (
              <button
                type="button"
                onClick={() => push(`/sparrow-report-location/${row}`)}
                className="text-blue-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              </button>
            )
          },
        },
      ]

      useEffect(() => {
        setLocationLocation(true);
        service
          .getBlackSparrowStatsLocations(locationDate)
          .then((stats) => {
            setLocationWise(stats.data.total_docs)
          })
          .catch((err) => {
            console.error(err)
          })
          .finally(() => {
            setLocationLocation(false)
          })
      }, [locationDate])


    return (
<Table
        data={locationWise}
        loadingData={loadingLocation}
        // handleExport={handleExport}
        totalItems={locationWise.length}
        filters={
          <div>
            <select
              className="rounded-md focus:border-gray-300 font-light focus:outline-none w-60 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
              value={locationDate}
              onChange={(e) => setLocationDate(e.target.value)}
            >
              {TAGS.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        }
        exportButton={false}
        columns={locationColumns}
      />
    )
}