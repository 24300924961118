import { ConfirmModal } from "common/ConfirmModal";
import { Modal } from "common/Modal";
import { Table } from "common/Table/Table";
import { Checkbox, Radio } from "flowbite-react";
import { useNotifications } from "hooks/useNotification";
import { useThunkDispatch } from "hooks/useThunkDispatch";
import {
  Fragment,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { setUser } from "redux/reducers/userReducer";
import { userSelector } from "redux/selectors/user";
import UserService from "services/user.service";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

const allPermissions = [
  { value: "leads", label: "Solar Leads" },
  { value: "reps", label: "Signed Reps" },
  { value: "video", label: "Video Categories" },
  { value: "feedbacks", label: "Feedback" },
  { value: "blitz", label: "Blitz" },
  { value: "giveaway", label: "Giveaway Images" },
  { value: "weekly", label: "Weekly Training" },
  { value: "candidates", label: "Candidates" },
  { value: "ads", label: "Media Ads" },
  { value: "opportunities", label: "Opportunities" },
  { value: "reimbursement", label: "Reimbursement" },
  { value: "tools", label: "Recuriting Tools" },
  // { value: "general", label: "General Data" },
  { value: "breezy", label: "Breezy Data" },
  { value: "conctacts", label: "Contacts" },
  { value: "salesoffice", label: "Sales Office" },
  { value: "techoffice", label: "Tech Office" },
  { value: "vivintLeads", label: "Vivint Leads" },
  // { value: "solarLead", label: "Solar Lead Management" },
  { value: "locations", label: "Recruiter Center Location" },
  { value: "production", label: "Production" },
  { value: "solar", label: "Solar Dashboard" },
  { value: "ecard", label: "Ecard & Interviews" },
  { value: "infoSession", label: "Info Session" },
  { value: "campuses", label: "Campuses & Zip code" },
  { value: "licenses", label: "Licenses" },
];

export const Users = () => {
  const [currentId, setCurrentId] = useState<any>(null);
  const [permissionModal, setPermissionModal] = useState(false);

  const columns = [
    {
      key: "fullname",
      title: "Full name",
      label: "fullname",
      dbColName: "fullname",
    },
    {
      key: "email",
      title: "email",
      label: "email",
      dbColName: "email",
    },
    {
      key: "role",
      title: "role",
      label: "role",
      dbColName: "admin",

    },
    {
      key: "createdAt",
      title: "created at",
      label: "date ",
      dbColName: "date",
      render: (date: string) => <p>{new Date(date).toLocaleDateString()}</p>,
    },
    {
      key: "actions",
      title: "actions",
      render: (user) => {
        return (
          <div className="flex gap-2">
            <button
              type="button"
              onClick={() => {
                setCurrentId(user);
                setPermissionModal(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 text-blue-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                setConfirm(true);
                setCurrentId(user._id);
              }}
              title="Permissions"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 text-red-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                />
              </svg>
            </button>
          </div>
        );
      },
    },
  ];

  const user = useSelector(userSelector)

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [state, setState] = useState({
    fullname: "",
    email: "",
    password: "",
    role: "",
    permissions: user?.permissions ?? [],
  });
  const [saving, setSaving] = useState(false);
  const dispatch = useThunkDispatch()

  const { successMessage, errorMessage } = useNotifications();

  const fetchData = useCallback(() => {
    setLoading(true);
    service
      .getUsers()
      .then((resp) => {
        setData(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDeleteUser = () => {
    service
      .deleteUser(currentId)
      .then((resp) => {
        fetchData();
        successMessage("User deleted successfully");
        setConfirm(false)
      })
      .catch((error) => {
        errorMessage("Failed to delete user");
        
      });
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    setSaving(true);
    service
      .addUser(state)
      .then((resp) => {
        successMessage("User added successfully");
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setSaving(false);
        setShowModal(false);
        fetchData()
      });
  };

  const handleChangePermission = (value) => {
    const permissions: any = [...state.permissions];

    if (permissions.includes(value)) {
      const index = permissions.indexOf(value);
      permissions.splice(index, 1);
    } else {
      permissions.push(value);
    }

    setState({
      ...state,
      permissions,
    });
  };

  const updatePermissions = (e) => {
    e.preventDefault();
    service
      .updatePermissions({ permissions: state.permissions, userid: currentId._id })
      .then((resp) => {
        successMessage("Permissions updated successfully");
        setPermissionModal(false)

        if(user._id === currentId._id) {
          dispatch(setUser(resp.data.data))
        }

        fetchData()
      })
      .catch((error) => {
        errorMessage("Permission update failed");
        setPermissionModal(false)
      });
  };

  const handleExport = () => {
    exportToCSV(data, "users");
  };

  return (
    <Fragment>
      <div className="flex justify-between items-center mb-4">
        <div>
          <h1 className="font-medium text-gray-700 tracking-wide text-xl">
            User Accounts
          </h1>
          <p className="text-gray-500 text-sm">View all users</p>
        </div>
        <button
          type="button"
          onClick={() => setShowModal(true)}
          className="bg-black drop-shadow-md text-white rounded-md p-2.5 text-sm flex items-center"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m6-6H6"
              />
            </svg>
          </span>
          Add User
        </button>
      </div>

      <ConfirmModal
        open={confirm}
        setOpen={setConfirm}
        message={"Are you sure to delete this user?"}
        onClickConfirm={handleDeleteUser}
      />
      <Table
        data={data}
        loadingData={loading}
        exportButton={false}
        handleExport={handleExport}
        totalItems={data.length}
        columns={columns}
      />
      <Modal
        showModal={permissionModal}
        setShowModal={setPermissionModal}
        title="Permissions"
        width="w-full md:w-1/2"
      >
        <div className="grid grid-cols-3 gap-8">
          {allPermissions.map((item) => (
            <label
              key={item.label}
              className="flex items-center gap-2 text-gray-800 "
              htmlFor={item.value}
            >
              <Checkbox
                name="permissions"
                value={item.value}
                defaultChecked={currentId?.permissions?.includes("All") || currentId?.permissions?.includes(item.value)}
                id={item.value}
                onChange={(e) => handleChangePermission(e.target.value)}
              />
              {item.label}
            </label>
          ))}
        </div>
        <div className="flex gap-4 border-t border-gray-200 mt-3.5 pt-3">
          <button
            type="submit"
            onClick={updatePermissions}
            className="text-sm text-white bg-green-600 rounded-md px-4 py-2"
          >
            Save
          </button>
          <button type="button" onClick={() => setPermissionModal(false)}>Cancel</button>
        </div>
      </Modal>

      <Modal
        width="w-full md:w-1/3"
        showModal={showModal}
        setShowModal={setShowModal}
        title="Add New User"
      >
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <input
            type="text"
            placeholder="Full Name"
            onChange={(e) =>
              setState({
                ...state,
                fullname: e.target.value,
              })
            }
            className="rounded-md focus:border-gray-400 focus:outline-none w-full focus:ring-0 border-gray-300 px-4 py-2.5 text-sm text-gray-800"
          />
          <input
            type="text"
            placeholder="Email"
            onChange={(e) =>
              setState({
                ...state,
                email: e.target.value,
              })
            }
            className="rounded-md focus:border-gray-400 focus:outline-none w-full focus:ring-0 border-gray-300 px-4 py-2.5 text-sm text-gray-800"
          />
          <input
            type="password"
            placeholder="Password"
            onChange={(e) =>
              setState({
                ...state,
                password: e.target.value,
              })
            }
            className="rounded-md focus:border-gray-400 focus:outline-none w-full focus:ring-0 border-gray-300 px-4 py-2.5 text-sm text-gray-800"
          />

          <div className="flex items-center gap-4">
            <label
              className="flex items-center gap-2 text-gray-800 "
              htmlFor="admin"
            >
              <Radio
                name="role"
                value="admin"
                id="admin"
                onChange={(e) => setState({ ...state, role: e.target.value })}
              />
              Admin
            </label>
            <label
              className="flex items-center gap-2 text-gray-800 "
              htmlFor="user"
            >
              <Radio
                name="role"
                value="user"
                id="user"
                onChange={(e) => setState({ ...state, role: e.target.value })}
              />
              User
            </label>
          </div>
          <p className="font-medium text-gray-800">Permissions</p>
          <div className="grid grid-cols-3 gap-8">
            {allPermissions.map((item) => (
              <label
                key={item.label}
                className="flex items-center gap-2 text-gray-800 "
                htmlFor={item.value}
              >
                <Checkbox
                  name="permissions"
                  value={item.value}
                  
                  id={item.value}
                  onChange={(e) => handleChangePermission(e.target.value)}
                />
                {item.label}
              </label>
            ))}
          </div>
          <button
            type="submit"
            disabled={saving}
            className="bg-green-400 disabled:cursor-not-allowed rounded-md font-medium text-sm disabled:bg-green-300 text-white p-2.5"
          >
            {saving ? "Saving..." : "Save"}
          </button>
        </form>
      </Modal>
    </Fragment>
  );
};
