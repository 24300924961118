import { Table } from "common/Table/Table"
import { useDebounce } from "hooks/useDebounce"
import { Fragment, useCallback, useEffect, useState } from "react";
import UserService from "services/user.service"
import { exportToCSV, formatDate, formatDateY } from "utils/funcs"
import { formatPhone } from "./Contacts"
import { DATE } from "./trainings/WeeklyStats"
import DatePicker from "react-datepicker"
import CopyText from "common/CopyText"
import moment from "moment";

const service = new UserService()

export const SignedReps = () => {
  const columns = [
    {
      key: "rep_id",
      dbColName: "rep_id",
      title: "rep id",
      freeze: true,
      freezeIndex: "left-0",
    },
    {
      key: "rep_name",
      dbColName: "rep_name",
      title: "rep name",
      freeze: true,
      freezeIndex: "left-20",
    },
    {
      key: "phone",
      dbColName: "phone",
      title: "phone",
      render: (phone) => <CopyText copyText={phone} text={formatPhone(phone)} />,
    },
    { 
      key: "email", 
      dbColName: "email", 
      title: "email", 
      render: (email) => <CopyText copyText={email} text={email} /> 
    },
    { key: "max_position", dbColName: "max_position", title: "max position" },
    { key: "tenure", dbColName: "tenure", title: "tenure" },
    { key: "region", dbColName: "region", title: "region" },
    // {
    //   key: "rep_first_name",
    //   dbColName: "rep_first_name",
    //   title: "rep first name",
    // },
    // {
    //   key: "rep_last_name",
    //   dbColName: "rep_last_name",
    //   title: "rep last name",
    // },
    {
      key: "management_group",
      dbColName: "management_group",
      title: "management group",
    },
    {
      key: "fp_preseason_previous_year",
      dbColName: "fp_preseason_previous_year",
      title: "fp preseason previous year",
    },
    {
      key: "fp_summer_previous_year",
      dbColName: "fp_summer_previous_year",
      title: "fp summer previous year",
    },
    {
      key: "fp_extended_previous_year",
      dbColName: "fp_extended_previous_year",
      title: "fp extended previous year",
    },
    {
      key: "fp_ytd_previous_year",
      dbColName: "fp_ytd_previous_year",
      title: "fp ytd previous year",
    },
    {
      key: "fp_total_previous_year",
      dbColName: "fp_total_previous_year",
      title: "fp total previous year",
    },
    {
      key: "prmr_preseason_previous_year",
      dbColName: "prmr_preseason_previous_year",
      title: "prmr preseason previous year",
    },
    {
      key: "prmr_summer_previous_year",
      dbColName: "prmr_summer_previous_year",
      title: "prmr summer previous year",
    },
    {
      key: "prmr_extended_previous_year",
      dbColName: "prmr_extended_previous_year",
      title: "prmr extended previous year",
    },
    {
      key: "prmr_ytd_previous_year",
      dbColName: "prmr_ytd_previous_year",
      title: "prmr ytd previous year",
    },
    {
      key: "prmr_total_previous_year",
      dbColName: "prmr_total_previous_year",
      title: "prmr total previous year",
    },
    {
      key: "upgrades_preseason_previous_year",
      dbColName: "upgrades_preseason_previous_year",
      title: "upgrades preseason previous year",
    },
    {
      key: "upgrades_summer_previous_year",
      dbColName: "upgrades_summer_previous_year",
      title: "upgrades summer previous year",
    },
    {
      key: "upgrades_extended_previous_year",
      dbColName: "upgrades_extended_previous_year",
      title: "upgrades extended previous year",
    },
    {
      key: "fp_preseason_",
      dbColName: "fp_preseason_",
      title: "fp preseason ",
    },
    { key: "fp_summer_", dbColName: "fp_summer_", title: "fp summer " },
    { key: "fp_extended_", dbColName: "fp_extended_", title: "fp extended " },
    { key: "fp_ytd_", dbColName: "fp_ytd_", title: "fp ytd " },
    {
      key: "prmr_preseason_",
      dbColName: "prmr_preseason_",
      title: "prmr preseason ",
    },
    { key: "prmr_summer_", dbColName: "prmr_summer_", title: "prmr summer " },
    {
      key: "prmr_extended_",
      dbColName: "prmr_extended_",
      title: "prmr extended ",
    },
    { key: "prmr_ytd_", dbColName: "prmr_ytd_", title: "prmr ytd " },
    {
      key: "upgrades_preseason_",
      dbColName: "upgrades_preseason_",
      title: "upgrades preseason ",
    },
    {
      key: "upgrades_summer_",
      dbColName: "upgrades_summer_",
      title: "upgrades summer ",
    },
    {
      key: "upgrades_extended_",
      dbColName: "upgrades_extended_",
      title: "upgrade  extended ",
    },
    {
      key: "upgrades_ytd_",
      dbColName: "upgrades_ytd_",
      title: "upgrades ytd ",
    },
    {
      key: "tenure_number",
      dbColName: "tenure_number",
      title: "tenure number",
    },
    {
      key: "days_since_last_genie_login",
      dbColName: "days_since_last_genie_login",
      title: "days since last genie login",
    },
    {
      key: "last_genie_login_date_",
      dbColName: "last_genie_login_date_",
      title: "last genie login date ",
      render: (date) => formatDate(date),
    },
    {
      key: "days_since_last_install",
      dbColName: "days_since_last_install",
      title: "days since last install",
    },
    {
      key: "last_install_date_",
      dbColName: "last_install_date_",
      title: "last install date ",
      render: (date) => formatDate(date),
    },
    {
      key: "days_since_last_credit",
      dbColName: "days_since_last_credit",
      title: "days since last credit",
    },
    {
      key: "last_credit_run_date_",
      dbColName: "last_credit_run_date_",
      title: "last credit run date ",
      render: (date) => formatDate(date),
    },
    {
      key: "agreement_sent_date",
      dbColName: "agreement_sent_date",
      title: "agreement sent date",
      render: (date) => formatDate(date),
    },
    {
      key: "agreement_signed_date",
      dbColName: "agreement_signed_date",
      title: "agreement signed date",
      render: (date) => formatDate(date),
    },
    {
      key: "onboarded_date",
      dbColName: "onboarded_date",
      title: "onboarded date",
      render: (date) => formatDate(date),
    },
    {
      key: "completed_sales_training_date",
      dbColName: "completed_sales_training_date",
      title: "completed sales training date",
      render: (date) => formatDate(date),
    },
    { key: "team", dbColName: "team", title: "team" },

    {
      key: "senior_management_group",
      dbColName: "senior_management_group",
      title: "senior management group",
    },
    {
      key: "senior_region",
      dbColName: "senior_region",
      title: "senior region",
    },
    { key: "partnership", dbColName: "partnership", title: "partnership" },
    { key: "division", dbColName: "division", title: "division" },
    { key: "recruiter_id", dbColName: "recruiter_id", title: "recruiter id" },
    {
      key: "recruiter_name",
      dbColName: "recruiter_name",
      title: "recruiter name",
    },
    {
      key: "recruiter_phone_number",
      dbColName: "recruiter_phone_number",
      title: "recruiter phone number",
      render: (phone) => formatPhone(phone),
    },
    {
      key: "recruiter_email",
      dbColName: "recruiter_email",
      title: "recruiter email",
    },
    {
      key: "team_leader_id",
      dbColName: "team_leader_id",
      title: "team leader id",
    },
    {
      key: "team_leader_name",
      dbColName: "team_leader_name",
      title: "team leader name",
    },
    {
      key: "team_leader_phone_number",
      dbColName: "team_leader_phone_number",
      title: "team leader phone number",
      render: (phone) => formatPhone(phone),
    },
    {
      key: "team_leader_email",
      dbColName: "team_leader_email",
      title: "team leader email",
    },
    {
      key: "management_group_leader_id",
      dbColName: "management_group_leader_id",
      title: "management group leader id",
    },
    {
      key: "management_group_leader_name",
      dbColName: "management_group_leader_name",
      title: "management group leader name",
    },
    {
      key: "management_group_leader_phone_number",
      dbColName: "management_group_leader_phone_number",
      render: (phone) => formatPhone(phone),

      title: "management group leader phone number",
    },
    {
      key: "management_group_leader_email",
      dbColName: "management_group_leader_email",
      title: "management group leader email",
    },
    {
      key: "senior_management_group_leader_id",
      dbColName: "senior_management_group_leader_id",
      title: "senior management group leader id",
    },
    {
      key: "senior_management_group_leader",
      dbColName: "senior_management_group_leader",
      title: "senior management group leader",
    },
    {
      key: "senior_management_group_leader_phone_number",
      dbColName: "senior_management_group_leader_phone_number",
      title: "senior_management group leader phone number",
      render: (phone) => formatPhone(phone),
    },
    {
      key: "senior_management_group_leader_email",
      dbColName: "senior_management_group_leader_email",
      title: "senior_management_group_leader_email",
    },
    {
      key: "region_leader_id",
      dbColName: "region_leader_id",
      title: "region_leader_id",
    },
    {
      key: "region_leader_name",
      dbColName: "region_leader_name",
      title: "region_leader_name",
    },
    {
      key: "region_leader_phone_number",
      dbColName: "region_leader_phone_number",
      title: "region_leader_phone_number",
      render: (phone) => formatPhone(phone),
    },
    {
      key: "region_leader_email",
      dbColName: "region_leader_email",
      title: "region_leader_email",
    },
    {
      key: "senior_region_id",
      dbColName: "senior_region_id",
      title: "senior_region_id",
    },
    {
      key: "senior_region_name",
      dbColName: "senior_region_name",
      title: "senior_region_name",
    },
    {
      key: "senior_region_leader_phone_number",
      dbColName: "senior_region_leader_phone_number",
      render: (phone) => formatPhone(phone),

      title: "senior_region_leader_phone_number",
    },
    {
      key: "senior_region_leader_email",
      dbColName: "senior_region_leader_email",
      title: "senior region leader email",
    },
    {
      key: "pertner_leader_id",
      dbColName: "pertner_leader_id",
      title: "pertner leader id",
    },
    {
      key: "partner_leader_name",
      dbColName: "partner_leader_name",
      title: "partner leader name",
    },
    {
      key: "partner_leader_phone_number",
      dbColName: "partner_leader_phone_number",
      title: "partner leader phone number",
      render: (phone) => formatPhone(phone),
    },
    {
      key: "partner_leader_email",
      dbColName: "partner_leader_email",
      title: "partner leader email",
    },
    {
      key: "last_solar_lead_created",
      dbColName: "last_solar_lead_created",
      title: "last solar lead created",
      render: (date) => formatDate(date),
    },
    {
      key: "last_solar_lead_closed",
      dbColName: "last_solar_lead_closed",
      title: "last_solar_lead_closed",
    },
    {
      key: "last_solar_lead_installed",
      dbColName: "last_solar_lead_installed",
      title: "last solar lead installed",
      render: (date) => formatDate(date),
    },
    {
      key: "last_solar_lead_funded",
      dbColName: "last_solar_lead_funded",
      title: "last solar lead funded",
      render: (date) => formatDate(date),
    },
    { key: "year", dbColName: "year", title: "year" },
  ];

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 100,
    year: "2025",
    phone: "",
    repName: "",
    // agreement_signed_date: "",
    onboarded_date: "",
    managementGroup: "",
    region: "",
    tenure: "",
    repId: "",
    date: "YEARTODATE",
    email: "",
    maxPosition: "",
  });

  const debounced = useDebounce(pagination, 500);

  const fetchData = useCallback(() => {
    setLoading(true);
    service
      .getSignedReps(debounced)
      .then((resp) => {
        if (resp.data.success) {
          setData(resp.data.data);
          setTotalItems(resp.data.totalDocument);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [debounced]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleExport = () => {
    setExporting(true);
    service
      .getSignedReps({
        ...pagination,
        pageNumber: "",
        pageSize: "",
        isDownload: "true",
      })
      .then((resp) => {
        exportToCSV(resp.data.data, "reps");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setExporting(false);
      });
  };

  const clearFilter = () => {
    setPagination({
      pageNumber: 1,
      phone: "",
      pageSize: 500,
      year: "2025",
      repName: "",
      // agreement_signed_date: "",
      onboarded_date: "",
      managementGroup: "",
      date: "",
      region: "",
      tenure: "",
      repId: "",
      email: "",
      maxPosition: "",
    });
  };

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
          Signed Reps
        </h1>
        <p className="text-gray-500 text-sm">View all</p>
      </div>
      <Table
        data={data}
        handleExport={handleExport}
        pagination={pagination}
        setData={setData}
        exporting={exporting}
        setPagination={setPagination}
        showSearch={false}
        loadingData={loading}
        filters={
          <Filters
            clearFilter={clearFilter}
            pagination={pagination}
            setPagination={setPagination}
          />
        }
        columnFilters={
          <ColumnFilters
            pagination={pagination}
            setPagination={setPagination}
          />
        }
        totalItems={totalItems}
        columns={columns}
      />
    </Fragment>
  );
};

const ColumnFilters = ({ pagination, setPagination }) => {
  const [showCustom, setShowCustom] = useState(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const handleChangeDates = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    if ((!start && !end) || end) {
      setPagination({
        ...pagination,
        date: `${moment(start).format("YYYY-MM-DD")},${moment(end).format("YYYY-MM-DD")}`,
      });
    }
  };

  return (
    <div className="flex gap-4 mt-3">
      <div className="flex flex-1 flex-col gap-1">
        <label className="text-xs text-zinc-700">Email</label>
        <input
          className="rounded-md focus:border-gray-300 font-light focus:outline-none flex-1 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="text"
          value={pagination.email}
          placeholder="Search by Email"
          onChange={(e) => {
            setPagination({
              ...pagination,
              email: e.target.value,
            });
          }}
        />
      </div>
      <div className="flex flex-1 flex-col gap-1">
        <label className="text-xs text-zinc-700">Region</label>
        <input
          className="rounded-md focus:border-gray-300 font-light focus:outline-none flex-1 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="text"
          placeholder="Region"
          value={pagination.region}
          onChange={(e) => {
            setPagination({
              ...pagination,
              region: e.target.value,
            });
          }}
        />
      </div>
      <div className="flex flex-1 flex-col gap-1">
        <label className="text-xs text-zinc-700">Tenure</label>
        <select
          placeholder="Tenure"
          value={pagination.tenure}
          className="rounded-md flex-1 focus:border-gray-300 font-light focus:outline-none w-72 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          onChange={(e) => {
            setPagination({
              ...pagination,
              tenure: e.target.value,
            });
          }}
        >
          <option value="" disabled selected>
            Tenure
          </option>
          <option value="">All</option>
          <option value="Rookie">Rookie</option>
          <option value="Veteran">Veteran</option>
          <option value="Sophomore">Sophomore</option>
        </select>
      </div>
      {/* <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="date"
        onChange={(e) =>
          setPagination({
            ...pagination,
            agreement_signed_date: e.target.value,
          })
        }
      /> */}

      <div className="flex flex-1 flex-col gap-1">
        <label className="text-xs text-zinc-700">Max Position</label>
        <select
          value={pagination.maxPosition}
          onChange={(e) =>
            setPagination({ ...pagination, maxPosition: e.target.value })
          }
          className="text-gray-800 rounded-md flex-1 border-gray-200 focus:border-gray-300 focus:ring-0 focus:outline-none text-sm"
        >
          <option value="" >
            Max Position
          </option>
          <option value="Rep">Rep</option>
          <option value="Team Lead">Team Lead</option>
          <option value="Manager">Manager</option>
          <option value="Senior Manager">Senior Manager</option>
          <option value="Regional">Regional</option>
          <option value="Senior Regional">Senior Regional</option>
          <option value="Partner">Partner</option>
        </select>
      </div>
      <div className="flex flex-1 flex-col gap-1">
        <label className="text-xs text-zinc-700">Signed Rep Date</label>
        <select
          value={pagination.date}
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-60 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          onChange={(e) => {
            if (e.target.value !== "CUSTOM") {
              setPagination({ ...pagination, date: e.target.value });
            } else {
              setShowCustom(true);
            }
          }}
        >
          <option>Select</option>
          {DATE.map((item) => (
            <option value={item} key={item}>
              {item}
            </option>
          ))}
        </select>
        {showCustom && (
          <DatePicker
            selected={startDate}
            placeholderText="Select range"
            onChange={handleChangeDates}
            startDate={startDate}
            endDate={endDate}
            isClearable
            className="z-100 ml-2 py-2 w-60"
            customInput={
              <input
                placeholder="Select date"
                className=" rounded-md border w-60 border-gray-outline outline-none focus-visible:shadow-none placeholder:text-sm focus:ring-0 focus:border-gray-200 py-2 text-sm text-zinc-600 pl-4 pr-4 disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;"
              />
            }
            selectsRange
            selectsDisabledDaysInRange
          />
        )}
      </div>
      {/* <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="date"
        onChange={(e) =>
          setPagination({
            ...pagination,
            onboarded_date: e.target.value,
          })
        }
      /> */}

      {/* <div className="flex items-center w-full relative gap-1">
        <label
          id="startDate"
          className="flex flex-col gap-1 w-full  text-xs text-zinc-700"
        >
          Start Date
          <input
            className="rounded-md focus:border-gray-300 font-light focus:outline-none  focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
            type="date"
            id="startDate"
            name="startDate"
            value={dateSelected.start}
            onChange={handleChangeDate}
          />
        </label>
        {dateSelected.start && (
          <button
            className="text-xs text-zinc-700 absolute right-10 mt-5"
            onClick={() => {
              setDatesSelected({
                ...dateSelected,
                start: "",
                end: "",
              });
              setPagination({
                ...pagination,
                startDate: "",
                endDate: "",
              });
            }}
            type="button"
          >
            X
          </button>
        )}
      </div>
      <div className="flex items-center w-full relative gap-1">
        <label
          id="endDate"
          className="flex flex-col gap-1 w-full text-xs text-zinc-700"
        >
          End Date
          <input
            className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
            type="date"
            id="endDate"
            name="endDate"
            value={dateSelected.end}
            onChange={handleChangeDate}
          />
        </label>
        {dateSelected.end && (
          <button
            className="text-xs text-zinc-700 absolute right-10 mt-5"
            onClick={() => {
              setDatesSelected({
                ...dateSelected,
                end: "",
                start: "",
              });
              setPagination({
                ...pagination,
                endDate: "",
                sartDate: "",
              });
            }}
            type="button"
          >
            X
          </button>
        )}
      </div> */}
    </div>
  );
};

export const Filters = ({ clearFilter, pagination, setPagination }: any) => {
  const filters = { ...pagination };
  delete filters.pageNumber;
  delete filters.pageSize;
  const hasFilters = Object.values(filters).some((item) => item);

  return (
    <div className="flex flex-1 items-center gap-4">
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none flex-1 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        value={pagination.repId}
        placeholder="Search by Rep ID"
        onChange={(e) => {
          setPagination({
            ...pagination,
            repId: e.target.value,
          });
        }}
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        value={pagination.repName}
        placeholder="Search by Rep Name"
        onChange={(e) => {
          setPagination({
            ...pagination,
            repName: e.target.value,
          });
        }}
      />
      <input
        placeholder="Phone number"
        className="rounded-md focus:border-gray-300 font-light focus:outline-none flex-1 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        value={pagination.phone}
        onChange={(e) => {
          setPagination({
            ...pagination,
            phone: e.target.value,
          })
        }}
      />
      <input
        placeholder="Management group"
        className="rounded-md focus:border-gray-300 font-light focus:outline-none flex-1 focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        value={pagination.managementGroup}
        onChange={(e) => {
          setPagination({
            ...pagination,
            managementGroup: e.target.value,
          });
        }}
      />
      <div className="flex flex-col flex-shrink-0 items-center gap-2">
        {/* <label className="text-sm text-gray-800">Filter by: </label>  */}
        <select
          value={pagination.year}
          onChange={(eve) =>
            setPagination({
              ...pagination,
              year: eve.target.value,
            })
          }
          className="rounded-md border-gray-200 focus:border-gray-300 focus:ring-0 focus:outline-none text-sm"
        >
          <option value="" disabled>Year</option>
          {["All", "2023", "2024", "2025"].map((year, i) => (
            <option key={i} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      {hasFilters && (
        <button
          onClick={() => {
            clearFilter();
          }}
          className="bg-black whitespace-nowrap rounded-md text-white px-3 py-2 text-sm "
        >
          Clear Filters
        </button>
      )}
    </div>
  );
};
