import classNames from "classnames";
import { Modal } from "common/Modal";
import { Table } from "common/Table/Table";
import { ToggleButton } from "common/form/ToggleButton";
import { useNotifications } from "hooks/useNotification";
import { Fragment, useEffect, useState } from "react";
import UserService from "services/user.service";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

const Badge = ({ status }) => {
  return (
    <div
      className={classNames("p-1 w-16 text-xs text-center  rounded-full", {
        "bg-green-100 text-[#027a48]": status,
        "bg-red-100 text-[#c01048]": !status,
      })}
    >
      {status ? "Paid" : "Not Paid"}
    </div>
  );
};

export const Reimbursement = () => {
  const columns = [
    {
      key: "badge_id",
      title: "Badge ID",
      label: "Badge ID",
      dbColName: "badge_id",
    },
    {
      key: "name",
      title: "Name",
      label: "Name",
      dbColName: "name",
    },
    {
      key: "title",
      title: "Title",
      label: "title",
      dbColName: "title",
    },
    {
      key: "manager",
      title: "Manger",
      label: "Manger",
      dbColName: "manager_name",
      show: false,
    },
    {
      key: "reimbursements.amount",
      title: "amount",
      label: "amount",
      dbColName: "reimbursements",
      render: (values) => <p>${values.amount}</p>,
    },
    {
      key: "reimbursements.place",
      title: "place",
      label: "place",
      dbColName: "reimbursements",
      render: (values: { place: string }) => <p>{values.place}</p>,
    },
    {
      key: "reimbursements.description",
      title: "description",
      label: "description",
      dbColName: "reimbursements",

      render: (values: { description: string }) => <p>{values.description}</p>,
    },
    {
      key: "created",
      title: "Date Submitted",
      label: "Submitted",
      dbColName: "createdAt",

      render: (date) => <p>{new Date(date).toLocaleDateString()}</p>,
    },
    {
      key: "reimbursements.status",
      title: "status",
      render: (values: { _id: string; reimbursements: { paid: boolean } }) => (
        <div className="flex items-center gap-2">
          <Badge status={values.reimbursements.paid} />
          <ToggleButton
            value={values.reimbursements.paid}
            id={`status-${values._id}`}
            onChange={(e) => changeStatus(values._id)}
          />
        </div>
      ),
    },
    {
      key: "action",
      title: "Action",
      render: (values: any) => {
        return (
          <div className="flex gap-2">
            <button
              type="button"
              onClick={() => {
                setShowModal(true);
                setImage(values.reimbursements.receipt);
              }}
              className="bg-green-400 text-xs rounded-md text-white px-4 py-2"
            >
              View
            </button>
            <button
              type="button"
              className="bg-blue-400 text-xs rounded-md text-white px-4 py-2"
            >
              Download
            </button>
          </div>
        );
      },
    },
  ];

  const { successMessage } = useNotifications();

  const [reimbursements, setReimbursements] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [exporting, setExporting] = useState(false)
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 100,
  });

  const changeStatus = (id: string) => {
    service
      .changeStatus(id)
      .then(() => {
        const shallowCopy = [...reimbursements]
        const modified: any = shallowCopy.map((item: any) => item._id === id ? { ...item, reimbursements: { ...item.reimbursements, paid: true }} : item )
        setReimbursements(modified)
        successMessage("Status updated successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    service
      .getReimbursements(pagination)
      .then((resp) => {
        setReimbursements(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  const handleExport = () => {
    setExporting(true);
    exportToCSV(reimbursements, "reimbursements")
    setExporting(false)
  }

  return (
    <Fragment>
      <div className="flex flex-col mb-4 ">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
         General Reimbursement
        </h1>
        <p className="text-gray-500 text-sm">View all reimbursements</p>
      </div>
      <Table
        data={reimbursements}
        loadingData={loading}
        setPagination={setPagination}
        exporting={exporting}
        handleExport={handleExport}
        pagination={pagination}
        totalItems={reimbursements.length}
        columns={columns}
      />
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        title="Image Preview"
      >
        <div>
          <img src={image} alt="receipt" />
        </div>
      </Modal>
    </Fragment>
  );
};
