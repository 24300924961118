import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    pageNumber: 1,
    pageSize: 100,
    type: "",
    leaderName: "",
    location: "",
    startDate: "",
    hierarcyFilter: "",
    endDate: "",
}

const weeklyFilterReducer = createSlice({
    name: "weeklyFilters",
    initialState,
    reducers: {
        setFilters: (state, action) =>  {
            return { ...state, ...action.payload }
        },
        clearFilters: () => initialState,
    },
})

export const {
    setFilters,
    clearFilters,
} = weeklyFilterReducer.actions

export default weeklyFilterReducer.reducer
