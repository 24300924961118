import { Table } from "common/Table/Table";
import { Fragment, useCallback, useEffect, useState } from "react";
import UserService from "services/user.service";
import { useDebounce } from "hooks/useDebounce";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportToCSV, formatDate, formatDateY } from "utils/funcs";

const service = new UserService();

export const Leads = () => {
  const columns = [
    { key: "master_repid", dbColName: "master_repid", title: "master repid",
      freeze: true,
      freezeIndex: 'left-0'
     },

    {
      key: "recruiter_name",
      dbColName: "recruiter_name",
      title: "recruiter name",
      freeze: true,
      freezeIndex: 'left-20'
    },
    {
      key: "master_closer_name",
      dbColName: "master_closer_name",
      title: "master closer name",
    },
    //  
    //   key: "master_enerflo_id_hash",
    //   dbColName: "master_enerflo_id_hash",
    //   title: "master_enerflo_id_hash",
    // },
    {
      key: "employee_type",
      dbColName: "employee_type",
      title: "employee type",
    },
    {
      key: "master_first_agreement_signed",
      dbColName: "master_first_agreement_signed",
      title: "master first agreement signed",
    },
    {
      key: "master_customer",
      dbColName: "master_customer",
      title: "master customer",
    },
    {
      key: "master_lead_date",
      dbColName: "master_lead_date",
      title: "master lead date",
    },
    {
      key: "master_lead_source",
      dbColName: "master_lead_source",
      title: "master lead source",
    },
    {
      key: "master_current_lead_source",
      dbColName: "master_current_lead_source",
      title: "master current lead source",
    },
    {
      key: "master_setter_name",
      dbColName: "master_setter_name",
      title: "master setter name",
    },
    {
      key: "master_setter",
      dbColName: "master_setter",
      title: "master setter",
    },

    {
      key: "master_closer",
      dbColName: "master_closer",
      title: "master closer",
    },
    {
      key: "master_enerflo_office",
      dbColName: "master_enerflo_office",
      title: "master enerflo office",
    },
    {
      key: "master_epc_company",
      dbColName: "master_epc_company",
      title: "master epc company",
    },

    {
      key: "master_milestone_permit_received",
      dbColName: "master_milestone_permit_received",
      title: "master milestone permit received", render: date=> formatDate(date)
    },
    {
      key: "master_milestone_install_completed",
      dbColName: "master_milestone_install_completed",
      title: "master milestone install completed", render: date=> formatDate(date)
    },
    { key: "funded_date", dbColName: "funded_date", title: "funded date", render: date=> formatDate(date) },
    {
      key: "master_ppw_net",
      dbColName: "master_ppw_net",
      title: "master ppw net",
    },
    {
      key: "master_net_ppw",
      dbColName: "master_net_ppw",
      title: "master net ppw",
    },
    {
      key: "master_system_size",
      dbColName: "master_system_size",
      title: "master system size",
    },
    {
      key: "master_system_size_watts",
      dbColName: "master_system_size_watts",
      title: "master system size watts",
    },
    {
      key: "master_funded_system_size_watt",
      dbColName: "master_funded_system_size_watt",
      title: "master funded system size watt",
    },
    {
      key: "master_lead_status",
      dbColName: "master_lead_status",
      title: "master lead status",
    },
    {
      key: "master_install_status",
      dbColName: "master_install_status",
      title: "master_install_status",
    },
    { key: "recruiter_id", dbColName: "recruiter_id", title: "recruiter id" },

    {
      key: "rt_c_team_name",
      dbColName: "rt_c_team_name",
      title: "rt c team name",
    },
    {
      key: "rt_c_team_leader_id",
      dbColName: "rt_c_team_leader_id",
      title: "rt c team leader id",
    },
    {
      key: "rt_c_team_leader_name",
      dbColName: "rt_c_team_leader_name",
      title: "rt c team leader name",
    },
    {
      key: "rt_c_management_group_name",
      dbColName: "rt_c_management_group_name",
      title: "rt c management group name",
    },
    {
      key: "rt_c_management_group_leader_id",
      dbColName: "rt_c_management_group_leader_id",
      title: "rt c management group leader id",
    },
    {
      key: "rt_c_management_group_leader_name",
      dbColName: "rt_c_management_group_leader_name",
      title: "rt c management group leader name",
    },
    {
      key: "rt_c_senior_management_group_name",
      dbColName: "rt_c_senior_management_group_name",
      title: "rt c senior management group name",
    },
    {
      key: "rt_c_senior_management_group_leader_id",
      dbColName: "rt_c_senior_management_group_leader_id",
      title: "rt c senior management group leader id",
    },
    {
      key: "rt_c_senior_management_group_leader_name",
      dbColName: "rt_c_senior_management_group_leader_name",
      title: "rt c senior management group leader name",
    },
    {
      key: "rt_c_region_name",
      dbColName: "rt_c_region_name",
      title: "rt c region name",
    },
    {
      key: "rt_c_region_leader_id",
      dbColName: "rt_c_region_leader_id",
      title: "rt c region leader id",
    },
    {
      key: "rt_c_region_leader_name",
      dbColName: "rt_c_region_leader_name",
      title: "rt c region leader name",
    },
    {
      key: "rt_c_senior_region_name",
      dbColName: "rt_c_senior_region_name",
      title: "rt c senior region name",
    },
    {
      key: "rt_c_senior_region_leader_id",
      dbColName: "rt_c_senior_region_leader_id",
      title: "rt c senior region leader id",
    },
    {
      key: "rt_c_senior_region_leader_name",
      dbColName: "rt_c_senior_region_leader_name",
      title: "rt c senior region leader name",
    },
    {
      key: "rt_c_partnership_name",
      dbColName: "rt_c_partnership_name",
      title: "rt c partnership name",
    },
    {
      key: "rt_c_partnership_leader_id",
      dbColName: "rt_c_partnership_leader_id",
      title: "rt c partnership leader id",
    },
    {
      key: "rt_c_partnership_leader_name",
      dbColName: "rt_c_partnership_leader_name",
      title: "rt c partnership leader name",
    },
    {
      key: "rt_c_division_name",
      dbColName: "rt_c_division_name",
      title: "rt c division name",
    },
    {
      key: "se_sales_year",
      dbColName: "se_sales_year",
      title: "se sales year",
    },
    { key: "ps_id", dbColName: "ps_id", title: "ps id" },
    { key: "psb_id", dbColName: "psb_id", title: "psb id" },
    { key: "l_id", dbColName: "l_id", title: "l id" },
    { key: "lb_id", dbColName: "lb_id", title: "lb id" },
    { key: "i_id", dbColName: "i_id", title: "i id" },
    { key: "ib_id", dbColName: "ib_id", title: "ib id" },
    { key: "sa_id", dbColName: "sa_id", title: "sa id" },
    { key: "sab_id", dbColName: "sab_id", title: "sab id" },
    { key: "f_id", dbColName: "f_id", title: "f id" },
    { key: "fb_id", dbColName: "fb_id", title: "fb id" },
    {
      key: "master_milestone_permit_submitted",
      dbColName: "master_milestone_permit_submitted",
      title: "master milestone permit submitted",
      render: date => formatDate(date)
    },
    {
      key: "master_epc_redline",
      dbColName: "master_epc_redline",
      title: "master epc redline",
    },
    {
      key: "master_funded_redline",
      dbColName: "master_funded_redline",
      title: "master funded redline",
    },
    {
      key: "smarthome_solar_package_type",
      dbColName: "smarthome_solar_package_type",
      title: "smarthome solar package type",
    },
    {
      key: "smarthome_package_type",
      dbColName: "smarthome_package_type",
      title: "smarthome package type",
    },
    {
      key: "total_contract_price",
      dbColName: "total_contract_price",
      title: "total contract price",
    },
    {
      key: "net_sales_price",
      dbColName: "net_sales_price",
      title: "net sales price",
    },
    {
      key: "master_milestone_install_scheduled",
      dbColName: "master_milestone_install_scheduled",
      title: "master milestone install scheduled",
      render: date => formatDate(date)
    },
    {
      key: "master_milestone_site_survey_complete",
      dbColName: "master_milestone_site_survey_complete",
      title: "master milestone site survey complete",
      render: date => formatDate(date)
    },
    {
      key: "master_milestone_site_survey_scheduled",
      dbColName: "master_milestone_site_survey_scheduled",
      title: "master milestone site survey scheduled",
      render: date => formatDate(date)
    },
    {
      key: "master_milestone_ntp_granted",
      dbColName: "master_milestone_ntp_granted",
      title: "master milestone ntp granted",
    },
    {
      key: "master_dealer_fee",
      dbColName: "master_dealer_fee",
      title: "master dealer fee",
    },
    {
      key: "master_installer_adder",
      dbColName: "master_installer_adder",
      title: "master installer adder",
    },
    {
      key: "amigo_total_paid",
      dbColName: "amigo_total_paid",
      title: "amigo total paid",
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [exporting, setExporting] = useState(false);

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 100,
    // year: 2023,
    recruiterName: "",
    masterFirstAgrementSignedStartDate: "",
    masterFirstAgrementSignedEndDate: "",
  });

  const debounced = useDebounce(pagination, 500);

  const fetchData = useCallback(() => {
    setLoading(true);
    service
      .getLeads(debounced)
      .then((resp) => {
        setTotalItems(resp.data.total);
        setData(
          resp.data.data.map((item) => ({
            ...item,
            master_lead_date: item?.master_lead_date
              ? new Date(item.master_lead_date).toLocaleDateString()
              : "",
            master_first_agreement_signed: item?.master_first_agreement_signed
              ? new Date(
                  item.master_first_agreement_signed
                ).toLocaleDateString()
              : "",
          }))
        );
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  }, [debounced]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleExport = () => {
    setExporting(true);
    const pagin: any = { ...pagination };
    delete pagin.pageNumber;
    delete pagin.pageSize;
    service
      .downloadLeads(pagin)
      .then((resp) => {
        exportToCSV(
          resp.data.data.map((item) => ({ ...item._source })),
          "leads"
        );
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setExporting(false);
      });
  };

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
          Sloar Leads
        </h1>
        <p className="text-gray-500 text-sm">View all leads</p>
      </div>
      <Table
        data={data}
        loadingData={loading}
        handleExport={handleExport}
        pagination={pagination}
        setPagination={setPagination}
        setData={setData}
        exporting={exporting}
        totalItems={totalItems}
        columns={columns}
        columnFilters={
          <ColumnFilters
            pagination={pagination}
            setPagination={setPagination}
          />
        }
      />
    </Fragment>
  );
};

const ColumnFilters = ({ pagination, setPagination }) => {
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const onChangeCreated = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    setPagination({
      ...pagination,

      masterFirstAgrementSignedStartDate: start ?  formatDateY(start): '',
      masterFirstAgrementSignedEndDate: end ?  formatDateY(end): '',
    });
  };

  return (
    <div className="flex gap-4 mt-3">
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        placeholder="Search by Recruiter Name"
        onChange={(e) => {
          setPagination({
            ...pagination,
            recruiterName: e.target.value,
          });
        }}
      />
      {/* <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="date"
        onChange={(e) => {
          setPagination({
            ...pagination,
            masterFirstAgrementSignedDate: e.target.value,
          });
        }}
      /> */}

      <DatePicker
        selected={startDate}
        placeholderText="Master First Agreement Signed Date"
        onChange={onChangeCreated}
        // showIcon
        startDate={startDate}
        endDate={endDate}
        isClearable
        className="z-100 py-2"
        customInput={
          <input
            placeholder="Select date"
            className=" bg-white  rounded-md border w-80 border-gray-outline outline-none focus-visible:shadow-none placeholder:text-sm focus:ring-2 ring-brown-10 ring-opacity-50 py-2 text-sm text-zinc-600 pl-4 pr-4 disabled:bg-zinc-50 disabled:text-zinc-500 disabled:cursor-not-allowed text-dark-8 font-TTHoves;"
          />
        }
        selectsRange
        selectsDisabledDaysInRange
      />
    </div>
  );
};
