import React, { FC } from "react";
import { MobileSidebar } from "./MobileSidebar";
import { Sidebar } from "./Sidebar";
import { Banner } from "common/Banner";

interface IProps {
  children: React.ReactNode;
}

export const DashboardLayout: FC<IProps> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [sidebar, setSidebar] = React.useState(true);
  return (
    <div className="min-h-screen font-poppins">
      <div className="h-screen flex overflow-hidden bg-gray-100">
        {/* Mobile sidebar */}
        <div className="block lg:hidden">
          <MobileSidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
        </div>

        {/* Static sidebar for desktop */}
        {sidebar ? (
          <div className="hidden lg:block">
            <Sidebar />
          </div>
        ) : null}
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <main className="focus:outline-none overflow-auto h-screen p-6">
            <Banner setSidebarOpen={setSidebarOpen} />

            <button
              className=" text-gray-500 focus:outline-none hidden -mt-3 mb-2 lg:block"
              onClick={() => setSidebar((prev) => !prev)}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
            {children}
          </main>
        </div>
      </div>
    </div>
  );
};
