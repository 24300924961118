export const Loader = () => {
    return (
        <div className="flex flex-col h-full items-center justify-center mx-auto w-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler mx-auto text-primary icon-tabler-loader-3 animate-spin"
          width="40"
          height="40"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M3 12a9 9 0 0 0 9 9a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9" />
          <path d="M17 12a5 5 0 1 0 -5 5" />
        </svg>
      </div>
    )
}