import { Table } from "common/Table/Table";
import { useDebounce } from "hooks/useDebounce";
import { useRouter } from "hooks/useRouter";
import { Fragment, useCallback, useEffect, useState } from "react";
import UserService from "services/user.service";
import { exportToCSV, formatDate } from "utils/funcs";

const service = new UserService();

export const SolarLeadManagement = () => {
  // "solar_closure": [
  //     {
  //         "closure_name": "Gabriel Gaertner",
  //         "calendar_id": 10315848,
  //         "timezone": "America/Phoenix",
  //         "is_book": false,
  //         "time_stamp": 1721806316917,
  //         "badge_id": "231816"
  //     },
  //     {
  //         "closure_name": "Levi Childers",
  //         "calendar_id": 10316352,
  //         "timezone": "America/Phoenix",
  //         "is_book": false,
  //         "time_stamp": 1721806316917,
  //         "badge_id": "128022"
  //     }
  // ],
  const { push } = useRouter();

  const columns = [
    {
      key: "id",
      dbColName: "id",
      title: "id",
      label: "id",
    },
    {
      key: "name",
      dbColName: "name",
      title: "name",
      label: "name",
      render: (value) => <p>{value.split("-")[1]}</p>,
      freeze: true,
      freezeIndex: "left-0",
    },
    {
      key: "office_name",
      dbColName: "office_name",
      title: "office name",
      label: "office_name",
    },
    { key: "email", dbColName: "email", title: "email", label: "email" },
    {
      key: "replyTo",
      dbColName: "replyTo",
      title: "reply to",
      label: "replyTo",
    },
    {
      key: "description",
      dbColName: "description",
      title: "description",
      label: "description",
    },
    {
      key: "location",
      dbColName: "location",
      title: "location",
      label: "location",
    },
    {
      key: "timezone",
      dbColName: "timezone",
      title: "timezone",
      label: "timezone",
    },
    {
      key: "thumbnail",
      dbColName: "thumbnail",
      title: "thumbnail",
      label: "thumbnail",
    },
    { key: "image", dbColName: "image", title: "image", label: "image" },

    {
      key: "order_type",
      dbColName: "order_type",
      title: "order type",
      label: "order_type",
    },
    {
      key: "order_frequency",
      dbColName: "order_frequency",
      title: "order frequency",
      label: "order_frequency",
    },
    {
      key: "orderKpi",
      dbColName: "orderKpi",
      title: "Order kpi",
      label: "orderKpi",
    },
    {
      key: "kpiDuration",
      dbColName: "kpiDuration",
      title: "kpi duration",
      label: "kpiDuration",
    },
    {
      key: "kpi_duration_date",
      dbColName: "kpi_duration_date",
      title: "kpi duration date",
      render: date => formatDate(date),
      label: "kpi_duration_date",
    },
    {
      key: "action",
      dbColName: "_id",
      title: "action",

      freeze: true,
      freezeIndex: "right-0",
      render: (id) => {
        return (
          <div className="flex items-center  justify-center">
            <button
              type="button"
              onClick={() => push(`/solar-details/${id}`)}
              className="text-blue-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
            </button>
            <div />
          </div>
        );
      },
    },
  ];

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 500,
    year: 2023,
    repName: "",
    agreement_signed_date: "",
    onboarded_date: "",
    managementGroup: "",
    region: "",
  });

  const debounced = useDebounce(pagination, 500);

  const fetchData = useCallback(() => {
    setLoading(true);
    service
      .getSolarLead()
      .then((resp) => {
        setData(resp.data.data);
        setTotalItems(resp.data.data.length);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
          Solar Lead Management
        </h1>
        <p className="text-gray-500 text-sm">View all</p>
      </div>
      <Table
        data={data}
        // handleExport={handleExport}
        pagination={pagination}
        setData={setData}
        exportButton={false}
        // exporting={exporting}
        setPagination={setPagination}
        loadingData={loading}
        // filters={
        //   <Filters pagination={pagination} setPagination={setPagination} />
        // }
        // columnFilters={
        //   <ColumnFilters
        //     pagination={pagination}
        //     setPagination={setPagination}
        //   />
        // }
        totalItems={totalItems}
        columns={columns}
      />
    </Fragment>
  );
};

const ColumnFilters = ({ pagination, setPagination }) => {
  return (
    <div className="flex gap-4 mt-3">
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        placeholder="Search by Rep Name"
        onChange={(e) => {
          setPagination({
            ...pagination,
            repName: e.target.value,
          });
        }}
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="date"
        onChange={(e) =>
          setPagination({
            ...pagination,
            agreement_signed_date: e.target.value,
          })
        }
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        placeholder="Region"
        onChange={(e) => {
          setPagination({
            ...pagination,
            region: e.target.value,
          });
        }}
      />
      <input
        placeholder="Management group"
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        onChange={(e) => {
          setPagination({
            ...pagination,
            managementGroup: e.target.value,
          });
        }}
      />
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="date"
        onChange={(e) =>
          setPagination({
            ...pagination,
            onboarded_date: e.target.value,
          })
        }
      />
    </div>
  );
};

export const Filters = ({ pagination, setPagination }: any) => {
  return (
    <div className="flex items-center gap-2">
      <label className="text-sm text-gray-800">Filter by: </label>
      <select
        onChange={(eve) =>
          setPagination({
            ...pagination,
            year: eve.target.value,
          })
        }
        defaultValue={pagination.year}
        className="rounded-md border-gray-200 focus:border-gray-300 focus:ring-0 focus:outline-none text-sm"
      >
        <option>Year</option>
        <option>1999</option>
        <option>2000</option>
        <option>2001</option>
        <option>2002</option>
        <option>2003</option>
        <option>2004</option>
        <option>2005</option>
        <option>2006</option>
        <option>2007</option>
        <option>2008</option>
        <option>2009</option>
        <option>2010</option>
        <option>2011</option>
        <option>2012</option>
        <option>2013</option>
        <option>2015</option>
        <option>2016</option>
        <option>2017</option>
        <option>2018</option>
        <option>2019</option>
        <option>2020</option>
        <option>2021</option>
        <option>2022</option>
        <option>2023</option>
      </select>
    </div>
  );
};
