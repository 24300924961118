import { Modal } from "common/Modal";
import { Table } from "common/Table/Table";
import { Tooltip } from "common/Tooltip";
// import { Tooltip } from "flowbite-react";
import { useNotifications } from "hooks/useNotification";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "redux/selectors/user";
import UserService from "services/user.service";
import swal from "sweetalert";
import { exportToCSV } from "utils/funcs";

const service = new UserService();

export const Feedbacks = () => {
  const [modal, setModal] = useState(false);
  const [images, setImages] = useState([]);
  const columns = [
    { key: "badgeid", title: "badgeid", dbColName: "badgeid" },
    {
      key: "images",
      title: "images",
      dbColName: "images",
      render: (images) => {
        return (
          <button
            type="button"
            className="text-blue-400"
            onClick={() => {
              setModal(true);
              setImages(images);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
              />
            </svg>
          </button>
        );
      },
    },
    { key: "name", title: "name", isSort: true, dbColName: "name" },
    { key: "email", title: "email", dbColName: "email" },
    { key: "position", title: "position", dbColName: "position" },

    {
      key: "description",
      title: "description",
      dbColName: "description",
      render: (description) => (
        <Tooltip message={description}>
          {description && description?.toString()?.slice(0, 40)}
        </Tooltip>
      ),
    },
    {
      key: "createdAt",
      title: "submitted",
      dbColName: "createdAt",
      render: (date) => <p>{new Date(date).toLocaleDateString()}</p>,
    },
    {
      key: "actions",
      title: "actions",
      render: ({ _id }) => {
        return (
          <div className="flex gap-2">
            <button
              onClick={() => handleDelete(_id)}
              className="bg-primary text-white rounded-md text-xs p-2 "
            >
              Delete
            </button>
            <button
              onClick={() => handleChangeStatus(_id)}
              className="bg-green-500 text-white rounded-md text-xs p-2 "
            >
              Change Status
            </button>
          </div>
        );
      },
    },
  ];

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 100,
  });

  const user = useSelector(userSelector)
  const { successMessage } = useNotifications();

  console.log(user)

  const fetchData = useCallback(() => {
    setLoading(true);
    service
      .getFeedbacks(pagination)
      .then((resp) => {
        if (resp.data.success) {
          setData(resp.data.data);
          setTotalItems(resp.data.data.length);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDelete = (id: string) => {
    swal({
      title: "Are you sure, you want to delete this feedback?",
      icon: "warning",
      className: "w-[500px]",
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
        },
        confirm: {
          text: "Delete",
          className: "bg-primary text-white",
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        service.deleteFeedback(id).then((resp) => {
          if (resp.data.success) {
            successMessage("Feedback deleted successfully");
            const shallowCopy = [...data];
            const newData = shallowCopy.filter((d: any) => d._id !== id);
            setData(newData);
          }
        });
      }
    });
  };

  const handleChangeStatus = (id) => {
    service.updateFeedback(id).then((resp) => {
      console.log(resp)
      successMessage("Feedback status changed");
    }).catch(error => {
      console.log(error)
    })
  }

  const handleExport = () => {
    exportToCSV(data, "feedbacks");
  };

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
          Feedbacks
        </h1>
        <p className="text-gray-500 text-sm">View all </p>
      </div>
      <Table
        data={data}
        pagination={pagination}
        setPagination={setPagination}
        setData={setData}
        loadingData={loading}
        handleExport={handleExport}
        totalItems={totalItems}
        columns={columns}
      />
      <Modal showModal={modal} setShowModal={setModal} title="Images">
        {images.length === 0 ? (
          <p className="text-center font-semibold text-lg">No Images</p>
        ) : (
          <div className="grid grid-cols-2 gap-2">
            {images.map((image, index) => (
              <div key={index} className="mx-auto w-full">
                <img
                  src={image}
                  alt="feedbakc"
                  className="h-full mx-auto w-full object-cover"
                />
              </div>
            ))}
          </div>
        )}
      </Modal>
    </Fragment>
  );
};
