import { Table } from "common/Table/Table";
import { useRouter } from "hooks/useRouter";
import { useEffect, useState } from "react";
import UserService from "services/user.service";

const service = new UserService();

export const RegisteredUsers = () => {
  const [loading, setLoading] = useState(false)
  const [registeredUsers, setRegisteredUsers] = useState<any>([])

  const { push, query } = useRouter()

  useEffect(() => {
    setLoading(true)
    service
      .getRegisteredBlitz(query)
      .then((resp) => {
        setRegisteredUsers(resp.data.data)
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false)
      })
  }, [query])

  const columns = [
    { key: "first name", title: "first name", dbColName: "first_name" },
    { key: "last name", title: "last name", dbColName: "last_name" },
    { key: "badge id", title: "badge id", dbColName: "badge_id" },
    { key: "phone number", title: "phone number", dbColName: "phone_number" },
    {
      key: "rep start date",
      title: "rep start date",
      dbColName: "rep_start_date",
    },
    { key: "rep end date", title: "rep end date", dbColName: "rep_end_date" },
    {
      key: "tenure registered",
      title: "tenure registered",
      dbColName: "tenure_registered",
    },
    { key: "position", title: "position", dbColName: "position" },
    { key: "max position", title: "max position", dbColName: "max position" },
    { key: "sales year", title: "sales year", dbColName: "sales_year" },
  ];

  return (
    <div>
      <div className="flex gap-4 items-start">
        <button
          type="button"
          onClick={() => push("/blitz")}
          className="bg-white rounded-md p-2 border"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
            />
          </svg>
        </button>
        <div className="flex flex-col mb-4">
          <h1 className="font-medium text-gray-700 tracking-wide text-xl">
            Registered Users
          </h1>
          <p className="text-gray-500 text-sm">View all Registered users</p>
        </div>
      </div>
      <Table
        loadingData={loading}
        columns={columns}
        data={registeredUsers}
        totalItems={registeredUsers.length}
      />
    </div>
  )
}
