import ReactApexChart from "react-apexcharts";

export const AreaChart = ({ name, data, labelName, series }: any) => {
  const chartData: any = {
    series: [
      {
        name:  labelName ?? name,
        data: data,
      },
    ],
    options: {
        title: {
            text:name,
            align: 'left',
          },
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "string",
        categories: series,
      },
    },
  };
  return (
    <div id="chart" className="border border-gray-200 bg-white p-3 rounded-md">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={350}
      />
    </div>
  );
};
