import { useRouter } from "hooks/useRouter";
import React, { memo, useEffect, useState } from "react";
import UserService from "services/user.service";

const service = new UserService();

const TrainingReports = ({ loading, data }) => {
  const weeks = Array.from({ length: 28 }, (_, i) => i + 1);
  const { push }= useRouter()
  
  const grandTotal = {
    totalUsers: data?.reduce((sum, loc) => sum + loc.total_user, 0),
    weekTotals: weeks.map((week) =>
      data?.reduce((weekSum, loc) => {
        const detailForWeek = loc.detail.find((detail) => detail.week_number === week);
        return weekSum + (detailForWeek ? detailForWeek.referral : 0);
      }, 0)
    ),
  }

  return (
    <div className="overflow-x-auto  border border-gray-200 rounded-md">
      {loading ? <p className="text-center text-zinc-700 p-6">Loading, please wait...</p> :
      <table className="min-w-full relative table-auto border-collapse overflow-auto">
        <thead>
          <tr className="text-xs">
            <th className="border bg-white sticky left-0 text-left text-gray-900 border-gray-300 px-4 py-3">
              Location
            </th>
            {weeks.map((week) => (
              <th
                key={week}
                className="whitespace-nowrap border border-gray-300 px-4 py-2"
              >
                Week {week}
              </th>
            ))}
            <th className="border border-gray-300 px-4 py-2 sticky right-0 bg-white text-center font-bold">
              Total
            </th>
          </tr>
        </thead>
        <tbody className="relative">
          {data.map((locationData) => (
            <tr key={locationData.location}>
              <td className="border sticky left-0 bg-white rounded-md border-gray-300 whitespace-nowrap px-4 py-2">
                {locationData.location}
              </td>
              {weeks.map((week) => {
                const detailForWeek = locationData.detail.find(
                  (detail) => detail.week_number === week
                );
                return (
                  <td
                    key={week}
                    onClick={() => {
                      if(detailForWeek?.training_id?.length > 0) {
                        push(`/training-users?id=${detailForWeek?.training_id.join(',')}`)
                      } else {
                        return
                      }
                    }}
                    className="text-center cursor-pointer hover:bg-white border border-gray-300 px-4 py-2"
                  >
                    {detailForWeek ? <p>{detailForWeek.referral}</p> : "-"}
                  </td>
                );
              })}
              <td className="border border-gray-300 px-4 py-2 sticky right-0 bg-white text-center font-bold">
                {locationData.total_user}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
        <tr className="font-bold ">
            <td className="border bg-white sticky left-0 border-gray-300 px-4 py-2">Grand Total</td>
            {grandTotal.weekTotals.map((weekTotal, index) => (
              <td key={index} className="border border-gray-300 px-4 py-2">
                {weekTotal}
              </td>
            ))}
            <td className="border  border-gray-300 px-4 py-2 sticky right-0 bg-white text-center">
              {grandTotal.totalUsers}
            </td>
          </tr>
        </tfoot>
      </table>}
    </div>
  )
}

export default memo(TrainingReports)