import { Table } from "common/Table/Table";
import { exportToCSV, formatDate } from "utils/funcs";
import { Fragment, useCallback, useEffect, useState } from "react";
import UserService from "services/user.service";
import QRCode from "react-qr-code";
import { Modal } from "common/Modal";
import { useDebounce } from "hooks/useDebounce";
import { AddTrainingModal } from "components/AddTrainingModal";
import classNames from "classnames";
import { tabStyles } from "pages/GeneralData";
import swal from "sweetalert";
import TrainingReports from "components/weekly/TrainingReports";
import { Attendees } from "components/weekly/Attendees";
import { useRouter } from "hooks/useRouter";
import { useNotifications } from "hooks/useNotification";
import { useSelector } from "react-redux";
import { userSelector } from "redux/selectors/user";
import { Tooltip } from "common/Tooltip";
import { Meetings } from "components/weekly/Meetings";
import { formatPhone } from "pages/Contacts";
import { useThunkDispatch } from "hooks/useThunkDispatch";
import { clearFilters, setFilters } from "redux/reducers/weeklyFilterReducer";

const service = new UserService();

export const WeeklyTrainings = () => {
  const [qrCode, setQrCode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [reports, setReports] = useState([]);
  const [loadingReports, setLoadingReports] = useState(false);

  const user = useSelector(userSelector);
  const router = useRouter();

  const columns = [
    {
      key: "badge_id",
      dbColName: "badge_id",
      title: "badge id",
      freeze: true,
      freezeIndex: "left-0",
    },
    {
      key: "creator_name",
      dbColName: "creator_name",
      title: "creator name",
      freeze: true,
      freezeIndex: "left-20",
    },
    { key: "trainer_name", dbColName: "trainer_name", title: "trainer name" },

    { key: "location", dbColName: "location", title: "location" },
    // { key: "manager_id", dbColName: "manager_id", title: "manager id" },
    // { key: "manager_name", dbColName: "manager_name", title: "manager name" },
    {
      key: "meeting_date",
      dbColName: "meeting_date",
      title: "meeting date",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      key: "meeting_start_time",
      dbColName: "meeting_start_time",
      title: "start time",
    },
    {
      key: "meeting_end_time",
      dbColName: "meeting_end_time",
      title: "end time",
    },
    { key: "title", dbColName: "title", title: "title" },
    // { key: "address", dbColName: "address", title: "address" },
    // {
    //   key: "manager_id",
    //   title: "Leader Badge id",
    //   dbColName: "manager_id",
    //   label: "manager id",
    // },

    {
      key: "highlights",
      title: "highlights",
      dbColName: "highlights",
      label: "highlights",
      render: (highlight) => (
        <Tooltip message={highlight}>
          {highlight && highlight?.toString()?.slice(0, 20)}
        </Tooltip>
      ),
    },

    // { key: "meet_link", dbColName: "meet_link", title: "meet_link" },
    { key: "type", dbColName: "type", title: "type" },

    { key: "zip_code", dbColName: "zip_code", title: "zip code" },
    // { key: "meetid", dbColName: "meetid", title: "meetid" },
    // { key: "file_link", dbColName: "file_link", title: "file link" },
    // { key: "qr_code", dbColName: "qr_code", title: "qr code" },
    // { key: "time_zone", dbColName: "time_zone", title: "time zone" },
    // {
    //   key: "meeting_date_and_time_start",
    //   dbColName: "meeting_date_and_time_start",
    //   title: "meeting start date",
    // },
    // {
    //   key: "meeting_date_and_time_end",
    //   dbColName: "meeting_date_and_time_end",
    //   title: "meeting end date",
    // },
    // { key: "creator_id", dbColName: "creator_id", title: "creator id" },
    {
      key: "creator_phone",
      dbColName: "creator_phone",
      title: "creator phone",
      render: (phone) => formatPhone(phone),
    },
    {
      key: "creator_email",
      dbColName: "creator_email",
      title: "creator email",
    },
    {
      key: "team_leader_id",
      dbColName: "team_leader_id",
      title: "team leader id",
    },
    {
      key: "team_leader_name",
      dbColName: "team_leader_name",
      title: "team leader name",
    },
    {
      key: "team_leader_phone_number",
      dbColName: "team_leader_phone_number",
      title: "team leader phone number",
      render: (phone) => formatPhone(phone),
    },
    {
      key: "team_leader_email",
      dbColName: "team_leader_email",
      title: "team_leader_email",
    },
    {
      key: "management_group_leader_id",
      dbColName: "management_group_leader_id",
      title: "management group leader id",
    },
    {
      key: "management_group_leader_name",
      dbColName: "management_group_leader_name",
      title: "management group leader name",
    },
    {
      key: "management_group_leader_phone_number",
      dbColName: "management_group_leader_phone_number",
      title: "management group leader phone number",
      render: (phone) => formatPhone(phone),
    },
    {
      key: "management_group_leader_email",
      dbColName: "management_group_leader_email",
      title: "management group leader email",
    },
    {
      key: "senior_management_group_leader_id",
      dbColName: "senior_management_group_leader_id",
      title: "senior management group leader id",
    },
    {
      key: "senior_management_group_leader",
      dbColName: "senior_management_group_leader",
      title: "senior management group leader",
    },
    {
      key: "senior_management_group_leader_phone_number",
      dbColName: "senior_management_group_leader_phone_number",
      title: "senior management group leader phone number",
    },
    {
      key: "senior_management_group_leader_email",
      dbColName: "senior_management_group_leader_email",
      title: "senior management group leader email",
    },
    {
      key: "region_leader_id",
      dbColName: "region_leader_id",
      title: "region leader id",
    },
    {
      key: "region_leader_name",
      dbColName: "region_leader_name",
      title: "region leader name",
    },
    {
      key: "region_leader_phone_number",
      dbColName: "region_leader_phone_number",
      title: "region leader phone number",
    },
    {
      key: "region_leader_email",
      dbColName: "region_leader_email",
      title: "region leader email",
    },
    {
      key: "senior_region_id",
      dbColName: "senior_region_id",
      title: "senior region id",
    },
    {
      key: "senior_region_name",
      dbColName: "senior_region_name",
      title: "senior region name",
    },
    {
      key: "senior_region_leader_phone_number",
      dbColName: "senior_region_leader_phone_number",
      title: "senior region leader phone number",
    },
    {
      key: "senior_region_leader_email",
      dbColName: "senior_region_leader_email",
      title: "senior region leader email",
    },
    {
      key: "partner_leader_id",
      dbColName: "partner_leader_id",
      title: "partner leader id",
    },
    {
      key: "partner_leader_name",
      dbColName: "partner_leader_name",
      title: "partner leader name",
    },
    {
      key: "partner_leader_phone_number",
      dbColName: "partner_leader_phone_number",
      title: "partner leader phone number",
      render: (phone) => formatPhone(phone),
    },
    {
      key: "partner_leader_email",
      dbColName: "partner_leader_email",
      title: "partner leader email",
    },

    // {
    //   key: "Qrcode",
    //   title: "QR CODE",
    //   label: "QR",
    //   render: (value: { type: string; meet_link: string }) => {
    //     const array = value.meet_link?.split("/");
    //     let link = "";

    //     if (value.type === "Weekly Training") {
    //       link = `https://weeklytrainings.evolutionsmarthome.com/raffle/${
    //         array[array.length - 1]
    //       }`;
    //     } else if (value.type === "Bootcamp | Solar") {
    //       link = `https://solarbootcamp.evolutionsmarthome.com/raffle/${
    //         array[array.length - 1]
    //       }`;
    //     } else if (value.type === "Bootcamp | Smart Home") {
    //       link = `https://shbootcamp.evolutionsmarthome.com/raffle/${
    //         array[array.length - 1]
    //       }`;
    //     }

    //     return (
    //       <button
    //         type="button"
    //         onClick={() => {
    //           setShowModal(true);
    //           setQrCode(link);
    //         }}
    //         className="text-xs rounded-md px-4 py-2 text-white bg-primary"
    //       >
    //         View QR
    //       </button>
    //     );
    //   },
    // },
    // {

    //   key: "total",
    //   title: "Total",
    //   render: row => <p className="text-zinc-700">
    //   {row.rsvp + row.no_of_attendees} 
    // </p>
    // },
    {
      key: "action",
      freeze: true,
      freezeIndex: "right-0",
      title: "action",
      render: (row) => {
        return (
          user?.admin === "admin" && (
            <div className="flex items-center gap-4 justify-center">
              <p className="text-zinc-700">
                {row.rsvp} - {row.no_of_attendees}
              </p>
              <button
                type="button"
                onClick={() => router.push(`/weekly-trainings/${row._id}`)}
                className="text-blue-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              </button>
              <button
                type="button"
                className="text-red-500"
                onClick={() => handleDelete(row._id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </button>
            </div>
          )
        );
      },
    },
  ];

  const { push, query } = useRouter();

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [addModal, setAddModal] = useState(false);
  const [state, setState] = useState({
    type: "",
    badge_id: "",
    trainer_name: "",
    manager_id: "",
    title: "",
    highlights: "",
    zip_code: "",
    meeting_start_time: "07:00 PM",
    meeting_end_time: "08:00 PM",
    address: "",
    location: "",
    manager_name: "",
    meeting_date: "",
  });
  const [loading, setLoading] = useState(false);
  const { successMessage } = useNotifications();
  const [exporting, setExporting] = useState(false);

  const filters = useSelector((state: any) => state.weeklyFilters)

  const [pagination, setPagination] = useState({
    pageNumber: filters.pageNumber ||  1,
    pageSize: filters.pageSize ||  100,
    type: filters.type ||  "",
    leaderName: filters.leaderName ||  "",
    location: filters.location ||  "",
    startDate: filters.startDate ||  "",
    hierarcyFilter: filters.hierarcyFilter ||  "",
    endDate: filters.endDate ||  "",
  });

  const dispatch = useThunkDispatch()
  const debounced = useDebounce(pagination, 500);

  useEffect(() => {
    dispatch(setFilters(debounced));
  }, [debounced, dispatch]);


  const fetchData = useCallback(() => {
    setLoading(true);
    service
      .getWeeklyTrainings(debounced)
      .then((resp) => {
        setData(resp.data.data);
        setTotalItems(resp.data.totalDocument);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [debounced]);

  const fetchReports = useCallback(() => {
    setLoadingReports(true);
    service
      .getTrainingReports()
      .then((resp) => {
        setReports(resp.data.data);
      })
      .catch((error) => {
        console.log(error);
        setData([]);
      })
      .finally(() => {
        setLoadingReports(false);
      });
  }, []);

  useEffect(() => {
    fetchReports();
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleExport = () => {
    setExporting(true);
    const pagin: any = { ...pagination };
    delete pagin.pageNumber;
    delete pagin.pageSize;
    service
      .getWeeklyTrainings(pagin)
      .then((resp) => {
        exportToCSV(resp.data.data, "trainings");
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setExporting(false);
      });
  };

  const handleDelete = (id) => {
    swal({
      title: "Are you sure, you want to delete this training?",
      icon: "warning",
      className: "w-[500px]",
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
        },
        confirm: {
          text: "Delete",
          className: "bg-primary text-white",
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        service
          .deleteTraining(id)
          .then((resp) => {
            if (resp.data.success) {
              const shallowCopy = [...data];
              const index = shallowCopy.findIndex(
                (item: { _id: string }) => item._id === id
              );
              shallowCopy.splice(index, 1);
              setData(shallowCopy);
              successMessage("Training deleted successfully");
            }
          })
          .catch((error) => console.log(error));
      }
    });
  };
  
  const resetFilters = () => {
    setPagination({
      pageNumber: 1,
      pageSize: 100,
      type: "",
      leaderName: "",
      location: "",
      startDate: "",
      hierarcyFilter: "",
      endDate: "",
    })
    dispatch(clearFilters())
  }

  return (
    <Fragment>
      <div className="text-sm mb-4 p-2 text-center text-gray-500 rounded-md bg-gray-200">
        <div className="flex flex-wrap -mb-px gap-3">
          <button
            type="button"
            onClick={() => push("/weekly-trainings?tab=Attendees")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: query.tab === "Attendees",
            })}
          >
            Attendees
          </button>
          <button
            type="button"
            onClick={() => push("/weekly-trainings?tab=Reports")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: query.tab === "Reports",
            })}
          >
            Training Reports
          </button>
          <button
            type="button"
            onClick={() => push("/weekly-trainings")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: !query.tab,
            })}
          >
            Weekly Trainings
          </button>
          <button
            type="button"
            onClick={() => push("/weekly-trainings?tab=Meetings")}
            className={classNames(tabStyles.default, {
              [tabStyles.active]: query.tab === "Meetings",
            })}
          >
            Weekly Training Board Meeting Reports
          </button>
        </div>
      </div>
      {!query.tab && (
        <div className="flex flex-col mb-4">
          <div className="flex justify-between items-center ">
            <div>
              <h1 className="font-medium text-gray-700 tracking-wide text-xl">
                Weekly Trainings
              </h1>
              <p className="text-gray-500 text-sm">View all weekly trainings</p>
            </div>
            <div className="flex gap-2">
              <button
                type="button"
                onClick={() =>
                  window.open(
                    "https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FDenver&showPrint=0&showTabs=0&showCalendars=0&showTz=0&showTitle=0&src=amR1bm5lcjE4QGdtYWlsLmNvbQ&color=%23D81B60",
                    "_blank" // <- This is what makes it open in a new window.
                  )
                }
                className="bg-black px-4 gap-2 flex items-center text-sm py-2.5 rounded-md text-white"
              >
                <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-5 w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                  />
                </svg>

                <span>Calendar</span>
              </button>
              <button
                type="button"
                onClick={() => push("/weekly-training-stats")}
                className="bg-black px-4 gap-2 flex items-center text-sm py-2.5 rounded-md text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z"
                  />
                </svg>
                <span>Weekly Stats</span>
              </button>
              <button
                type="button"
                onClick={() => push("/black-sparrow?tab=Reports")}
                className="bg-black px-4 gap-2 flex items-center text-sm py-2.5 rounded-md text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z"
                  />
                </svg>
                <span>Black Sparrow Reports</span>
              </button>

              <button
                onClick={() => setAddModal(true)}
                className="bg-black drop-shadow-md text-white rounded-md p-2.5 text-sm flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v12m6-6H6"
                  />
                </svg>
                Add Training
              </button>
            </div>
          </div>
        </div>
      )}
      {!query.tab ? (
        <Table
          data={data}
          handleExport={handleExport}
          setData={setData}
          exportButton={true}
          showSearch={false}
          exporting={exporting}
          pagination={pagination}
          setPagination={setPagination}
          loadingData={loading}
          totalItems={totalItems}
          columns={columns}
          filters={
            <Filters resetFilters={resetFilters} pagination={pagination} setPagination={setPagination} />
          }
          columnFilters={
            <ColumnFilters
             data={data}
              pagination={pagination}
              setPagination={setPagination}
            />
          }
        />
      ) : query.tab === "Reports" ? (
        <TrainingReports data={reports} loading={loadingReports} />
      ) : query.tab === "Attendees" ? (
        <Attendees />
      ) : query.tab === "Meetings" ? (
        <Meetings />
      ) : null}

      <Modal
        title="QR Code"
        width="w-full md:w-1/3"
        showModal={showModal}
        setShowModal={setShowModal}
      >
        <QRCode
          size={256}
          className="h-full w-full"
          value={qrCode}
          viewBox={`0 0 256 256`}
        />
      </Modal>
      <AddTrainingModal
        showModal={addModal}
        state={state}
        setState={setState}
        setShowModal={setAddModal}
        data={data}
        setData={setData}
      />
    </Fragment>
  );
};

const Filters = ({ pagination, resetFilters,  setPagination }) => {
  const filters = { ...pagination };
  delete filters.pageNumber;
  delete filters.pageSize;
  const hasFilters = Object.values(filters).some((item) => item);

  return (
    <div className="flex gap-4 flex-1">
      <select
        placeholder="Type"
        value={pagination.type}
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        onChange={(e) => {
          setPagination({
            ...pagination,
            type: e.target.value,
          });
        }}
      >
        <option value="">Select Type</option>
        <option value="Weekly Training">Weekly Training</option>
        <option value="Bootcamp | Smart Home">Bootcamp | Smart Home</option>
        <option value="Bootcamp | Solar">Bootcamp | Solar</option>
        <option value="Group Interview">Group Interview</option>
      </select>
      <input
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        type="text"
        value={pagination.leaderName}
        placeholder="Search by creator"
        onChange={(e) => {
          setPagination({
            ...pagination,
            leaderName: e.target.value,
          });
        }}
      />
      <input
        placeholder="Search by location name"
        type="text"
        value={pagination.location}
        className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
        onChange={(e) => {
          setPagination({
            ...pagination,
            location: e.target.value,
          });
        }}
      />
      {hasFilters && (
        <button
          onClick={resetFilters}
          className="bg-black whitespace-nowrap rounded-lg text-white px-3 py-2 text-sm "
        >
          Clear Filters
        </button>
      )}
    </div>
  )
}

const ColumnFilters = ({ data, pagination, setPagination }) => {
  const [dateSelected, setDatesSelected] = useState({
    start: "",
    end: "",
  });

  useEffect(() => {
    if (dateSelected.start && dateSelected.end) {
      setPagination({
        ...pagination,
        startDate: dateSelected.start,
        endDate: dateSelected.end,
      });
    }
  }, [dateSelected]);

  const handleChangeDate = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") {
      setDatesSelected({
        ...dateSelected,
        start: value,
      })
    } else if (name === "endDate") {
      setDatesSelected({
        ...dateSelected,
        end: value,
      })
    }
  }

  const totals = data.reduce(
    (acc, item) => {
      acc.totalRSVP += item.rsvp || 0;
      acc.totalAttendees += item.no_of_attendees || 0;
      return acc;
    },
    { totalRSVP: 0, totalAttendees: 0 }
  )

  return (
    <div>
      <div className="flex items-end gap-4 mt-3">
        <div className="flex items-center w-full relative gap-1">
          <label className="flex flex-col gap-1 w-full  text-xs text-zinc-700">
            Hierarchy
            <select
              placeholder="Hierarcy Filter"
              value={pagination.hierarcyFilter}
              className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
              onChange={(e) => {
                setPagination({
                  ...pagination,
                  hierarcyFilter: e.target.value,
                });
              }}
            >
              <option value="">All</option>
              <option value="Partner">Partner</option>
              <option value="Manager">Manager</option>
              <option value="Senior Manager">Senior Manager</option>
              <option value="Regional">Regional</option>
            </select>
          </label>
        </div>
        <div className="flex items-center w-full relative gap-1">
          <label
            id="startDate"
            className="flex flex-col gap-1 w-full  text-xs text-zinc-700"
          >
            Meeting Start Date
            <input
              className="rounded-md focus:border-gray-300 font-light focus:outline-none  focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
              type="date"
              id="startDate"
              name="startDate"
              value={dateSelected.start}
              onChange={handleChangeDate}
            />
          </label>
          {dateSelected.start && (
            <button
              className="text-xs text-zinc-700 absolute right-10 mt-5"
              onClick={() => {
                setDatesSelected({
                  ...dateSelected,
                  start: "",
                  end: "",
                });
                setPagination({
                  ...pagination,
                  startDate: "",
                  endDate: "",
                });
              }}
              type="button"
            >
              X
            </button>
          )}
        </div>
        <div className="flex items-center w-full relative gap-1">
          <label
            id="endDate"
            className="flex flex-col gap-1 w-full text-xs text-zinc-700"
          >
            Meeting End Date
            <input
              className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
              type="date"
              id="endDate"
              name="endDate"
              value={dateSelected.end}
              onChange={handleChangeDate}
            />
          </label>
          {dateSelected.end && (
            <button
              className="text-xs text-zinc-700 absolute right-10 mt-5"
              onClick={() => {
                setDatesSelected({
                  ...dateSelected,
                  end: "",
                  start: "",
                });
                setPagination({
                  ...pagination,
                  endDate: "",
                  sartDate: "",
                });
              }}
              type="button"
            >
              X
            </button>
          )}
        </div>
      </div>
      <div className="mt-3 flex gap-2 justify-end">
        <p className="text-zinc-800 font-medium ">Total RSVP: {totals.totalRSVP}</p>
        <p className="text-zinc-800 font-medium ">Total Attendees: {totals.totalAttendees}</p>
      </div>
    </div>
  )
}
