import { Table } from "common/Table/Table";
import { Tooltip } from "common/Tooltip";
import { useNotifications } from "hooks/useNotification";
import { Fragment, useEffect, useState } from "react";
import UserService from "services/user.service";
import { formatDate } from "utils/funcs";

import swal from "sweetalert";
import { useDebounce } from "hooks/useDebounce";

const service = new UserService();

export const Ecards = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
    const [totalItems, setTotalItems] = useState(0)

  const columns = [
    { key: "first_name", dbColName: "first_name", title: "first name" },
    { key: "last_name", dbColName: "last_name", title: "last name" },
    { key: "email", dbColName: "email", title: "email" },
    { key: "phone", dbColName: "phone", title: "phone" },
    { key: "zip_code", dbColName: "zip_code", title: "zip code" },
    { key: "details", dbColName: "details", title: "details", render: (description) => (
            <Tooltip message={description}>
              {description && description?.toString()?.slice(0, 40)}
            </Tooltip>
          ), },
    { key: "to_badgeid", dbColName: "to_badgeid", title: "to badgeid" },
    { key: "createdAt", dbColName: "createdAt", title: "created At", render: date => formatDate(date) },
    {
      key: "action",
      dbColName: "_id",
      freeze: true,
      freezeIndex: "right-0",
      title: "action",
      render: (id) => {
        return (
          <button
            type="button"
            className="text-red-500 text-right"
            onClick={() => handleDelete(id)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </button>
        );
      },
    },
  ];

  const { successMessage } = useNotifications()

  const handleDelete = id => {
    swal({
      title: "Are you sure, you want to delete this ecard?",
      icon: "warning",
      className: "w-[500px]",
      buttons: {
        cancel: {
          text: "Cancel",
          visible: true,
        },
        confirm: {
          text: "Delete",
          className: "bg-primary text-white",
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        service.deleteEcard(id).then((resp) => {
          if (resp.data.success) {
            successMessage("Ecard deleted successfully");
            const shallowCopy = [...data];
            const newData = shallowCopy.filter((d: any) => d._id !== id);
            setData(newData);
          }
        }).catch(error => console.log(error))
      }
    });
  }

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 100,
    name: '',
    startDate: '',
    endDate: '',
  });

  const debounced = useDebounce(pagination, 1000)

  useEffect(() => {
    setLoading(true);
    service
      .getEcards(debounced)
      .then((resp) => {
        if(resp.data.success) {
          
          setData(resp.data.data)
          setTotalItems(resp.data.totalDocument)
        } else {
          setData([])
        }
      })
      .catch((err) => {
        console.log(err);
        setData([])
      })
      .finally(() => {
        setLoading(false);
      });
  }, [debounced]);

  const clearFilter = () => {
    setPagination({
      pageNumber: 1,
      pageSize: 100,
      name: '',
      startDate: '',
      endDate: '',
    })
  }

  return (
    <Fragment>
      <div className="flex flex-col mb-4">
        <h1 className="font-medium text-gray-700 tracking-wide text-xl">
          Ecards
        </h1>
        <p className="text-gray-500 text-sm">View all Ecards</p>
      </div>
      <Table
        data={data}
        loadingData={loading}
        pagination={pagination}
        showSearch={false}
        setPagination={setPagination}
        setData={setData}
        filters={<HeadFilters clearFilter={clearFilter} pagination={pagination} setPagination={setPagination} />
      }
        columnFilters={
          <ColumnFilters
            pagination={pagination}
            setPagination={setPagination}
          />
        }
        totalItems={totalItems}
        columns={columns}
        exportButton={false}
      />
    </Fragment>
  );
};

const HeadFilters =  ({ clearFilter, pagination, setPagination }) => {
  const filters = { ...pagination };
  delete filters.pageNumber;
  delete filters.pageSize;
  const hasFilters = Object.values(filters).some((item) => item);
  return (
    <div className="flex gap-2 flex-1">
          <div className="relative flex-1">
          <input
            type="search"
            value={pagination.name}
            placeholder="Search by name"
            onChange={(e) => setPagination({
              ...pagination,
              name: e.target.value
            })}
            className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 absolute text-gray-500 right-2 top-2.5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
          </svg>
        </div>
        {hasFilters && (
      <button
        onClick={() => {
          clearFilter()
        }}
        className="bg-black whitespace-nowrap rounded-lg text-white px-3 py-2 text-sm "
      >
        Clear Filters
      </button>
    )}
        </div>
    
  )
}

const ColumnFilters = ({ pagination, setPagination }) => {
  return (
    <div className="flex gap-4 mt-2">
      <div className="flex-1">
        <label className="text-xs text-gray-700">Start Date</label>
        <input
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          value={pagination.startDate}
          onChange={(e) =>
            setPagination({
              ...pagination,
              startDate: e.target.value,
            })
          }
        />
      </div>

      <div className="flex-1">
        <label className="text-xs text-gray-700">End Date</label>
        <input
          placeholder="Start date"
          className="rounded-md focus:border-gray-300 font-light focus:outline-none w-full focus:ring-0 border-gray-200 px-4 py-2 text-sm text-gray-800"
          type="date"
          value={pagination.endDate}
          onChange={(e) => {
            setPagination({
              ...pagination,
              endDate: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};
